import React, { FC, useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
// eslint-disable-next-line
import { initTranslations } from '@utilities/i18n';
// import { register } from '@utilities/serviceWorkerRegistration';
import * as Sentry from '@sentry/react';

Sentry.init({
  dsn: 'https://42f444b81008a290a4365cfda8379d27@o4508175771566080.ingest.de.sentry.io/4508591951052880',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [ 'localhost', /^https:\/\/mechisapp\.herokuapp\.com\/graphql/ ],
  // Session Replay
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

initTranslations();

const ReloadOnFirstVisit: FC = () => {
  useEffect(() => {
    const hasVisitedThisSession = sessionStorage.getItem('hasVisitedThisSession');
    if (!hasVisitedThisSession) {
      sessionStorage.setItem('hasVisitedThisSession', 'true');
      window.location.reload();
    }
  }, []);

  return null;
};

const rootElement = document.getElementById('root');
if (!rootElement) throw new Error('Failed to find the root element');
const root = ReactDOM.createRoot(rootElement);

root.render(
  <React.StrictMode>
    <App />
    <ReloadOnFirstVisit />
  </React.StrictMode>
);

// register();
reportWebVitals();

