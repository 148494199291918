import { ReactiveVar, makeVar } from '@apollo/client';
import { IOdometerValidationModalData } from './types';
 
export const init: IOdometerValidationModalData = {
  timestamp: 0,
  payload: undefined,
  filledDate: new Date(),
  minOdometer: undefined,
  maxOdometer: undefined,
  ignoreNextValidation: false,
};

const odometerValidationModalData: ReactiveVar<IOdometerValidationModalData> = makeVar<IOdometerValidationModalData>(init);

export default odometerValidationModalData;

