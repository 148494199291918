import React, { FC, useState, useEffect } from 'react';
import { ITagFiltersProps, ITagOption } from './types';
import { IOwnerState, ControlState } from '../types';
import {
  Control,
  ControlLabel,
} from '../styles';
import {
  IconButton,
  Dialog,
  DialogContent,
  Stack,
  Button,
  DialogActions,
  TextField,
  InputAdornment,
  Typography,
  Tabs,
  Tab,
  Paper,
  Box,
  Link,
} from '@mechis/elements';
import { getColorByState } from '../Selections';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import { useTranslation } from 'react-i18next';
import useTagManager from '@hooks/useTagManager';
import useAuth from '@hooks/useAuth';
import Tag from './Tag';
import { FixedSizeList as List } from 'react-window';
import CancelIcon from '@mui/icons-material/Cancel';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import EmojiObjectsIcon from '@mui/icons-material/EmojiObjects';

const TagFilters: FC<ITagFiltersProps> = ({ customControl }) => {
  const { t } = useTranslation();
  const {
    tagsOptions,
    tagsOptionsLatest,
    tags,
    resetTags,
    toggleTag,
  } = useTagManager();
  const [ filter, setFilter ] = useState('');
  const [ state, setState ] = useState<ControlState>('default');
  const [ openTagFilters, setOpenTagFilters ] = useState(false);
  const [ tabValue, setTabValue ] = useState<number>(0);
  const { isComgateUser } = useAuth();
  const DEVICE_HEIGHT_PORTRAIT = localStorage.getItem('DEVICE_HEIGHT_PORTRAIT');
  // TODO: MECH-916
  const DEVICE_TAG_MANAGER_OFFSET = 292;
  const LIST_HEIGHT = Number(DEVICE_HEIGHT_PORTRAIT) - DEVICE_TAG_MANAGER_OFFSET;

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const onCloseModal = () => {
    setOpenTagFilters(false);
  };

  const onSelect = () => {
    setOpenTagFilters(false);
  };

  const onReset = () => {
    resetTags();
    setState('default');
  };

  const ownerState: IOwnerState = {
    state,
  };
  
  if (isComgateUser()) {
    return <></>;
  }

  // NOTE: DONT DELETE THIS
  // ORIGINAL SOLUTION WITHOUT TABARA STRING SPLIT 
  /*
  let filteredTagsOptions: ITagOption[] = (tagsOptions as ITagOption[]).filter((tag) => {
    const normalizedTag = t(`tagManager.id.${tag.tagId}`).normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    const normalizedFilter = filter.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    return normalizedTag.toLowerCase().includes(normalizedFilter.toLowerCase());
  });
  */

  // TABARA SPLIT STRING SOLUTION
  let filteredTagsOptions: ITagOption[] = (tagsOptions as ITagOption[]).filter((tag) => {
    const normalizedTag = t(`tagManager.id.${tag.tagId}`).normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase();
    const searchPhrases = filter.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase().split(' ');

    const matchedPhrases = searchPhrases.filter((phrase) => normalizedTag.includes(phrase));
    return matchedPhrases.length === searchPhrases.length;
  });

  // Tab: "Jen vybrané"
  if (tabValue === 1) { 
    filteredTagsOptions = filteredTagsOptions.filter((tag) =>
      tags.some((selectedTag) => selectedTag === tag.tagId)
    );
  }

  // Tab: "Často používané"
  if (tabValue === 2) { 
    filteredTagsOptions = filteredTagsOptions.filter((tag) =>
      tagsOptionsLatest?.some((latestTag) => latestTag.tagId === tag.tagId)
    );
  }

  useEffect(() => {
    if (tags.length === 0) {
      setState('default');
    } else {
      setState('filled');
    }
  }, [ tags ]);

  return (
    <>
      {customControl ? (
        <div onClick={() => setOpenTagFilters(true)}>{customControl}</div>
      ) : (
        <Control>
          <IconButton
            size="small"
            color={getColorByState(state)}
            isSquared
            onClick={() => setOpenTagFilters(true)}
          >
            <LocalOfferIcon />
          </IconButton>
          <ControlLabel ownerState={ownerState}>{tags?.length === 0 ? 'Tags' : tags?.length}</ControlLabel>
        </Control>
      )}
      <Dialog
        onClose={() => setOpenTagFilters(false)}
        open={openTagFilters}
        maxWidth="xs"
        fullScreen={true}
        sx={{
          '& .MuiDialogContent-root': {
            display: 'flex',
            flexDirection: 'column',
            gap: '4px',
          },
        }}
      > 
        <DialogContent>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="sectionHeadline">Filtruj tagy</Typography>
            <IconButton
              size="small"
              onClick={onCloseModal}
            >
              <HighlightOffIcon />
            </IconButton>
          </Stack>
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            sx={{
              minHeight: '32px',
              '& button': {
                minHeight: '32px',
                minWidth: 'initial',
                padding: '0 12px',
                fontSize: '0.75rem',
              },
            }}
          >
            <Tab label={t('tagManager.tabs.all')} />
            <Tab label={t('tagManager.tabs.onlySelected')} />
            <Tab label={t('tagManager.tabs.recent')} />
          </Tabs>
          <TextField
            placeholder="Vyhledávej tagy..."
            variant="outlined"
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
            fullWidth
            color="secondary"
            margin="normal"
            size="small"
            sx={{
              mt: 1,
              mb: 2,
            }}
            InputProps={{
              sx: { 
                borderRadius: 8,
                borderColor: 'lightgray',
              },
              endAdornment: filter && (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setFilter('')}
                    edge="end"
                  >
                    <CancelIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          {filteredTagsOptions.length === 0 ? (
            <Paper variant="outlined" sx={{ position: 'relative', mt: 2 }}>
              <FormatQuoteIcon 
                color="disabled"
                fontSize="large"
                sx={{ 
                  position: 'absolute',
                  top: '8px',
                  left: '8px',
                  transform: 'rotate(180deg)',
                  opacity: '0.4',
                }}
              />
              <Box p={2} textAlign="center">
                <IconButton
                  color="warning"
                  size="large"
                  sx={{
                    position: 'relative',
                    mt: -6,
                  }}
                >
                  <EmojiObjectsIcon fontSize="large" />
                </IconButton>
                <Typography mb={2}>
                  <small>
                    Vyhledávání neodpovídá žádným tagům. Chybí ti nějaký tag? Dej nám vědět na 
                    <Link
                      href="https://discord.com/channels/1236634682376978432/1328264306562699386"
                      target="_blank"
                      rel="noopener noreferrer"
                    > Discordu!</Link>
                  </small>
                </Typography>
              </Box>
              <FormatQuoteIcon 
                color="disabled"
                fontSize="large"
                sx={{ 
                  position: 'absolute',
                  bottom: '8px',
                  right: '8px',
                  opacity: '0.4',
                }}
              />
            </Paper>
          ) : (
            <List
              height={LIST_HEIGHT}
              itemCount={filteredTagsOptions.length}
              itemSize={30+16}
              width="100%"
              itemData={{ tagsOptions: filteredTagsOptions }}
            >
              {/* eslint-disable-next-line */}
              {({ index, style, data }) => {
                // eslint-disable-next-line
                const { tagsOptions } = data;
                const tag = tagsOptions[index];
                return (
                  <div style={style}>
                    <Tag
                      key={tag.tagId}
                      tagId={tag.tagId}
                      tagCategoryName={tag.tagCategory.name}
                      isSelected={tags?.includes(tag.tagId)}
                      onClick={() => toggleTag(tag.tagId)}
                    />
                  </div>
                );
              }}
            </List>
          )}
        </DialogContent>
        <DialogActions>
          <Stack
            direction="row"
            justifyContent="space-between"
            width={'100%'}
            pb={1}
            px={2}
          >
            <Button
              variant="outlined"
              color="primary"
              onClick={onReset}
              disabled={tags?.length === 0}
              sx={{ mt: -5 }}
            >
              {t('tagManager.resetFiltersBtn')}
            </Button>
            <Button
              variant="contained"
              color="secondary"
              disabled={tags?.length === 0}
              onClick={onSelect}
              sx={{ mt: -5, zIndex: 1400 }}
            >
              {t('tagManager.selectTagsBtn')}
            </Button>
          </Stack>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default TagFilters;

