import { PaletteOptions, ThemeOptions } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import breakpoints from './default/breakpoints';
import palette from './alt/palette';
import typography from './default/typography';
import components from './default/components';
import mixins from '@configurations/themes/default/mixins';
import { radiuses, IRadiusValue } from '@configurations/themes/default/radiuses';

declare module '@mui/material/styles/createPalette' {

  interface CommonColors {
    lightGrey: string
  }

  interface TypeBackground {
    warning: string
    success: string
    error: string
    navigationBg: string
  }
}

declare module '@mui/material/styles/createTheme' {
  interface Theme {
    radiuses: IRadiusValue
    constants: IThemeConstants
  }

  // eslint-disable-next-line
  interface ThemeOptions {
    radiuses: IRadiusValue
    constants: IThemeConstants
  }
}

interface IThemeConstants {
  readonly appHeaderHeightSmall: number
  readonly appHeaderHeightLarge: number
  readonly appHeaderHeightPWASmall: number
  readonly appHeaderHeightPWALarge: number
  readonly pwaOffset: number
}

const appHeaderHeightSmall = 68; // in pixels
const appHeaderHeightLarge = 130; // in pixels
const PWA_OFFSET = 32; // in pixels

const theme: ThemeOptions = createTheme({
  breakpoints: {
    values: {
      ...breakpoints,
    },
  },
  mixins: {
    ...mixins,
  },
  radiuses: {
    ...radiuses,
  },
  palette: palette as PaletteOptions,
  typography: typography,
  // @ts-ignore
  components: components,
  constants: {
    appHeaderHeightSmall,
    appHeaderHeightLarge,
    appHeaderHeightPWASmall: appHeaderHeightSmall+PWA_OFFSET,
    appHeaderHeightPWALarge: appHeaderHeightLarge+PWA_OFFSET,
    pwaOffset: PWA_OFFSET,
  },
});

export default theme;
