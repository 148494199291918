import React, { FC } from 'react';
import {
  useDeviceUsedFuelsQuery,
  useFuelTypesQuery,
} from '@state/mechis-backend/generated/schema';
import {
  Box,
  FormGroup,
  Stack,
} from '@mechis/elements';
import Fuel, { Skeleton as FuelSkeleton } from '../components/Fuel';
import editFuelCacheControl from '@state/mutations/editFuel';
import { IEditFuelItemModel } from '@state/models/editFuel/types';
import useTech from '@hooks/useTech';

const FuelList: FC = () => {
  const { techId } = useTech();
  const { data: allFuelTypes, loading, error } = useFuelTypesQuery();
  const { setFuels } = editFuelCacheControl;
  const { data: usedFuelsData } = useDeviceUsedFuelsQuery({
    variables: {
      deviceId: techId,
    },
    skip: !techId,
  });

  const originalData = usedFuelsData?.device;

  if (allFuelTypes && originalData) {
    const originalMappedData = originalData.fuel
      .map((fuel): IEditFuelItemModel => ({
        id: parseInt(fuel.id),
        fuelName: fuel.fuelType?.fuelName || '',
        consumptionUnit: { id: parseInt(fuel?.consumptionUnit?.id || '') },
        volumeUnit: { id: parseInt(fuel?.volumeUnit?.id || '') },
        actualConsumption: fuel.actualConsumption,
        fuelType: {...fuel.fuelType, id: parseInt(fuel.fuelType?.id || '')},
        initialConsumption: fuel.initialConsumption,
        tankVolume: fuel.tankVolume,
        isSelected: true,
      }));

    const placeholderMappedData = allFuelTypes?.fuelTypes
      .filter((f) => !originalMappedData.map((d) => d.fuelType?.id).includes(parseInt(f.id)))
      .map((obj) => ({
        ...obj,
        id: 0,
        isSelected: false,
        consumptionUnit: { id: 1 },
        fuelType: { id: +obj.id },
        initialConsumption: 0,
        tankVolume: 0,
        volumeUnit: { id: 1 },
      }));

    setFuels([ ...originalMappedData, ...placeholderMappedData ]);
  }

  return (
    <Box
      sx={{
        marginTop: (theme) => theme.spacing(4),
        marginBottom: (theme) => theme.spacing(2),
      }}
    >
      <FormGroup sx={{ mt: -2 }}>
        {loading && (
          <Stack spacing={2}>
            <FuelSkeleton />
            <FuelSkeleton />
            <FuelSkeleton />
            <FuelSkeleton />
            <FuelSkeleton />
            <FuelSkeleton />
            <FuelSkeleton />
            <FuelSkeleton />
            <FuelSkeleton />
          </Stack>
        )}
        {allFuelTypes && originalData && !error && (
          <Stack spacing={2}>
            {allFuelTypes?.fuelTypes.map((ft) => (
              <Fuel
                key={ft?.id}
                id={parseInt(ft?.id || '')}
              />
            ))}
          </Stack>
        )}
      </FormGroup>
    </Box>
  );
};

export default FuelList;
