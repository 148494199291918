interface ExpirableStorageItem {
  value: string
  create: number
}

export const setWithExpiry = (key: string, value: string): void => {
  const now = new Date();
  const item: ExpirableStorageItem = {
    value: value,
    create: now.getTime(),
  };

  localStorage.setItem(key, JSON.stringify(item));
};

export const getWithExpiry = (key: string, expiry: number): string | null => {
  const itemStr = localStorage.getItem(key);
  if (!itemStr) return null;

  const item: ExpirableStorageItem = JSON.parse(itemStr);
  const now = new Date();

  if (now.getTime() > item.create + expiry) {
    localStorage.removeItem(key);
    return null;
  }

  if (item.create === undefined) {
    setWithExpiry(key, item.value);
  }

  return item.value;
};
