import { MAXIMUM_CHARS_FOR_RECORD_NAME } from '@configurations/constants/app';
import { EVENT_TYPE_EXPENSE, EVENT_TYPE_MAINTENANCE } from '@configurations/constants/eventTypes';
import { Event } from '@state/mechis-backend/generated/schema';
import i18n from 'i18next';

const limitedDescriptionWithNumberOfRest = (words: string[]) => {
  let accumulatedLength = 0;

  // Find the index where the accumulated length exceeds the limit
  const displayedWords = words.filter((word) => {
    if (accumulatedLength + word.length + (accumulatedLength > 0 ? 2 : 0) <= MAXIMUM_CHARS_FOR_RECORD_NAME) {
      accumulatedLength += word.length + (accumulatedLength > 0 ? 2 : 0); // Add length of word + comma + space
      return true;
    }
    return false;
  });

  const restCount = words.length - displayedWords.length;

  return displayedWords.join(', ') + (restCount > 0 ? ` (+${restCount})` : '');
};

export const getEventName = (event: Event): string => {
  if (Number(event.eventType.id) === EVENT_TYPE_EXPENSE) {
    const tags = event.expense?.map((expense) => i18n.t(`tagManager.id.${expense.tag?.tagId}`));

    return tags ? limitedDescriptionWithNumberOfRest(tags) : event.name;
  }

  if (Number(event.eventType.id) === EVENT_TYPE_MAINTENANCE) {
    const uniqueMaintenanceIntervalNames = Array.from(
      new Set(
        event.eventOperation?.map(
          (eventOperation) => eventOperation.operation.maintenanceInterval?.name ?? ''
        )
      )
    );

    return uniqueMaintenanceIntervalNames ? limitedDescriptionWithNumberOfRest(uniqueMaintenanceIntervalNames) : event.name;
  }

  return event.name;
};

