import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useReactiveVar } from '@apollo/client';
import { makeVar } from '@apollo/client';
import { 
  useTagsQuery, 
  useTagsOrderedByUsageQuery,
  Tag,
} from '@state/mechis-backend/generated/schema';
import csTranslations from '@configurations/translations/cs.json';
import enTranslations from '@configurations/translations/en.json';

import {
  PanTool,
  Build,
  OilBarrel,
  Extension,
  Radio,
  CleaningServices,
  Settings,
  SettingsSuggest,
  CenterFocusWeak,
  SquareFoot,
  CropRotate,
  Pages,
  Money,
} from '@mui/icons-material';
import { TAGS_BY_USAGE_LIMIT } from '@configurations/constants/app';
import { ExpensesByEventQuery } from '@state/mechis-backend/generated/schema';

const tagsVar = makeVar<string[]>([]);

const useTagManager = (existedExpensesInfo?: ExpensesByEventQuery) => {
  const { t, i18n } = useTranslation();
  // todo: fix typescript here
  const [ tagsOptions, setTagsOptions ] = useState<any[]>([]);

  const tags = useReactiveVar(tagsVar);

  const toggleTag = (tagId: string) => {
    const currentTags = tagsVar();
    const tagIndex = currentTags.findIndex((tag) => tag === tagId);

    if (tagIndex === -1) {
      tagsVar([ ...currentTags, tagId ]);
    } else {
      tagsVar(currentTags.filter((tag) => tag !== tagId));
    }
  };

  const noTag = [ {
    description: '',
    id: '1',
    tagCategory: {__typename: 'TagCategory', id: '1', name: 'operation'},
    tagId: 'tag_tire_change',
    tagName: 'přezutí',
    tagTranslation: 'přezutí',
    __typename: 'Tag',
  } ];

  const { data: tagsResponse, loading, error } = useTagsQuery({
    variables: {
      limit: 10000, // original value is 10000
      offset: 0,
    },
  });

  const { data: tagsResponseLatest } = useTagsOrderedByUsageQuery({
    variables: {
      limit: TAGS_BY_USAGE_LIMIT,
    },
  });

  const getTranslations = () => {
    switch (i18n.language) {
      case 'cs': return csTranslations;
      case 'en': return enTranslations;
      default: return null;
    }
  };

  const isTranslationDecorate = (tag: Tag) => {
    let isTranslation = true;
    const translations = getTranslations();

    // @ts-ignore
    const id = translations.tagManager.id;
    if (!id) {
      isTranslation = false;
    } else {
      // @ts-expect-error
      isTranslation = id[tag.tagId];
    }

    if (!isTranslation) {
      console.warn('Missing translation for tag: ', tag.tagId);
    }

    return {
      ...tag,
      tagTranslation: t(`tagManager.id.${tag.tagId}`),
      isTranslation,
    };
  };

  const tagsOptionsLatest = tagsResponseLatest?.tagsOrderedByUsage?.map((tag) => isTranslationDecorate(tag));

  const getTagsIds = () => tagsOptions?.filter((tag) => tags.includes(tag.tagId))?.map((tag) => Number(tag.id));

  useEffect(() => {
    if (existedExpensesInfo) {
      const myArray = [];
      for (const expense of existedExpensesInfo?.expensesByEvent?.expense as any) {
        myArray.push(expense.tag.tagId);
        tagsVar(myArray);
      }
    }
  }, [ existedExpensesInfo ]);

  useEffect(() => {
    if (!loading && !error && tagsResponse) {
      const result = tagsResponse?.tags?.tag.map((tag) => isTranslationDecorate(tag));
      if (result) {
        setTagsOptions(result);
      }
    }
  }, [ tagsResponse, loading, error ]);

  return {
    tagsOptions: tagsOptions || noTag,
    tagsOptionsLatest,
    loading,
    error,
    tags,
    toggleTag,
    resetTags: () => tagsVar([]),
    getTagsIds,
  };
};

const getIconByCategory = (name: string) => {
  switch (name) {
    case 'operation': 
      return <PanTool fontSize="small" />;
    case 'tool': 
      return <Build fontSize="small" />;
    case 'fuel': 
      return <OilBarrel fontSize="small" />;
    case 'item': 
      return <Extension fontSize="small" />;
    case 'accessories': 
      return <Radio fontSize="small" />;
    case 'service': 
      return <CleaningServices fontSize="small" />;
    case 'system': 
      return <SettingsSuggest fontSize="small" />;
    case 'specification': 
      return <CenterFocusWeak fontSize="small" />;
    case 'quantity': 
      return <SquareFoot fontSize="small" />;
    case 'components': 
      return <CropRotate fontSize="small" />;
    case 'other': 
      return <Pages fontSize="small" />;
    case 'finance': 
      return <Money fontSize="small" />;
    default: 
      return <Settings fontSize="small" />;
  }
};

export { getIconByCategory };

export default useTagManager;
