import {
  ApolloClient,
  createHttpLink,
  ApolloLink,
  Operation,
} from '@apollo/client';
import { TokenRefreshLink } from 'apollo-link-token-refresh';
import { onError } from '@apollo/client/link/error';
import jwtDecode, { JwtPayload } from 'jwt-decode';
import { setContext } from '@apollo/client/link/context';
import cache from './cache';
import {
  GRAPHQL_ENDPOINT,
  HTTP_CALL_ERROR,
  // HTTP_CALL_ERROR_TTL,
  REFRESH_TOKEN_URL,
} from '@configurations/constants/app';
import { ACCESS_TOKEN } from '@configurations/constants/app';
import snackbarCacheControl from './mutations/snackbar';
import odometerValidationModalDataCacheControl from './mutations/odometerValidationModalData';
// import { getWithExpiry, setWithExpiry } from '@hooks/useTTLLocalStorage';
import * as Sentry from '@sentry/react';

const { setSnackbar } = snackbarCacheControl;
let isRefreshing = false; // Flag to track if the refresh is in progress
let pendingRequests: (() => void)[] = []; // Queue of pending requests

const httpLink = createHttpLink({
  uri: GRAPHQL_ENDPOINT,
  credentials: 'include',
});

const authLink = setContext((_, { headers }) => {
  const accessToken = localStorage.getItem(ACCESS_TOKEN)?.replace('"', '').replace('"', '');

  return {
    headers: {
      ...headers,
      authorization: accessToken ? `Bearer ${accessToken}` : '',
    },
  };
});

const refreshLink = new TokenRefreshLink({
  accessTokenField: 'accessToken',
  isTokenValidOrUndefined: (operation: Operation) => {
    const accessToken = localStorage.getItem(ACCESS_TOKEN);
    if (operation.operationName === 'LoginUser') return true;
    if (!accessToken) return true;
    try {
      const { exp } = jwtDecode<JwtPayload>(accessToken);
      if (!exp) return false;
      return Date.now() < exp * 1000;
    } catch {
      return false;
    }
  },
  fetchAccessToken: () => {
    isRefreshing = true; // Set the flag to indicate refresh is in progress
    return fetch(REFRESH_TOKEN_URL, {
      method: 'POST',
      credentials: 'include',
    }).finally(() => {
      isRefreshing = false; // Reset the flag when done
      pendingRequests.forEach((callback) => callback());
      pendingRequests = []; // Clear pending requests
    });
  },
  // eslint-disable-next-line
  handleFetch: (accessToken, operation: Operation) => {
    localStorage.setItem(ACCESS_TOKEN, accessToken);
  },
  handleError: (err: Error, operation: Operation) => {
    localStorage.removeItem(ACCESS_TOKEN);
    console.error('[TokenRefreshLink]', operation.operationName, err);
    window.location.href = '/';
  },
});

const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path, extensions }) => {
      if (extensions && extensions.code === 'FORBIDDEN') {
        if (!isRefreshing) {
          pendingRequests.push(() => forward(operation)); // Queue the request
        }
        return; // Do not show error snackbar during refresh
      } else {
        const errorMsg = `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}, Extensions: ${extensions}`;
        console.log(errorMsg);

        if (extensions && extensions.code === 'TOKEN_ERROR') {
          localStorage.removeItem(ACCESS_TOKEN);
          window.location.href = '/';
        }

        if (
          extensions &&
          (
            extensions.code === 'REFRESH_TOKEN_NOT_FOUND' ||
            extensions.code === 'TOKEN_ERROR' ||
            extensions.code === 'WRONG_REFRESH_TOKEN' ||
            extensions.code === 'NOT_AUTHENTICATED'
          )
        ) {
          localStorage.removeItem(ACCESS_TOKEN);
          if (window.location.pathname.includes('app')) {
            window.location.href = '/';
          }
        }

        if (extensions && extensions.code === 'VALIDATION_ODOMETER_TO_DATE_ERROR') {
          // Extract the relevant error details
          const { filledDate, minOdometer, maxOdometer } = extensions as any;

          // Call your function to update the cache
          odometerValidationModalDataCacheControl.setOdometerValidationModalData(
            filledDate,
            operation,
            minOdometer,
            maxOdometer
          );
        } else {
          Sentry.captureException(errorMsg); // Send the error to Sentry
          setSnackbar('error', extensions.code as string); // Show error snackbar for other errors
        }
      }
    });
  }

  if (networkError) {
    console.log(`[Network error]: ${networkError}`);
    setSnackbar('error', HTTP_CALL_ERROR);

    // const isHttpCallErrorRecent = getWithExpiry(HTTP_CALL_ERROR, HTTP_CALL_ERROR_TTL);
    // if (isHttpCallErrorRecent) {
    //   localStorage.removeItem(ACCESS_TOKEN);
    //   localStorage.removeItem(HTTP_CALL_ERROR);
    //   window.location.href = '/';
    // } else {
    //   setWithExpiry(HTTP_CALL_ERROR, 'true');
    //   window.location.reload();
    // }
  }
});

const client = new ApolloClient({
  // eslint-disable-next-line
  link: ApolloLink.from([ errorLink, refreshLink, authLink, httpLink ]),
  cache,
});

export default client;
