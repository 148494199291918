import React, { 
  useState,
  useEffect,
  FC,
} from 'react';
import {
  IOwnerState,
  ControlState,
  IGeneralFilter,
} from '../types';
import {
  Control,
  ControlLabel,
} from '../styles';
import {
  IconButton,
  Dialog,
  DialogContent,
  DialogActions,
  Box,
  Typography,
  Stack,
  Button,
  Grid,
  TextField,
  Checkbox,
  Paper,
  InputAdornment,
} from '@mechis/elements';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { getColorByState } from '../Selections';
import FilterListIcon from '@mui/icons-material/FilterList';
import { useTranslation } from 'react-i18next';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { useQuery } from '@apollo/client';
import { GET_SELECTIONS } from '@state/queries/selections';
import { ISelectionsQuery } from '@state/queries/selections/types';
import selectionsCacheControl from '@state/mutations/selections';
import boolCount from '@utilities/boolCount';
import useTech, { UnitType } from '@hooks/useTech';
import NumberField from '@mechis/elements/NumberField';

const GeneralFilters: FC<IGeneralFilter> = ({ 
  isDate: showDate,
  isOdometer: showOdometer,
  isPrice: showPrice,
  isRecordType: showRecordType,
}) => {
  const { data } = useQuery<ISelectionsQuery>(GET_SELECTIONS);
  const { 
    setFilterDateFrom: setDateFrom,
    setFilterDateTo: setDateTo,
    setFilterOdometerFrom: setOdometerFrom,
    setFilterOdometerTo: setOdometerTo,
    setFilterPriceFrom: setPriceFrom,
    setFilterPriceTo: setPriceTo,
    setFilterIsDate: setIsDate,
    setFilterIsOdometer: setIsOdometer,
    setFilterIsPrice: setIsPrice,
    setFilterIsRecordOdometer: setIsRecordOdometer,
    setFilterIsRecordRefueling: setIsRecordRefueling,
    setFilterIsRecordExpenses: setIsRecordExpenses,
    setFilterIsRecordMaintenance: setIsRecordMaintenance,
    setIsFilterModalOpen: setIsFilterModalOpen,
  } = selectionsCacheControl;

  const dateFrom = data?.selections.filterDateFrom;
  const dateTo = data?.selections.filterDateTo;
  const isDate = data?.selections.filterIsDate;
  const odometerFrom = data?.selections.filterOdometerFrom;
  const odometerTo = data?.selections.filterOdometerTo;
  const isOdometer = data?.selections.filterIsOdometer;
  const priceFrom = data?.selections.filterPriceFrom;
  const priceTo = data?.selections.filterPriceTo;
  const isPrice = data?.selections.filterIsPrice;
  const isRecordOdometer = data?.selections.filterIsRecordOdometer;
  const isRecordRefueling = data?.selections.filterIsRecordRefueling;
  const isRecordExpenses = data?.selections.filterIsRecordExpenses;
  const isRecordMaintenance = data?.selections.filterIsRecordMaintenance;

  const isSmthSelected = 
    isDate || 
    isPrice || 
    isOdometer || 
    isRecordOdometer || 
    isRecordRefueling || 
    isRecordExpenses || 
    isRecordMaintenance;

  const hasErrors = Number(odometerFrom) > Number(odometerTo);

  const { getTechUnit } = useTech();
  const { t } = useTranslation();
  const [ state, setState ] = useState<ControlState>('default');
  const [ openGeneralFilters, setOpenGeneralFilters ] = useState(false);

  useEffect(() => {
    if (isSmthSelected) {
      setState('filled');
    } else {
      setState('default');
    }
  }, [ isSmthSelected ]);

  useEffect(() => {
    setIsFilterModalOpen(openGeneralFilters);
  }, [ openGeneralFilters ]);

  const ownerState: IOwnerState = {
    state,
  };

  const filtersSelected = boolCount([
    isDate as boolean,
    isOdometer as boolean,
    isPrice as boolean,
    isRecordOdometer as boolean,
    isRecordRefueling as boolean,
    isRecordExpenses as boolean,
    isRecordMaintenance as boolean,
  ]);

  const onSelect = () => {
    setOpenGeneralFilters(false);
    setState('filled');
  };

  const onReset = () => {
    setIsDate(false);
    setIsOdometer(false);
    setIsPrice(false);
    setIsRecordOdometer(false);
    setIsRecordRefueling(false);
    setIsRecordExpenses(false);
    setIsRecordMaintenance(false);
    setOpenGeneralFilters(false);
    setState('default');
  };
  
  return (
    <>
      <Control>
        <IconButton
          size="small"
          color={getColorByState(state)}
          isSquared
          onClick={() => setOpenGeneralFilters(true)}
        >
          <FilterListIcon />
        </IconButton>
        <ControlLabel ownerState={ownerState}>
          {filtersSelected > 0 ? filtersSelected : t('filterManager.iconTitle')}
        </ControlLabel>
      </Control>
      
      <Dialog
        onClose={onReset}
        open={openGeneralFilters}
        maxWidth="xs"
        fullScreen={true}
      >
        <DialogContent>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{ mb: 2 }}
          >
            <Typography variant="sectionHeadline">
              {t('filterManager.title')}
            </Typography>
            <IconButton
              size="small"
              onClick={onReset}
            >
              <HighlightOffIcon />
            </IconButton>
          </Stack>
          <Grid container spacing={1}>
            {showDate && (
              <>
                <Grid item xs={5} sx={{ mt: 1, mb: 1 }}>
                  <MobileDatePicker
                    showToolbar={false}
                    disabled={!isDate}
                    inputFormat="d/M/yy"
                    value={dateFrom}
                    label={t('filterManager.dateFrom')}
                    // @ts-ignore
                    onChange={setDateFrom}
                    renderInput={(params) => <TextField
                      size="small"
                      variant="outlined"
                      {...params}
                    />}
                  />
                </Grid>
                <Grid item xs={5} sx={{ mt: 1, mb: 1 }}>
                  <MobileDatePicker
                    showToolbar={false}
                    disabled={!isDate}
                    inputFormat="d/M/yy"
                    value={dateTo}
                    label={t('filterManager.dateTo')}
                    // @ts-ignore
                    onChange={setDateTo}
                    renderInput={(params) => <TextField
                      size="small"
                      variant="outlined"
                      {...params}
                    />}
                  />
                </Grid>
                <Grid item xs={2} sx={{ mt: 1, mb: 1 }}>
                  <Checkbox
                    size="large"
                    color={isDate ? 'secondary' : 'default'}
                    checked={isDate}
                    onChange={(e) => setIsDate(e.target.checked)}
                    sx={{ mt: -0.5 }}
                  />
                </Grid>
              </>
            )}

            {showOdometer && (
              <>
                <Grid item xs={5} sx={{ mt: 1, mb: 1 }}>
                  <NumberField
                    label={t('filterManager.odometerFrom')}
                    size="small"
                    value={odometerFrom}
                    // @ts-ignore
                    onChange={(e) => setOdometerFrom(e.target.value)}
                    variant="outlined"
                    disabled={!isOdometer}
                    onlyInteger
                    InputProps={{
                      endAdornment: <InputAdornment position="end"><small>{getTechUnit(UnitType.Odometer)}</small></InputAdornment>,
                    }}
                  />
                </Grid>
                <Grid item xs={5} sx={{ mt: 1, mb: 1 }}>
                  <NumberField
                    label={t('filterManager.odometerTo')}
                    size="small"
                    value={odometerTo}
                    error={!!odometerTo && hasErrors}
                    // @ts-ignore
                    onChange={(e) => setOdometerTo(e.target.value)}
                    variant="outlined"
                    disabled={!isOdometer}
                    onlyInteger
                    InputProps={{
                      endAdornment: <InputAdornment position="end"><small>{getTechUnit(UnitType.Odometer)}</small></InputAdornment>,
                    }}
                  />
                </Grid>
                <Grid item xs={2} sx={{ mt: 1, mb: 1 }}>
                  <Checkbox
                    size="large"
                    color={isOdometer ? 'secondary' : 'default'}
                    checked={isOdometer}
                    onChange={(e) => setIsOdometer(e.target.checked)}
                    sx={{ mt: -0.5 }}
                  />
                </Grid>
              </>
            )}

            {showPrice && (
              <>
                <Grid item xs={5} sx={{ mt: 1, mb: 1 }}>
                  <NumberField
                    label={t('filterManager.priceFrom')}
                    size="small"
                    value={priceFrom}
                    // @ts-ignore
                    onChange={(e) => setPriceFrom(e.target.value)}
                    variant="outlined"
                    disabled={!isPrice}
                    onlyInteger
                    InputProps={{
                      endAdornment: <InputAdornment position="end"><small>kč</small></InputAdornment>,
                    }}
                  />
                </Grid>
                <Grid item xs={5} sx={{ mt: 1, mb: 1 }}>
                  <NumberField
                    label={t('filterManager.priceTo')}
                    id="initialConsumption"
                    size="small"
                    value={priceTo}
                    // @ts-ignore
                    onChange={(e) => setPriceTo(e.target.value)}
                    variant="outlined"
                    disabled={!isPrice}
                    onlyInteger
                    InputProps={{
                      endAdornment: <InputAdornment position="end"><small>kč</small></InputAdornment>,
                    }}
                  />
                </Grid>
                <Grid item xs={2} sx={{ mt: 1, mb: 1 }}>
                  <Checkbox
                    size="large"
                    color={isPrice ? 'secondary' : 'default'}
                    checked={isPrice}
                    onChange={(e) => setIsPrice(e.target.checked)}
                    sx={{ mt: -0.5 }}
                  />
                </Grid>
              </>
            )}  
          </Grid>

          {showRecordType && (
            <>
              <Typography
                sx={{
                  fontSize: '12px',
                  background: 'white',
                  display: 'inline-block',
                  paddingRight: '8px',
                  paddingLeft: '8px',
                  position: 'relative',
                  top: '12px',
                  left: '8px',
                }}
              >
                {t('filterManager.titleTypes')}
              </Typography>
              <Paper variant="outlined">
                <Box py={1} px={2}>
                  <Stack spacing={1}>
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between"
                      width="100%"
                    >
                      <Typography
                        variant="sectionHeadline"
                        sx={{ mb: 0, width: '100%' }}
                        color={isRecordOdometer ? 'secondary' : 'default'}
                      >
                        {t('filterManager.types.odometer')}
                      </Typography>
                      <Checkbox
                        size="large"
                        color={isRecordOdometer ? 'secondary' : 'default'}
                        checked={isRecordOdometer}
                        onChange={(e) => setIsRecordOdometer(e.target.checked)}
                      />
                    </Stack>
                    <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
                      <Typography
                        variant="sectionHeadline"
                        sx={{ mb: 0, width: '100%' }}
                        color={isRecordRefueling ? 'secondary' : 'default'}
                      >
                        {t('filterManager.types.refueling')}
                      </Typography>
                      <Checkbox
                        size="large"
                        checked={isRecordRefueling}
                        onChange={(e) => setIsRecordRefueling(e.target.checked)}
                        color={isRecordRefueling ? 'secondary' : 'default'}
                      />
                    </Stack>
                    <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
                      <Typography
                        variant="sectionHeadline"
                        sx={{ mb: 0, width: '100%' }}
                        color={isRecordExpenses ? 'secondary' : 'default'}
                      >
                        {t('filterManager.types.expenses')}
                      </Typography>
                      <Checkbox
                        size="large"
                        checked={isRecordExpenses}
                        onChange={(e) => setIsRecordExpenses(e.target.checked)}
                        color={isRecordExpenses ? 'secondary' : 'default'}
                      />
                    </Stack>
                    <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
                      <Typography
                        variant="sectionHeadline"
                        sx={{ mb: 0, width: '100%' }}
                        color={isRecordMaintenance ? 'secondary' : 'default'}
                      >
                        {t('filterManager.types.maintenance')}
                      </Typography>
                      <Checkbox
                        size="large"
                        checked={isRecordMaintenance}
                        onChange={(e) => setIsRecordMaintenance(e.target.checked)}
                        color={isRecordMaintenance ? 'secondary' : 'default'}
                      />
                    </Stack>
                  </Stack>
                </Box>
              </Paper>
            </>
          )} 
        </DialogContent>
        <DialogActions>
          <Stack 
            direction="row"
            justifyContent="space-between"
            sx={{ 
              width: '100%',
              pl: 2,
              pr: 2,
              pb: 1,
            }}
          >
            <Button
              variant="outlined"
              color="primary"
              disabled={!isSmthSelected}
              onClick={onReset}
              sx={{ mt: -5 }}
            >
              {t('filterManager.resetFiltersBtn')}
            </Button>
            <Button
              variant="contained"
              color="secondary"
              disabled={!isSmthSelected || hasErrors}
              onClick={onSelect}
              sx={{ mt: -5 }}
            >
              {t('filterManager.selectFiltersBtn')}
            </Button>
          </Stack>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default GeneralFilters;
