import React, { useEffect } from 'react';
import { 
  Typography,
  Paper,
  Box,
  Stack,
  IconButton,
  Button,
} from '@mechis/elements';
import Expense from '@mechis/blocks/Expense';
import { useTranslation } from 'react-i18next';
import NumberFormat from 'react-number-format';
import TagFilters from '@mechis/sections/Selections/TagFilters';
import _ from 'lodash';
import { IExpense, ITagOption } from '@mechis/sections/Selections/TagFilters/types';
import useExpense from '@hooks/useExpense';
import useTagManager from '@hooks/useTagManager';
import { IPrice } from '@state/models/newExpense/types';
import { IProps } from '@mechis/sections/Expenses/types';
import EmojiObjectsIcon from '@mui/icons-material/EmojiObjects';
import AddCircleIcon from '@mui/icons-material/AddCircle';

const Expenses: React.FC<IProps> = ({ existedExpensesInfo }) => {
  const { t } = useTranslation();
  const {
    tags,
    tagsOptions,
    toggleTag,
  } = useTagManager(existedExpensesInfo);

  const { 
    expenses,
    setExpenses,
    prices,
    setPrices,
    priceTotal,
    setPriceTotal,
  } = useExpense();

  const onDelete = (tagId: string) => {
    const newValue = _.filter(expenses, (item) => {
      return item.tagId !== tagId;
    });
    setExpenses(newValue);
    toggleTag(tagId);
  };

  useEffect(() => {
    if (prices) {
      const totalPrice = prices.reduce((acc, obj) => acc + obj.price, 0);
      setPriceTotal(totalPrice);
    }
  }, [ prices ]);

  useEffect(() => {
    if (tags && tagsOptions && tagsOptions.length > 0) {
      const result = tagsOptions?.filter((tagOption: ITagOption) => tags.includes(tagOption.tagId));
      
      // @ts-ignore
      const pricesMap = result?.map((expense: IExpense) => {
        return {
          tagId: expense.tagId,
          price: getPriceByTag(expense.tagId) ?? 0,
        };
      });

      setExpenses(result);
      setPrices(pricesMap);
    }
  }, [ tags, tagsOptions ]);

  const getPriceByTag = (tagId: string) => {
    if (!existedExpensesInfo) {
      return _.filter(prices, { tagId })[0]?.price;
    } else {
      const expense = existedExpensesInfo?.expensesByEvent?.expense;
      const existedExpense = _.find(expense, (item) => item.tag.tagId === tagId);
      return existedExpense?.price || 0;
    }
  };
  
  const updatePriceByTag = (tagId: string, price: number) => {
    if (prices) {
      const indexToUpdate = prices?.findIndex((item: IPrice) => item.tagId === tagId);
      if (indexToUpdate !== -1) {
        const updatedPrices = [ ...prices ];
        updatedPrices[indexToUpdate] = {
          ...updatedPrices[indexToUpdate],
          price,
        };
        setPrices(updatedPrices);
      }
    }
  };

  return (
    <Paper>
      <Box p={2}>
        <Paper variant="outlined">
          <Box p={2}>
            <Stack spacing={2.5} pt={expenses.length === 0 ? 0 : 2}>
              {expenses?.length === 0 && (
                <Box position="relative">
                  <Box p={2} textAlign="center">
                    <IconButton
                      color="warning"
                      size="large"
                      sx={{
                        position: 'relative',
                        mt: -12,
                      }}
                    >
                      <EmojiObjectsIcon fontSize="large" />
                    </IconButton>
                    <Typography mb={-2} mt={-2}>
                      <small>
                        {t('components.expense.warningText')}
                      </small>
                    </Typography>
                  </Box>
                </Box>
              )}
              {expenses?.map((expense: ITagOption, index) => {
                return (
                  <Expense
                    key={`expense-${index}-${expense.tagId}`}
                    price={getPriceByTag(expense.tagId)}
                    setPrice={(price) => updatePriceByTag(expense.tagId, price)}
                    tagId={expense.tagId}
                    onDelete={() => onDelete(expense.tagId)}
                    title={t(`tagManager.id.${expense.tagId}`)}
                    tagCategory={expense.tagCategory.name}
                  />
                );
              })}
              <TagFilters
                customControl={
                  <Button
                    variant={expenses.length === 0 ? 'contained' : 'outlined'}
                    color="secondary"
                    size={expenses.length === 0 ? 'medium' : 'small'}
                    sx={{
                      margin: '0 auto',
                      display: 'flex',
                      float: expenses.length === 0 ? 'none' : 'right',
                    }}
                    startIcon={<AddCircleIcon />}
                  >
                    {t('addExpense.chooseTags')}
                  </Button>
                }
              />
            </Stack>
          </Box>
        </Paper>
        <Stack 
          direction="row" 
          alignItems="center"
          justifyContent="space-between"
          sx={{ 
            mt: 2,
            display: expenses.length === 0 ? 'none' : 'block',
          }}
        >
          <Box pl={2} />
          <Box pr={2}>
            <Typography
              variant="sectionHeadline"
              align="right"
              sx={{ 
                textTransform: 'uppercase',
                display: 'block',
                mb: (theme) => theme.spacing(-1.5),
              }}
            >
              {t('addExpense.priceTotal')}
            </Typography>
            <Typography variant="h1" align="right">
              <NumberFormat
                value={priceTotal}
                suffix={` ${t('addExpense.currency')}`}
                displayType="text"
                thousandSeparator={' '}
              />
            </Typography>
          </Box>
        </Stack>
      </Box>
    </Paper>
  );
};

export default Expenses;
