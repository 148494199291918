import variants from './variants';
import styleOverrides from './styleOverrides';
import breakpoints from './breakpoints';

const components = {
  MuiCssBaseline: {
    styleOverrides: {
      html: {
        fontSize: '11px',
        [`@media (min-width: ${breakpoints.iPhone5}px)`]: {
          fontSize: '11px',
        },
        [`@media (min-width: ${breakpoints.galaxyS8}px)`]: {
          fontSize: '12px',
        },
        [`@media (min-width: ${breakpoints.iPhoneX}px)`]: {
          fontSize: '14px',
        },
        [`@media (min-width: ${breakpoints.iPhone12}px)`]: {
          fontSize: '16px',
        },
        [`@media (min-width: ${breakpoints.pixel5}px)`]: {
          fontSize: '16px',
        },
        [`@media (min-width: ${breakpoints.iPhone14ProMax}px)`]: {
          fontSize: '16px',
        },
        [`@media (min-width: ${breakpoints.sm}px)`]: {
          fontSize: '16px',
        },
      },
    },
  },
  MuiInputBase: {
    styleOverrides: styleOverrides['inputBase'],
  },
  MuiAppBar: {
    variants: variants['appbar'],
  },
  MuiButton: {
    styleOverrides: styleOverrides['button'],
    variants: variants['button'],
  },
  MuiToolbar: {
    styleOverrides: styleOverrides['toolbar'],
  },
  MuiBottomNavigation: {
    styleOverrides: styleOverrides['bottomNavigation'],
  },
  MuiBottomNavigationAction: {
    styleOverrides: styleOverrides['bottomNavigationAction'],
  },
  MuiPaper: {
    styleOverrides: styleOverrides['paper'],
    variants: variants['paper'],
  },
  MuiTextField: {
    styleOverrides: styleOverrides['textField'],
    variants: variants['textField'],
  },
  MuiAccordion: {
    styleOverrides: styleOverrides['accordion'],
  },
  MuiListItemText: {
    variants: variants['listItemText'],
  },
  MuiIconButton: {
    variants: variants['iconButton'],
  },
  MuiMenuItem: {
    styleOverrides: styleOverrides['menuItem'],
  },
  MuiCheckbox: {
    variants: variants['checkbox'],
  },
  MuiTooltip: {
    styleOverrides: styleOverrides['tooltip'],
  },
  MuiDialog: {
    styleOverrides: styleOverrides['dialog'],
  },
};

export default components;
