import React, { FC } from 'react';
import { IAnalysisWidgetFuelRowProps } from './types';
import { v4 as uuid } from 'uuid';
import {
  useAnalyzeQuery,
  AnalyzeCategory,
  AnalyzeCommand,
} from '@state/mechis-backend/generated/schema';
import { Stack, TableCell, TableRow } from '@mechis/elements';
import { useTranslation } from 'react-i18next';
import AnalysisWidgetSkeletonRow from './AnalysisWidgetSkeletonRow';
import NumberFormat from 'react-number-format';
import { THOUSAND_SEPARATOR } from '@configurations/constants/app';
import useTech from '@hooks/useTech';

const AnalysisWidgetFuelRow: FC<IAnalysisWidgetFuelRowProps> = ({
  fuelId,
  filters,
  type,
  unit,
}) => {
  const { t } = useTranslation();
  const { techId } = useTech();

  const { data, loading } = useAnalyzeQuery({
    variables: {
      deviceId: techId,
      analyzeCategory: AnalyzeCategory.Refuelings,
      command: AnalyzeCommand.Avg,
      tags: [],
      filters: filters,
      fuelId: parseInt(fuelId),
    },
    skip: !techId,
  });

  if (loading) return <AnalysisWidgetSkeletonRow />;

  const resolveLabel = () => {
    if (!type) return t('analysisWidget.consumption');

    return t('analysisWidget.consumption', {
      type: '(' + t(`fuelTypes.${type}`) + ')',
    });
  };

  const resolveValue = () => {
    const consumption = data?.analyze?.find((item) => item?.rowDescriptionKey.includes('consumption'));
    
    return consumption?.value;
  };

  return (
    <TableRow key={uuid()}>
      <TableCell>
        <Stack flexDirection="row" sx={{ lineHeight: 1 }} alignItems="center">
          {resolveLabel()}
        </Stack>
      </TableCell>
      <TableCell align="right" sx={{ width: '35%' }}>
        <NumberFormat
          value={resolveValue()}
          displayType="text"
          thousandSeparator={THOUSAND_SEPARATOR}
          suffix={unit ? `\u00a0${unit}` : undefined}
          decimalScale={2}
        />
      </TableCell>
    </TableRow>
  );
};

export default AnalysisWidgetFuelRow;
