import { styled } from '@mui/material/styles';
import { shouldForwardProp as sysShouldForwardProp } from '@mui/system';
import { IOwnerState } from './types';
import { Typography } from '@mechis/elements';

export const Section = styled('header', {
  name: 'AppHeader',
  slot: 'Root',
  shouldForwardProp: (prop) => sysShouldForwardProp(prop) && prop !== 'ownerState',
})<{ ownerState: IOwnerState }>(({ theme, ownerState }) => ({
  '& > header': {
    borderTopRightRadius: 0,
    borderTopLeftRadius: 0,
    // eslint-disable-next-line
    ...theme.mixins.appHeaderSize(ownerState.isIOSMobilePWA ? theme.constants.appHeaderHeightPWALarge : theme.constants.appHeaderHeightLarge),
    ...(ownerState.isSmallHeader && {
      // eslint-disable-next-line
      ...theme.mixins.appHeaderSize(ownerState.isIOSMobilePWA ? theme.constants.appHeaderHeightPWASmall : theme.constants.appHeaderHeightSmall),
    }),
    transition: theme.transitions.create([ 'min-height', 'height' ], {
      duration: theme.transitions.duration.standard,
    }),
    ...(ownerState.isIOSMobilePWA && {
      paddingTop: theme.constants.pwaOffset,
    }),
  },
  ...(ownerState.isTrigger && {
    '& > header': {
      // eslint-disable-next-line
      ...theme.mixins.appHeaderSize(ownerState.isIOSMobilePWA ? theme.constants.appHeaderHeightPWASmall : theme.constants.appHeaderHeightSmall),
      borderTopRightRadius: 0,
      borderTopLeftRadius: 0,
      transition: theme.transitions.create([ 'min-height', 'height' ], {
        duration: theme.transitions.duration.standard,
      }),
      ...(ownerState.isIOSMobilePWA && {
        paddingTop: theme.constants.pwaOffset,
      }),
    },
  }),
  ...(ownerState.isMenuOpened && {
    '& > header': {
      borderTopRightRadius: 0,
      borderTopLeftRadius: 0,
      height: '100%',
      zIndex: 1250,
    },
  }),
}));

export const HeaderWidget = styled('div', {
  name: 'AppHeader',
  slot: 'Widget',
})(({ theme }) => ({
  width: '100%',
  paddingTop: theme.spacing(1),
}));

export const AppHeadline = styled(Typography, {
  name: 'AppHeader',
  slot: 'Headline',
})(({ theme }) => ({
  color: theme.palette.primary.dark,
}));

export const AppHeadlineMobile = styled(Typography, {
  name: 'AppHeader',
  slot: 'MobileHeadline',
})(({ theme }) => ({
  flexGrow: 1,
  textAlign: 'center',
  fontWeight: 500,
  color: theme.palette.common.white,
}));

