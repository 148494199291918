import { styled } from '@mui/material/styles';
import { isMobile } from 'react-device-detect';
import { shouldForwardProp as sysShouldForwardProp } from '@mui/system';
import { IOwnerState } from './types';

export const Section = styled('main', {
  name: 'AppContent',
  slot: 'Root',
  shouldForwardProp: (prop) => sysShouldForwardProp(prop) && prop !== 'ownerState',
})<{ ownerState: IOwnerState }>(({ theme, ownerState }) => ({
  ...(isMobile && {
    // eslint-disable-next-line
    marginTop: `calc(${ownerState.isIOSMobilePWA ? theme.constants.appHeaderHeightPWASmall : theme.constants.appHeaderHeightSmall}px + 0px)`,
    ...(!ownerState.isSmallHeader && {
      // eslint-disable-next-line
      marginTop: `calc(${ownerState.isIOSMobilePWA ? theme.constants.appHeaderHeightPWALarge : theme.constants.appHeaderHeightLarge}px + 30px)`,
    }),

    // PWA MIN HEIGHT RESOLVER FIX
    ...(ownerState.isIOSMobilePWA && {
      // border: '1px dashed black',
      // eslint-disable-next-line
      minHeight: ownerState.isSmallHeader ? `calc(100vh - 60px - ${theme.constants.appHeaderHeightPWASmall}px)` : `calc(100vh - 60px - ${theme.constants.appHeaderHeightPWALarge}px - 30px)`,
    }),
  }),
}));
