import React, {FC} from 'react';
import { IProps, IOwnerState } from './types';
import { Section } from './styles';
import {
  Box,
  Stack,
} from '@mechis/elements';
import useAppHeaderWidget from '@hooks/useAppHeaderWidget';
import useDetect from '@hooks/useDetect';

const AppContent: FC<IProps> = ({ children }) => {
  const { widget } = useAppHeaderWidget();
  const { isIOSMobilePWA } = useDetect();

  const ownerState: IOwnerState = {
    isSmallHeader: widget ? false : true,
    isIOSMobilePWA,
  };

  return (
    <Section ownerState={ownerState}>
      <Box px={2.5} py={4} sx={{ display: 'flex'}}>
        <Stack direction="row" spacing={2} sx={{ width: '100%' }}>
          <Box sx={{ width: '100%' }}>
            {children}
          </Box>
        </Stack>
      </Box>
    </Section>
  );
};

export default AppContent;
