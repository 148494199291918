import React from 'react';
import {
  Paper,
  Box,
  IconButton,
  Link as MuiLink,
} from '@mechis/elements';
import Typography from '@mechis/elements/Typography';
import useHeader from '@hooks/useHeader';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import EmojiObjectsIcon from '@mui/icons-material/EmojiObjects';
import { Link } from 'react-router-dom';

const Calendar = () => {
  useHeader({ headline: 'appBar.headline.calendar' });

  return (
    <>
      <Paper variant="outlined" sx={{ position: 'relative' }}>
        <FormatQuoteIcon
          color="disabled"
          fontSize="large"
          sx={{
            position: 'absolute',
            top: '8px',
            left: '8px',
            transform: 'rotate(180deg)',
            opacity: '0.4',
          }}
        />
        <Box p={2} textAlign="center">
          <IconButton
            color="warning"
            size="large"
            sx={{
              position: 'relative',
              mt: -6,
            }}
          >
            <EmojiObjectsIcon fontSize="large" />
          </IconButton>
          <Typography mb={2}>
            <small>Omlouváme se, ale tato funkce není momentálně dostupná.</small>
          </Typography>
          <Typography mb={2}>
            <small>Usilovně pracujeme na vývoji, abychom ji co nejdříve dokončili.</small>
          </Typography>
          <Typography mb={2}>
            <small>Sledujte nás na
              <MuiLink
                component={Link}
                to="https://www.facebook.com/MechisApp"
              > Facebooku</MuiLink>
            , nebo
              <MuiLink
                component={Link}
                to="https://www.instagram.com/mechisak/"
              > Instagramu</MuiLink>
            , kde vás budeme informovat.</small>
          </Typography>
        </Box>
        <FormatQuoteIcon
          color="disabled"
          fontSize="large"
          sx={{
            position: 'absolute',
            bottom: '8px',
            right: '8px',
            opacity: '0.4',
          }}
        />
      </Paper>
    </>
  );
};

export default Calendar;
