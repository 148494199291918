import { useEffect, useState } from 'react';
import { PluginProps } from 'yet-another-react-lightbox';
import useAttachments from '../useAttachments';

const AttachmentsLightboxDynamicUrlPlugin = ({ augment }: PluginProps) => {
  augment(({ render, ...restProps }) => {
    const { getFileURL } = useAttachments();

    return {
      render: {
        ...render,
        slide: (props) => {
          const [ source, setSource ] = useState<string | null>(null);

          useEffect(() => {
            if (props.offset === 0) {
              const fetchAvatarUrl = async () => {
                try {
                  const url = await getFileURL(props.slide.src);
                  setSource(url);
                } catch (error) {
                  console.error('Error fetching avatar URL:', error);
                  setSource(null);
                }
              };
              fetchAvatarUrl();
            }
          }, [ props.slide.src, props.offset ]);

          return render.slide?.({
            ...props,
            slide: {
              ...props.slide,
              src: source || props.slide.src,
            },
          });
        },
      },
      ...restProps,
    };
  });
};

export default AttachmentsLightboxDynamicUrlPlugin;
