import React, { FC, useState } from 'react';
import { IProps, IOwnerState } from './types';
import { Section, Title } from './styles';
import { Box, Dialog, Image, Typography } from '@mechis/elements';
import { useTranslation } from 'react-i18next';
import { useDeviceHeaderDetailQuery } from '@state/mechis-backend/generated/schema';
import { DeviceListing } from '@mechis/sections/DeviceListing/DeviceListing';
import Loader from '@mechis/blocks/Loader';
import ErrorIcon from '@mui/icons-material/Error';
import useTech from '@hooks/useTech';

const SelectedTech: FC<IProps> = ({ variant = 'mobile' }) => {
  const [ t ] = useTranslation();
  const { techId } = useTech();

  const { data: headerDetail, loading: headerDetailLoading, error: headerDeviceError } = useDeviceHeaderDetailQuery({
    variables: {
      deviceId: techId,
    },
    skip: !techId,
    fetchPolicy: 'cache-first',
  });

  const ownerState: IOwnerState = {
    variant,
  };
  const [ isDeviceSwitcherOpen, setIsDeviceSwitcherOpen ] = useState<boolean>(false);

  if (headerDetailLoading) {
    return (
      <Loader />
    );
  }

  if (headerDeviceError) {
    return (
      <ErrorIcon />
    );
  }

  return (
    <>
      <Dialog
        open={isDeviceSwitcherOpen}
        onClose={() => setIsDeviceSwitcherOpen(false)}
      >
        <Box p={2}>
          <Typography
            variant="h4"
            sx={{
              marginBottom: (theme) => theme.spacing(2),
            }}
          >
            {t('appBar.deviceSelect.title')}
          </Typography>
          <div style={{maxHeight: '70vh'}}>
            <DeviceListing
              onClick={() => setIsDeviceSwitcherOpen(false)}
              isInModal
            />
          </div>
        </Box>
      </Dialog>
      <Section ownerState={ownerState} onClick={() => setIsDeviceSwitcherOpen(true)}>
        <Box mr={2}>
          <Image
            src="https://www.designmag.cz/foto/2017/03/volkswagen-arteon-0.jpg"
            alt="Popisek obrázku, může se rovnat hodnotě názvu techniky"
            width={variant === 'desktop' ? '60' : '100'}
            height={variant === 'desktop' ? '60' : '100'}
            effect="blur"
            fit="cover"
            containerProps={{
              bg: 'background',
            }}
            radius={variant === 'desktop' ? 8 : 12}
            padding={variant === 'desktop' ? 'small' : 'medium'}
          />
        </Box>
        <Box p={0.5}>
          <Title variant="h3">{headerDetail?.device?.name}</Title>
          {/*
          <Vrp 
            variant="transparent"
            vrp={headerDetail?.device?.spz}
            serialNumber={headerDetail?.device?.serialNumber}
          />
          */}
        </Box>
      </Section>
    </>
  );
};

export default SelectedTech;
