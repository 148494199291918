import React, { FC, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  Dialog,
  LinearProgress,
  Paper,
  Typography,
  Stack,
  DialogTitle,
  DialogContent,
  Slider,
  DialogActions,
} from '@mechis/elements';
import normaliseToPercentage from '@utilities/normaliseToPercentage';
import {
  useMembershipsQuery,
  UserDataQuoteDocument,
  useUpgradeDataQuoteMutation,
  useUserDataQuoteQuery,
} from '@state/mechis-backend/generated/schema';
import Loader from '@mechis/blocks/Loader';
import snackbarCacheControl from '@state/mutations/snackbar';
import { IProps } from './types';
import { formatSize } from '@utilities/app';
import { BASE_DATA_QUOTE_UNIT } from '@configurations/constants/price';
import useNavigate from '@hooks/useNavigate';
import CloudQueueIcon from '@mui/icons-material/CloudQueue';

const upgradeOptions = [
  {
    value: 0,
    label: '256 MB',
    count: 1,
  },
  {
    value: 25,
    label: '512 MB',
    count: 2,
  },
  {
    value: 50,
    label: '1024 MB',
    count: 4,
  },
  {
    value: 100,
    label: '2048 MB',
    count: 8,
  },
];

const StorageCapacityWidget: FC<IProps> = ({ setNoCreditOpen }) => {
  const { t } = useTranslation();
  const { setSnackbar } = snackbarCacheControl;
  const { searchParams } = useNavigate();
  const [ isDialogOpen, toggleDialogOpen ] = useState<boolean>(false);
  const { data, loading } = useUserDataQuoteQuery();
  const { data: memberShipData } = useMembershipsQuery();
  const PRICE_CLOUD = memberShipData?.memberships?.pricePerDataUnit ?? 0;

  const [ upgradeDataQuoteMutation ] = useUpgradeDataQuoteMutation({
    onCompleted: () => setSnackbar('success', 'DATA_QUOTE_UPDATE_SUCCESSFUL'),
    onError: () => setSnackbar('error', 'DATA_QUOTE_UPDATE_ERROR'),
    refetchQueries: [
      {
        query: UserDataQuoteDocument,
      },
    ],
  });
  const [ selectedOption, setSelectedOption ] = useState<number>(0);
  const selectedDataQuoteToUpgrade: number = upgradeOptions.find((el) => el.value === selectedOption)?.count ?? 0;

  useEffect(() => {
    const openModalAccounts = searchParams.get('openModalDataQuote');
    if (openModalAccounts) toggleDialogOpen(true);
  }, [ searchParams ]);

  const dataVolume = data?.user?.dataVolume ?? 0;
  const dataQuote = data?.user?.dataQuote ?? 0;
  const creditBalance = data?.user?.creditBalance ?? 0;
  const dataFree = data?.user?.membership.dataFree ?? 0;

  const getDataQuotePaidByPriceCloudForNewDataPreview = () => {
    return (dataQuote + (BASE_DATA_QUOTE_UNIT * selectedDataQuoteToUpgrade));
  };

  const getDataQuotePaidByPriceCloud = () => {
    return ((dataQuote - dataFree) + (BASE_DATA_QUOTE_UNIT * selectedDataQuoteToUpgrade));
  };

  const handleDialogOpen = () => {
    toggleDialogOpen(() => true);
  };
  const handleDialogClose = () => {
    toggleDialogOpen(() => false);
  };

  const handleEnlargeStorage = async () => {
    if (creditBalance < (PRICE_CLOUD * selectedDataQuoteToUpgrade)) {
      setNoCreditOpen(true);
      handleDialogClose();
      return;
    }
  
    await upgradeDataQuoteMutation({
      variables: {
        countOfDataQuoteToUnlock: selectedDataQuoteToUpgrade,
      },
    });
    handleDialogClose();
  };

  if (loading) {
    return <Loader size={80} />;
  }

  const getValueForProgressBar = (value: number, max: number) => value < max ? normaliseToPercentage(value, max) : 100;

  return (
    <Box>
      <Typography variant="sectionHeadline">{t('cloud.storage.title')}</Typography>
      <Paper>
        <Box p={3} textAlign="center">
          <Stack spacing={2} alignItems="center">
            <Typography sx={{ lineHeight: 1 }}>
              <small style={{ fontSize: '0.75em' }}>
                {t('cloud.storage.used', { dataVolume: formatSize(dataVolume), dataQuote: formatSize(dataQuote) })}
              </small>
            </Typography>
            <LinearProgress
              variant="determinate"
              color={dataVolume < dataQuote ? 'secondary' : 'error'}
              value={getValueForProgressBar(dataVolume, dataQuote)}
              sx={{
                width: '100%',
                height: '10px',
                borderRadius: '8px',
              }}
            />
            <Button variant="contained" onClick={handleDialogOpen} color="secondary">
              {t('cloud.storage.add')}
            </Button>
          </Stack>
        </Box>
      </Paper>
      <Dialog open={isDialogOpen} onClose={handleDialogClose}>
        <DialogTitle>
          <Stack direction="column" alignItems="center" justifyContent="center">
            <Typography variant="sectionHeadline">
              {t('cloud.storage.dialog.enlargeStorage')}
            </Typography>
            <CloudQueueIcon color="secondary" fontSize="large" />
          </Stack>
        </DialogTitle>
        <DialogContent sx={{ p: 4 }}>
          <Typography variant="body1" align="center">
            <small>
              <Trans
                i18nKey="cloud.storage.dialog.description"
                values={{
                  storageValue: BASE_DATA_QUOTE_UNIT + 'MB',
                  mechisCreditValue: PRICE_CLOUD,
                }}
                components={{
                  m: <span style={{ textDecoration: 'line-through' }} />,
                }}
              />
            </small>
            
          </Typography>
          <Box pt={4}>
            <Stack>
              <Typography variant="body1" textAlign="center">
                <small>
                  {t('cloud.storage.dialog.addDataQuote')}
                </small>
              </Typography>
              <Box px={3}>
                <Slider
                  value={selectedOption}
                  step={null}
                  valueLabelDisplay="auto"
                  valueLabelFormat={(value) => {
                    const storage = upgradeOptions.find((el) => el.value === value);
                    return storage?.label ?? value;
                  }}
                  onChange={(_e, value) => setSelectedOption(value as number)}
                  marks={upgradeOptions}
                  color="secondary"
                />
              </Box>
            </Stack>
          </Box>
          <Box mt={2}>
            <Typography variant="body1" textAlign="center">
              <small>
                <Trans
                  i18nKey="cloud.storage.dialog.newTotalDataQuote"
                  values={{
                    newDataQuote: formatSize(
                      getDataQuotePaidByPriceCloudForNewDataPreview()
                    ),
                  }}
                />
              </small>
            </Typography>
          </Box>
          <Box mt={2}>
            <Typography variant="body1" textAlign="center">
              <small>
                <Trans
                  i18nKey="cloud.storage.dialog.newTotalDataQuotePrice"
                  values={{
                    newDataQuotePrice:
                    (getDataQuotePaidByPriceCloud() / BASE_DATA_QUOTE_UNIT) *
                    PRICE_CLOUD,
                  }}
                  components={{
                    m: <span style={{ textDecoration: 'line-through' }} />,
                  }}
                />
              </small>
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions sx={{ p: 4 }}>
          <Box display="flex" justifyContent="center" width="100%" flexDirection={'column'}>
            <Button
              color="secondary"
              variant="contained" 
              onClick={handleEnlargeStorage} 
              autoFocus={true}
            >
              {t('cloud.storage.dialog.enlargeStorage')}
            </Button>
            <Typography variant="body2" align="center" sx={{ opacity: 0.65 }}>
              <small>
                <em>
                  {t('cloud.storage.dialog.info')}
                </em>
              </small>
            </Typography>
          </Box>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default StorageCapacityWidget;
