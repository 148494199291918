import React, { useEffect, useState } from 'react';
import {
  Paper,
  Box,
  Stack,
  Button,
  TextField,
} from '@mechis/elements';
import { useTranslation } from 'react-i18next';
import { TagsOrderedByUsageDocument, useCreateExpenseEventMutation, UserDocument } from '@state/mechis-backend/generated/schema';
import { GET_SELECTIONS } from '@state/queries/selections';
import { ISelectionsQuery } from '@state/queries/selections/types';
import { useQuery, useReactiveVar } from '@apollo/client';
import { EVENT_TYPE_EXPENSE } from '@configurations/constants/eventTypes';
import snackbarCacheControl from '@state/mutations/snackbar';
import { useNavigate, useParams } from 'react-router-dom';
import { CURRENCY_DEFAULT_ID, TAGS_BY_USAGE_LIMIT } from '@configurations/constants/app';
import _ from 'lodash';
import { ITagOption } from '@mechis/sections/Selections/TagFilters/types';
import useHeader from '@hooks/useHeader';
import Selections from '@mechis/sections/Selections';
import useExpense from '@hooks/useExpense';
import Expenses from '@mechis/sections/Expenses';
import selectionsCacheControl from '@state/mutations/selections';
import { useAttachments } from '@mechis/sections/Attachments';
import useTech from '@hooks/useTech';
import odometerValidationModalData from '@state/models/odometerValidationModalData';
import odometerValidationModalDataCacheControl from '@state/mutations/odometerValidationModalData';

const NewExpenses = () => {
  useHeader({
    headline: 'appBar.headline.expenses',
    widget: (
      <Selections
        date={{ isSelection: true, order: 1 }}
        odometer={{ isSelection: true, order: 2 }}
        attachments={{ isSelection: true, order: 3 }}
      />
    ),
  });

  const { step } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    expenses,
    //setExpenses,
    priceTotal,
    prices,
    resetUseExpenseState,
  } = useExpense();
  const { techId } = useTech();
  const [ isUploading, setIsUploading ] = useState<boolean>(false);
  const { data: selectionData } = useQuery<ISelectionsQuery>(GET_SELECTIONS);
  const [ eventDate, setEventDate ] = useState<Date>(new Date);
  const [ description, setDescription ] = useState<string>('');
  const { setSnackbar } = snackbarCacheControl;
  const { uploadFiles, setSelectedFiles } = useAttachments();
  const { setOdometerState, resetMutationsInputSelections } = selectionsCacheControl;
  const reactiveOdometerToDateValidateModal = useReactiveVar(odometerValidationModalData);
  const [ createExpense ] = useCreateExpenseEventMutation({
    onCompleted: () => {
      setSelectedFiles([]);
      setSnackbar('success', 'EXPENSE_CREATE_SUCCESSFUL');
      setIsUploading(false);
      resetUseExpenseState();
      resetMutationsInputSelections();
      odometerValidationModalDataCacheControl.resetIgnoreNextValidation();
      navigate(`/app/${techId}/tech-detail/records`);
    },
    onError: () => {
      setSnackbar('error', 'EXPENSE_CREATE_ERROR');
      setIsUploading(false);
    },
    refetchQueries: [
      {
        query: TagsOrderedByUsageDocument,
        variables: {
          limit: TAGS_BY_USAGE_LIMIT,
        },
      },
      { query: UserDocument },
    ],
  });
  const odometerState = selectionData?.selections.odometerState;

  useEffect(() => {
    return () => resetUseExpenseState();
  }, []);

  const onSaveExpense = async () => {
    if (!techId) {
      return;
    }

    if ((selectionData?.selections?.odometerValue ?? 0) < 1) {
      setSnackbar('error', 'ODOMETER_MISSING');
      setOdometerState('missing');
      return;
    }

    if (expenses) {
      setIsUploading(true);
      let attachmentIds = [];
      const uploadedFiles = await uploadFiles();
      if (uploadedFiles) {
        const ids = uploadedFiles.map((attachment) => attachment.id);
        attachmentIds = ids;
      }

      await createExpense({
        variables: {
          event: {
            device: {
              id: techId,
            },
            name: 'Výdaj',
            eventType: {
              id: EVENT_TYPE_EXPENSE,
            },
            eventDate: eventDate,
            priceTotal,
            stateOdometer: Number(selectionData?.selections.odometerValue),
            description,
          },
          expenses: expenses.map((expense: ITagOption) => {
            return {
              tag: {
                id: +expense.id,
              },
              currency: {
                id: CURRENCY_DEFAULT_ID,
              },
              price: _.filter(prices, { tagId: expense.tagId })[0].price,
            };
          }),
          attachmentIds,
          ignoreValidation: reactiveOdometerToDateValidateModal?.ignoreNextValidation,
        },
      });
    }
  };

  useEffect(() => {
    if (selectionData?.selections?.dateValue) {
      setEventDate(selectionData?.selections?.dateValue);
    }
  }, [ selectionData ]);

  if (step && step === 'note') {
    return (
      <>
        <Paper>
          <Box py={3} px={2.5}>
            <TextField
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              label={t('addExpense.description')}
              multiline
              rows={8}
              variant="outlined"
              type="text"
              id="newExpenseNote"
              InputProps={{
                startAdornment: <></>,
              }}
            />
          </Box>
        </Paper>
        <Button
          variant="contained"
          color="secondary"
          onClick={onSaveExpense}
          isLoading={isUploading}
          sx={{
            maxWidth: '160px',
            display: 'block',
            marginLeft: 'auto',
            marginRight: 'auto',
            marginTop: (theme) => theme.spacing(2),
          }}
        >
          {t('addExpense.btnCreate')}
        </Button>
      </>
    );
  }

  return (
    <>
      <Expenses />
      {expenses.length > 0 && (
        <Stack alignItems="center" spacing={1.5} sx={{ mt: 2.5 }}>
          <Button
            variant="contained"
            color="secondary"
            disabled={expenses?.length === 0 || odometerState === 'missing'}
            onClick={() => {
              if ((selectionData?.selections?.odometerValue ?? 0) < 1) {
                setSnackbar('error', 'ODOMETER_MISSING');
                setOdometerState('missing');
                return;
              }
              navigate('note');
            }}
          >
            {t('addExpense.btnContinue')}
          </Button>
          <Button
            variant="text"
            color="primary"
            size="small"
            disabled={expenses?.length === 0 || odometerState === 'missing'}
            onClick={onSaveExpense}
            sx={{
              maxWidth: '160px',
              display: 'block',
              marginLeft: 'auto',
              marginRight: 'auto',
              marginTop: (theme) => theme.spacing(2),
              textDecoration: 'underline',
            }}
          >
            {t('addExpense.btnSkip')}
          </Button>
        </Stack>
      )}
    </>
  );
};

export default NewExpenses;
