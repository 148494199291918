import React, { FC, useEffect, useState } from 'react';
import { IProps } from './types';
import { Trans, useTranslation } from 'react-i18next';
import { useReactiveVar } from '@apollo/client';
import odometerValidationModalData from '@state/models/odometerValidationModalData';
import SpeedIcon from '@mui/icons-material/Speed';
import CancelIcon from '@mui/icons-material/Cancel';
import odometerValidationModalDataCacheControl from '@state/mutations/odometerValidationModalData';
import {
  Stack,
  Typography,
  Dialog,
  DialogContent,
  Button,
  DialogActions,
  Box,
} from '@mechis/elements';
import { format } from 'date-fns';

const OdometerToDateValidateModal: FC<IProps> = () => {
  const [ lastTimestamp, setLastTimestamp ] = useState<number>(0);
  const [ isOpen, setIsOpen ] = useState(false);
  const [ filledDate, setFilledDate ] = useState<Date | null>(null);
  const [ minOdometer, setMinOdometer ] = useState<number | null>(null);
  const [ maxOdometer, setMaxOdometer ] = useState<number | null>(null);
  const { t } = useTranslation();
  const reactiveOdometerToDateValidateModal = useReactiveVar(odometerValidationModalData);

  useEffect(() => {
    if (reactiveOdometerToDateValidateModal?.timestamp !== lastTimestamp) {
      setIsOpen(true);
      setLastTimestamp(reactiveOdometerToDateValidateModal.timestamp);
      setFilledDate(reactiveOdometerToDateValidateModal.filledDate);
      setMinOdometer(reactiveOdometerToDateValidateModal?.minOdometer ?? null);
      setMaxOdometer(reactiveOdometerToDateValidateModal.maxOdometer ?? null);
    }
  }, [ reactiveOdometerToDateValidateModal ]);

  const resolveDescription = () => {
    if (minOdometer && maxOdometer) {
      return (
        <Trans
          i18nKey="odometerToDateValidationModal.descriptionBoth"
          values={{ minOdometer, maxOdometer, eventDate: format(new Date(filledDate ?? new Date()), 'd.M.yyyy') }}
        />
      );
    }
    
    if (minOdometer) {
      return (
        <Trans
          i18nKey="odometerToDateValidationModal.descriptionMin"
          values={{ minOdometer, eventDate: format(new Date(filledDate ?? new Date()), 'd.M.yyyy') }}
        />
      );
    }

    if (maxOdometer) {
      return (
        <Trans
          i18nKey="odometerToDateValidationModal.descriptionMax"
          values={{ maxOdometer, eventDate: format(new Date(filledDate ?? new Date()), 'd.M.yyyy') }}
        />
      );
    }

    return (
      <Trans
        i18nKey="odometerToDateValidationModal.description"
        values={{ eventDate: format(new Date(filledDate ?? new Date()), 'd.M.yyyy') }}
      />
    );
  };

  if (!!isOpen) {
    return (
      <Dialog
        onClose={() => setIsOpen(false)}
        open={isOpen}
        fullWidth
        PaperProps={{
          sx: {
            backgroundColor: '#f6f6f6',
            textAlign: 'center',
          },
        }}
      >
        <CancelIcon
          onClick={() => setIsOpen(false)}
          sx={{
            position: 'absolute',
            right: '16px',
            top: '16px',
            color: 'lightgrey',
          }}
        />
        <DialogContent>
          <Typography
            variant="h5"
            textAlign="center"
            mb={1}
            mt={1}
          >
            {t('odometerToDateValidationModal.title')}
          </Typography>
          <Stack spacing={3} mt={2} direction="column-reverse">
            <Stack mt={2} spacing={1.5}>
              <Box textAlign="center">
                <SpeedIcon
                  color="error"
                  sx={{
                    fontSize: (theme) => `${theme.spacing(30)}`,
                  }}
                />
              </Box>
              <Stack direction="row" alignItems="center" spacing={1} pl={0.5}>
                <Typography variant="body2" sx={{ lineHeight: 1.5, fontSize: '1.25rem'}}>
                  {resolveDescription()}
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        </DialogContent>
        <DialogActions sx={{ mt: 2 }}>
          <Stack
            sx={{
              width: '100%',
              pr: 2,
              pl: 2,
            }}
            direction="row" 
            justifyContent="space-around"
          >
            <Button
              variant="outlined"
              onClick={() => {
                setIsOpen(false);
              }}
            >
              {t('odometerToDateValidationModal.back')}
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                odometerValidationModalDataCacheControl.ignoreNextValidation();
                setIsOpen(false);
              }}
            >
              {t('odometerToDateValidationModal.understand')}
            </Button>
          </Stack>
        </DialogActions>
      </Dialog>
    );
  } else {
    return <></>;
  }
};

export default OdometerToDateValidateModal;
