import React, { useEffect, useState } from 'react';
import { Box, Paper } from '@mechis/elements';
import { Button, Dialog, Stack, Typography } from '@mechis/elements';
import { useTranslation } from 'react-i18next';
import { IProps } from './types';
import { useCreateCashPaymentByAmountMutation } from '@state/mechis-backend/generated/schema';
import CountUp from 'react-countup';
import { CREDIT_TIERS } from '@configurations/constants/price';
import Coupon from '../Coupon';
import CreditItem from './CreditItem';

const CreditWidget: React.FC<IProps> = ({ creditBalance, noCreditOpen, setNoCreditOpen }) => {
  const [ isDialogOpen, toggleDialogOpen ] = useState<boolean>(false);
  const { t } = useTranslation();
  const [ needCredit, setNeedCredit ] = useState<boolean>(false);

  useEffect(() => {
    if (noCreditOpen) {
      setNeedCredit(true);
      toggleDialogOpen(true);
    }
  }, [ noCreditOpen ]);

  const [ createCashPayment ] = useCreateCashPaymentByAmountMutation({
    onCompleted: (data) => {
      window.location.href = data.createCashPayment?.url ?? '';
    },
  });

  return (
    <Box>
      <Typography variant="sectionHeadline">
        {t('cloud.credit.title')}
      </Typography>
      <Paper sx={{ minHeight: '147px' }}>
        <Stack spacing={3} p={3} textAlign="center">
          <Typography 
            variant="h3"
            sx={{
              color: (theme) => theme.palette.primary.main, 
            }}
          >
            <CountUp
              start={0}
              end={creditBalance}
              duration={1.5}
              separator=" "
            /> <span style={{textDecoration: 'line-through'}}>M</span>
          </Typography>
          <Button
            variant="contained"
            onClick={() => {
              toggleDialogOpen(!isDialogOpen);
            }}
          >
            {t('cloud.credit.add')}
          </Button>
        </Stack>
      </Paper>
      <Dialog
        open={isDialogOpen}
        onClose={() => {
          setNeedCredit(false);
          setNoCreditOpen(false);
        }}
      >
        <Box
          p={1}
          px={2}
          display={'flex'}
          flexDirection={'column'}
          alignItems={'center'}
          justifyContent={'center'}
          gap={1}
        >
          <Typography variant="h1">
            {Boolean(needCredit) ? t('cloud.credit.needCredit') : t('cloud.credit.add')}
          </Typography>
          <Box width={'100%'}>
            <Stack gap={1}>
              {CREDIT_TIERS.map((tier, index) => (
                <CreditItem
                  key={index}
                  {...tier}
                  onClick={async () => {
                    await createCashPayment({
                      variables: {
                        cashPaymentData: {
                          amountPaid: tier.priceCZK,
                        },
                      },
                    });
                  }}
                />
              ))}
            </Stack>
            <Coupon closeDialog={() => toggleDialogOpen(false)} />
          </Box>
          <Box>
            <Button variant="text" onClick={() => {
              setNeedCredit(false);
              setNoCreditOpen(false);
              toggleDialogOpen(false);
            }}>
              {t('cloud.credit.dialog.close')}
            </Button>
          </Box>
        </Box>
      </Dialog>
    </Box>
  );
};

export default CreditWidget;
