import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { GET_NEW_EXPENSE } from '@state/queries/newExpense';
import newExpenseCacheControl from '@state/mutations/newExpense';
import { IExpense } from '@mechis/sections/Selections/TagFilters/types';
import { IPrice } from '@state/models/newExpense/types';

interface IUseExpenseType {
  readonly expenses: Array<IExpense>
  readonly setExpenses: any
  readonly prices: Array<IPrice>
  readonly setPrices: any
  readonly priceTotal: number
  readonly setPriceTotal: any
  readonly resetUseExpenseState: () => void
}

const useExpense = (): IUseExpenseType => {
  const { pathname } = useLocation();
  const { data } = useQuery(GET_NEW_EXPENSE);
  const expenses = data?.newExpense.expenses;
  const prices = data?.newExpense.prices;
  const priceTotal = data?.newExpense.priceTotal;
  const locationFrom = data?.newExpense.locationFrom;

  const { 
    setExpenses,
    setPrices,
    setPriceTotal,
    setLocationFrom,
  } = newExpenseCacheControl;

  const resetUseExpenseState = () => {
    setExpenses([]),
    setPrices([]),
    setPriceTotal(0);
  };
  
  // Toto je neintuitivní řešení, hrozí tady nechtěné side effecty, potřeba vymyslet jinak!
  useEffect(() => {
    if ((pathname !== locationFrom)) {
      const screenFrom = locationFrom.split('/')[3];
      const screen = pathname.split('/')[3];

      if (screenFrom && screen) {
        if ((screenFrom !== screen) && screen !== 'tech-detail') {
          resetUseExpenseState();
        }
      }

      setLocationFrom(pathname);
    }
  }, [ pathname ]);
  
  return {
    expenses,
    setExpenses,
    prices,
    setPrices,
    priceTotal,
    setPriceTotal,
    resetUseExpenseState,
  };
};

export default useExpense;
