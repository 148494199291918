import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import NumberFormat from 'react-number-format';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import {
  CZK_UNIT_NAME,
  THOUSAND_SEPARATOR,
} from '@configurations/constants/app';
import { useTheme } from '@mui/material';
import { Typography, Card, CardContent, Stack, Chip, CardActions, Button, Box } from '@mechis/elements';

interface CreditItemProps {
  mechisCredit: number
  priceCZK: number
  onClick: () => void
}

const CreditItem: React.FC<CreditItemProps> = ({
  mechisCredit,
  priceCZK,
  onClick,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const priceForOne = priceCZK / mechisCredit;
  const isRecommended = priceForOne === 1;

  const handleButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    onClick();
  };

  return (
    <Card
      sx={{
        border: isRecommended ? `1px solid ${theme.palette.secondary.main}` : '1px solid #E0E0E0',
        boxShadow: 'none',
      }}
      onClick={onClick}
    >
      <CardContent sx={{ padding: 1, paddingBottom: 0 }}>
        <Stack
          direction="row"
          spacing={0.5}
          justifyContent={'space-between'}
          alignItems={'center'}
        >
          <Typography variant="h4">
            <Trans
              i18nKey="cloud.credit.dialog.credit"
              values={{ amount: mechisCredit }}
              components={{
                m: <span style={{ textDecoration: 'line-through' }} />,
              }}
            />
          </Typography>
          {isRecommended ? (
            <Chip
              icon={<AutoAwesomeIcon />}
              label={t('cloud.credit.dialog.recommended')}
              size="small"
            />
          ) : null}
        </Stack>
      </CardContent>
      <CardActions>
        <Box
          display="flex"
          justifyContent="space-between"
          width="100%"
          alignItems="center"
        >
          <Stack direction="row" spacing={0.5}>
            <Typography variant="subtitle1" color="text.secondary">
              <Trans
                i18nKey="cloud.credit.dialog.priceForOne"
                components={{
                  m: <span style={{ textDecoration: 'line-through' }} />,
                }}
              />
            </Typography>
            <Typography variant="subtitle1" color="text.secondary">
              <NumberFormat
                value={priceForOne}
                displayType="text"
                thousandSeparator={THOUSAND_SEPARATOR}
                decimalScale={2}
                suffix={` ${CZK_UNIT_NAME}`}
              />
            </Typography>
          </Stack>
          <Button size="small" variant="contained" onClick={handleButtonClick}>
            {t('cloud.credit.dialog.buy', { price: priceCZK })}
          </Button>
        </Box>
      </CardActions>
    </Card>
  );
};

export default CreditItem;
