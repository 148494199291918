import React, { useEffect, useState } from 'react';
import { ControlState, IOwnerState } from '../types';
import { IProps } from './types';
import {
  Control,
  ControlLabel,
} from '../styles';
import {
  IconButton,
  TextField,
} from '@mechis/elements';
import { getColorByState } from '../Selections';
import TodayIcon from '@mui/icons-material/Today';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import {
  EventByIdDocument,
  MaintenanceIntervalsByDeviceDocument,
  useUpdateEventDateMutation,
} from '@state/mechis-backend/generated/schema';
import snackbarCacheControl from '@state/mutations/snackbar';
import useTech from '@hooks/useTech';
import odometerValidationModalData from '@state/models/odometerValidationModalData';
import odometerValidationModalDataCacheControl from '@state/mutations/odometerValidationModalData';
import { useReactiveVar } from '@apollo/client';

const MutableDate: React.FC<IProps> = ({ eventDateAndOdometer, eventId }) => {
  const [ dateState, setDateState ] = useState<ControlState>('default');
  const [ dateValue, setDateValue ] = useState<number>(0);
  const { techId } = useTech();
  const { setSnackbar } = snackbarCacheControl;
  const reactiveOdometerToDateValidateModal = useReactiveVar(odometerValidationModalData);

  useEffect(() => {
    if (eventDateAndOdometer !== undefined) {
      setDateValue(eventDateAndOdometer.event?.eventDate);
      setDateState('filled');
    }
  }, [ eventDateAndOdometer ]);

  const ownerState: IOwnerState = {
    state: dateState,
  };

  const [ updateEventDateMutation ] = useUpdateEventDateMutation({
    onCompleted: () => {
      setSnackbar('success', 'EVENT_UPDATE_SUCCESSFUL');
      setDateState('filled');
      odometerValidationModalDataCacheControl.resetIgnoreNextValidation();
    },
    onError: () => setSnackbar('error','EVENT_UPDATE_ERROR'),
    refetchQueries: [
      {
        query: EventByIdDocument,
        variables: {
          eventId: Number(eventId),
        },
      },
      {
        query: MaintenanceIntervalsByDeviceDocument,
        variables: {
          deviceId: techId,
        },
      },
    ],
  });

  const updateEventDate = async (newValue?: any) => {
    await updateEventDateMutation({
      variables: {
        event: {
          id: Number(eventId),
          eventDate: newValue ? newValue : dateValue,
        },
        ignoreValidation: reactiveOdometerToDateValidateModal?.ignoreNextValidation,
      },
    });
  };

  const handleChange = async (newValue: any) => {
    setDateValue(newValue);
  };

  const persistToSelectionsCache = async (newValue: any) => {
    await updateEventDate(newValue);
  };

  const handleClick = () => {
    const element = document.getElementById('selection-datepicker');
    element?.click();
  };

  return (
    <Control>
      <IconButton
        size="small"
        color={getColorByState(dateState)}
        isSquared
        onClick={() => handleClick()}
      >
        <TodayIcon />
      </IconButton>
      <ControlLabel ownerState={ownerState}>
        <MobileDatePicker
          showToolbar={false}
          inputFormat="d/M/yy"
          disableFuture
          value={dateValue}
          onChange={(newValue: any) => handleChange(newValue)}
          onAccept={persistToSelectionsCache}
          renderInput={(params) => <TextField id="selection-datepicker" {...params} />}
        />
      </ControlLabel>
    </Control>
  );
};

export default MutableDate;
