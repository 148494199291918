import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import csTranslation from '@configurations/translations/cs.json';
import enTranslation from '@configurations/translations/en.json';
import { CZ_TAG_TRANSLATION_URL, EN_TAG_TRANSLATION_URL } from '@configurations/constants/app';

export const initTranslations = () => {
  const resources = {
    cs: { translation: csTranslation },
    en: { translation: enTranslation },
  };

  fetch(CZ_TAG_TRANSLATION_URL)
    .then((res) => res.json())
    .then((data) => {
      if (data.cs) {
        resources.cs.translation.tagManager.id = data.cs;
      } else {
        console.error('CS Translations not found in response:', data);
      }
    })
    .catch((error) => {
      console.error('Getting CS tags translations:', error);
    });

  fetch(EN_TAG_TRANSLATION_URL)
    .then((res) => res.json())
    .then((data) => {
      if (data.en) {
        resources.en.translation.tagManager.id = data.en;
      } else {
        console.error('En Translations not found in response:', data);
      }
    })
    .catch((error) => {
      console.error('Getting EN tags translations:', error);
    });

  i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
      resources,
      lng: 'cs',
      interpolation: {
        escapeValue: false,
      },
    });
};

export default i18n;
