import React, { FC, useEffect } from 'react';
import { IProps } from './types';
import {
  Block,
  Value,
  PaperBlock,
  InnerPaperBlock,
  PaperBlockHeader,
  PaperBlockHeaderName,
} from './styles';
import NumberFormat from 'react-number-format';
import { useNavigate } from 'react-router-dom';
import {
  Typography,
  Box,
  IconButton,
  Stack,
} from '@mechis/elements';
import {
  EVENT_TYPE_EXPENSE,
  EVENT_TYPE_MAINTENANCE,
  EVENT_TYPE_ODOMETER,
  EVENT_TYPE_REFUELING,
} from '@configurations/constants/eventTypes';
import { THOUSAND_SEPARATOR } from '@configurations/constants/app';
import { OutputDateFormats } from '@configurations/types/global';
import { tableOutputDate } from '@utilities/app';
import useEvent from '@hooks/useEvent';
import { isNumber } from 'lodash';
import useTech, { UnitType } from '@hooks/useTech';
import { colors } from '@mui/material';

const Record: FC<IProps> = ({
  eventId,
  title,
  kilometersDifference,
  date,
  // eslint-disable-next-line
  kilometers,
  type,
  priceTotal,
  actualConsumption,
  isEven = true,
  fuelType,
}) => {
  // todo: fix navigation, when RECORD DETAIL PAGE is ready with new layout structure?
  const navigate = useNavigate();
  const [ isInvalidOdometer, setIsInvalidOdometer ] = React.useState<boolean>(false);
  const { techId, getTechUnit, getConsumptionUnitByFuelName } = useTech();
  const { eventIcon } = useEvent(11); //TODO: Marek Koláč please rework to be independed to useEventId when no data from call is used

  useEffect(() => {
    if (kilometersDifference < 0) {
      setIsInvalidOdometer(true);
    }
  }, [ kilometersDifference ]);

  const getTotalPrice = (eventType: number, price: number | undefined): number => {
    if (price) {
      switch (eventType) {
        case EVENT_TYPE_REFUELING:
        case EVENT_TYPE_EXPENSE:
        case EVENT_TYPE_MAINTENANCE:
          return parseFloat(Number(price).toFixed(0));
        default:
          return 0;
      }
    }

    return 0;
  };

  const getColor = () => {
    switch (type) {
      case EVENT_TYPE_REFUELING:
        return colors['indigo'];
      case EVENT_TYPE_EXPENSE:
        return colors['red'];
      case EVENT_TYPE_MAINTENANCE:
        return colors['amber'];
      case EVENT_TYPE_ODOMETER:
        return colors['lime'];
      default:
        return colors['amber'];
    }
  };

  const getConsumptionUnit = () => {
    if (!fuelType) return '';
    
    return getConsumptionUnitByFuelName(fuelType, true);
  };

  return (
    <Block onClick={() => navigate(`/app/${techId}/tech-detail/record/${eventId}`)}>
      <Box mr={2}>
        <IconButton
          // color={isEven ? 'primary' : 'secondary'}
          size="small"
          isSquared
          sx={{
            width: 40,
            height: 40,
            // background: colors['amber'][200],
            background: `linear-gradient(135deg, ${getColor()['200']}, ${getColor()['100']})`,
          }}
        >
          {eventIcon(type)}
        </IconButton>
      </Box>
      <PaperBlock>
        <InnerPaperBlock px={2} py={2}>
          <PaperBlockHeader>
            <PaperBlockHeaderName>
              <Typography
                variant="h6"
                color={isEven ? 'primary' : 'secondary'}
              >
                {title}
              </Typography>
              <Stack direction="row" spacing={1} alignItems="center">
                <NumberFormat
                  className="kilometers-sign"
                  value={kilometers}
                  displayType="text"
                  thousandSeparator={THOUSAND_SEPARATOR}
                  suffix={getTechUnit(UnitType.Odometer, true)}
                />
                {isNumber(actualConsumption) && (
                  <Value variant="h6">
                    <span style={{ fontSize: '0.75em' }}>
                      {actualConsumption > 0 ? (
                        <NumberFormat
                          value={actualConsumption}
                          displayType="text"
                          decimalScale={2}
                          thousandSeparator={THOUSAND_SEPARATOR}
                          suffix={getConsumptionUnit()}
                        />
                      ) : (
                        `N/A${getConsumptionUnit()}`
                      )}
                    </span>
                  </Value>
                )}
                {isInvalidOdometer && (
                  <Typography sx={{ fontSize: '0.75em', color: (theme) => theme.palette.error.main }}>
                  Nevalidní stav tachometru
                  </Typography>
                )}
              </Stack>
            </PaperBlockHeaderName>
            <Value variant="h6">
              <Typography
                variant="subtitle1"
                component="span"
                sx={{
                  lineHeight: 1,
                }}
              >
                {tableOutputDate(date, OutputDateFormats.Classic)}
              </Typography>
 
              {type === EVENT_TYPE_ODOMETER ? (
                <span>
                  <NumberFormat
                    prefix="+ "
                    suffix={getTechUnit(UnitType.Odometer, true)}
                    value={kilometersDifference}
                    displayType="text"
                    thousandSeparator={THOUSAND_SEPARATOR}
                  />
                </span>
              ) : (
                <span className="negative">
                  <NumberFormat
                    prefix="- "
                    suffix=" kč"
                    value={getTotalPrice(type, priceTotal)}
                    displayType="text"
                    thousandSeparator={THOUSAND_SEPARATOR}
                  />
                </span>
              )}
            </Value>
          </PaperBlockHeader>
        </InnerPaperBlock>
      </PaperBlock>
    </Block>
  );
};

export default Record;
