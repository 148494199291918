import React, { FC, useEffect } from 'react';
import { Box, Chip, FormControlLabel, InputAdornment, Paper, Stack, Switch, Typography } from '@mechis/elements';
import {
  FuelDevice,
  useRefuelingByEventIdQuery,
} from '@state/mechis-backend/generated/schema';
import Loader from '@mechis/blocks/Loader';
import Button from '@mechis/elements/Button';
import { useTranslation } from 'react-i18next';
import useIcon from '@mechis/icons/useIcon';
import { CommonIcons } from '@mechis/icons/types';
import { Controller } from 'react-hook-form';
import { blurTarget, clearZero } from '@utilities/app';
import useRefueling from '@hooks/useRefueling';
import { IRefuelingEditProps } from '../types';
import NumberField from '@mechis/elements/NumberField';

const Edit: FC<IRefuelingEditProps> = ({ eventId, onBack }) => {
  const { t } = useTranslation();
  const {
    fuels,
    onFuelChange,
    control,
    handleSubmit,
    // watch,
    setValue,
    onEditRefueling,
  } = useRefueling(eventId);

  const { data, loading, error } = useRefuelingByEventIdQuery({
    variables: {
      eventId: Number(eventId),
    },
  });

  const fuel = data?.refuelingByEvent[0];
  const currentFuel = fuels.find((f: FuelDevice) => f?.fuelType?.id === fuel?.fuelType?.id);

  useEffect(() => {
    if (fuel) {
      setValue('skipTank', fuel.skipTank);
      setValue('pricePerUnit', fuel.pricePerUnit);
      setValue('fuelVolume', fuel.unitNumber);
      setValue('priceTotal', fuel.event.priceTotal);
      setValue('fullTank', fuel.fullTank);
      setValue('fuelId', parseInt(fuel.id));
      setValue('fuelTypeId', fuel?.fuelType?.id || '0');
    }
  }, [ fuel, setValue ]);

  // const priceTotalWatch = watch('priceTotal');
  // const fuelVolumeWatch = watch('fuelVolume');
  // const pricePerUnitWatch = watch('pricePerUnit');

  // const blurRecalculation = () => {
  //   if (priceTotalWatch && fuelVolumeWatch && (!pricePerUnitWatch || pricePerUnitWatch === 0)) {
  //     const pricePerUnitTmp = priceTotalWatch / fuelVolumeWatch;
  //     setValue('pricePerUnit', pricePerUnitTmp);
  //   }

  //   if (priceTotalWatch && pricePerUnitWatch && (!fuelVolumeWatch || fuelVolumeWatch === 0)) {
  //     const fuelVolumeTmp = priceTotalWatch / pricePerUnitWatch;
  //     setValue('fuelVolume', fuelVolumeTmp);
  //   }

  //   if (fuelVolumeWatch && pricePerUnitWatch && (!priceTotalWatch || priceTotalWatch === 0)) {
  //     const priceTotalTmp = fuelVolumeWatch * pricePerUnitWatch;
  //     setValue('priceTotal', priceTotalTmp);
  //   }
  // };

  const onSave = () => {
    handleSubmit((editState) => onEditRefueling(editState))();
    onBack();
  };

  if (loading) {
    return <Loader />;
  }

  if (error) {
    return null;
  }

  return (
    <Box my={2}>
      <Paper>
        <Box p={2} pt={2.5}>
          <Stack
            direction="row"
            justifyContent="center"
            sx={{
              pb: 2,
              borderBottom: '1px dashed lightgrey',
              flexWrap: 'wrap',
            }}
          >
            <Chip
              key={fuel?.fuelType?.id}
              icon={useIcon(`fuel-${fuel?.fuelType?.fuelName}` as CommonIcons)}
              label={t(`fuelTypes.${fuel?.fuelType?.fuelName}`)}
              variant="filled"
              color="primary"
              size="small"
              onClick={() => onFuelChange(fuel?.fuelType?.id as string)}
              sx={{
                m: 0.5,
                '& svg': {
                  marginLeft: '8px !important',
                },
              }}
            />
          </Stack>
        </Box>
        <Box px={4} pt={1} pb={4}>
          <Stack
            direction="column"
            justifyContent="space-between"
            spacing={2}
          >
            <Controller
              render={({ field, formState }) => (
                <NumberField
                  {...field}
                  value={clearZero(field.value)}
                  // onBlur={blurRecalculation}
                  onWheel={blurTarget}
                  id="priceTotal"
                  error={!!formState.errors?.priceTotal?.message}
                  textAlign="right"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Typography
                          sx={{
                            fontFamily: 'Poppins',
                            fontSize: '0.8em',
                            fontWeight: 'bold',
                            color: (theme) => theme.palette.primary.main,
                          }}
                        >
                          {t('newRefueling.form.priceTotal.name')}
                        </Typography>
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end" sx={{ position: 'relative', top: '1px' }}>
                        <small>Kč</small>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
              name="priceTotal"
              control={control}
            />
            <Controller
              render={({ field, formState }) => (
                <NumberField
                  {...field}
                  value={clearZero(field.value)}
                  // onBlur={blurRecalculation}
                  onWheel={blurTarget}
                  id="fuelVolume"
                  error={!!formState.errors?.fuelVolume?.message}
                  textAlign="right"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Typography
                          sx={{
                            fontFamily: 'Poppins',
                            fontSize: '0.8em',
                            fontWeight: 'bold',
                            color: (theme) => theme.palette.primary.main,
                          }}
                        >
                          {t('newRefueling.form.fuelVolume.name')}
                        </Typography>
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end" sx={{ position: 'relative', top: '1px' }}>
                        <small> {currentFuel?.volumeUnit?.unitName}</small>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
              name="fuelVolume"
              control={control}
            />
            <Controller
              render={({ field, formState }) => (
                <NumberField
                  {...field}
                  value={clearZero(field.value)}
                  // onBlur={blurRecalculation}
                  onWheel={blurTarget}
                  id="pricePerUnit"
                  error={!!formState.errors?.priceTotal?.message}
                  textAlign="right"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Typography
                          sx={{
                            fontFamily: 'Poppins',
                            fontSize: '0.8em',
                            fontWeight: 'bold',
                            color: (theme) => theme.palette.primary.main,
                          }}
                        >
                          {t('newRefueling.form.pricePerUnit.name')} {currentFuel?.volumeUnit?.unitName}
                        </Typography>
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end" sx={{ position: 'relative', top: '1px' }}>
                        <small>Kč</small>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
              name="pricePerUnit"
              control={control}
            />
            <Stack direction="row" justifyContent="space-between" spacing={2}>
              <Controller
                render={({ field }) => (
                  <Stack direction="row" alignItems="center" justifyContent="space-between">
                    <FormControlLabel
                      sx={{
                        flexDirection: 'column',
                        margin: '0 auto',
                        border: (theme) => `1px dashed ${field.value ? theme.palette.secondary.main : 'lightgrey'}`,
                        borderRadius: '12px',
                        padding: '12px',
                        maxWidth: '100px',
                        textAlign: 'center',
                      }}
                      control={
                        <Switch
                          {...field}
                          checked={field.value}
                          size="small"
                          id="fullTank"
                          color="secondary"
                          inputProps={{ 'aria-label': 'controlled' }}
                        />
                      }
                      label={
                        <Typography
                          sx={{
                            fontSize: '0.65em',
                            fontWeight: field.value ? 'bold' : 'initial',
                            color: (theme) => field.value ? theme.palette.secondary.main : undefined,
                          }}
                        >
                          {t('newRefueling.form.fullTank.name')}
                        </Typography>
                      }
                    />
                  </Stack>
                )}
                name="fullTank"
                control={control}
              />
              <Controller
                render={({ field }) => (
                  <Stack direction="row" alignItems="center" justifyContent="space-between">
                    <FormControlLabel
                      sx={{
                        flexDirection: 'column',
                        margin: '0 auto',
                        border: (theme) => `1px dashed ${field.value ? theme.palette.secondary.main : 'lightgrey'}`,
                        borderRadius: '12px',
                        padding: '12px',
                        maxWidth: '100px',
                        textAlign: 'center',
                      }}
                      control={
                        <Switch
                          {...field}
                          id="skipTank"
                          size="small"
                          color="secondary"
                          checked={field.value}
                          inputProps={{ 'aria-label': 'controlled' }}
                        />
                      }
                      label={
                        <Typography
                          sx={{
                            fontSize: '0.65em',
                            fontWeight: field.value ? 'bold' : 'initial',
                            color: (theme) => field.value ? theme.palette.secondary.main : undefined,
                          }}
                        >
                          {t('newRefueling.form.skipTank.name')}
                        </Typography>
                      }
                    />
                  </Stack>
                )}
                name="skipTank"
                control={control}
              />
            </Stack>
          </Stack>
        </Box>
      </Paper>
      <Stack justifyContent="center" mt={2} direction={'row'} spacing={2}>
        <Button variant="text" color="secondary" onClick={onBack}>
          {t('techDetail.updateEvent.refueling.back')}
        </Button>
        <Button variant="contained" color="secondary" onClick={onSave}>
          {t('techDetail.updateEvent.refueling.update')}
        </Button>
      </Stack>
    </Box>
  );
};

export default Edit;
