import React, { FC } from 'react';
import { IconButton, Box } from '@mechis/elements';
import { getIconByCategory } from '@hooks/useTagManager';
import { useTranslation } from 'react-i18next';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';

interface ITagProps {
  tagId: string
  tagCategoryName: string
  isSelected: boolean
  onClick: () => void
}

const Tag: FC<ITagProps> = ({ tagId, tagCategoryName, isSelected, onClick }) => {
  const { t } = useTranslation();

  return (
    <li
      style={{
        alignItems: 'center',
        justifyContent: 'space-between',
        listStyleType: 'none',
        display: 'flex',
        borderBottom: '1px solid rgb(234, 236, 239)',
        fontSize: '0.8125rem',
        paddingBottom: '8px',
        opacity: isSelected ? 1 : 0.6,
      }}
      onClick={onClick}
    >
      <Box display="flex" alignItems="center">
        <IconButton
          color={isSelected ? 'secondary' : 'background'}
          size="small"
          isSquared
        >
          {getIconByCategory(tagCategoryName)}
        </IconButton>
        <Box ml={2} sx={{ fontWeight: isSelected ? 600 : 100 }}>
          {t(`tagManager.id.${tagId}`)}
        </Box>
      </Box>
      <IconButton
        color={isSelected ? 'error' : 'secondary'}
        isSquared
        sx={{ 
          borderRadius: '4px',
          padding: '4px',
          opacity: 0.8,
        }}
      >
        {isSelected ? 
          <RemoveCircleOutlineIcon 
            sx={{ fontSize: '14px' }} 
          /> :
          <AddCircleOutlineIcon 
            sx={{ fontSize: '14px' }}
          />
        }
      </IconButton>
    </li>
  );
};

export default Tag;

