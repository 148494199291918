import React, { FC, useEffect, useState } from 'react';
import useHeader from '@hooks/useHeader';
import Selections from '@mechis/sections/Selections';
import ControlMenu from '../ControlMenu';
import { Grid, Box, NativeSelect, Button, Stack, Paper } from '@mechis/elements';
import { useTranslation } from 'react-i18next';
import {
  DeviceBasicInformationDocument,
  DevicesDocument,
  DeviceUnitsByDeviceIdDocument,
  MaintenanceIntervalsByDeviceDocument,
  useDeviceUnitsByDeviceIdQuery,
  useUpdateDeviceUnitsMutation,
} from '@state/mechis-backend/generated/schema';
import snackbarCacheControl from '@state/mutations/snackbar';
import { useNavigate } from 'react-router-dom';
import useTech from '@hooks/useTech';
import useUnits from '@hooks/useUnits';

const ChangeUnits: FC = () => {
  const { t } = useTranslation();
  const { techId } = useTech();
  const navigate = useNavigate();
  const [ odometerUnitId, setOdometerUnitId ] = useState<number | undefined>();
  const [ powerUnitId, setPowerUnitId ] = useState<number | undefined>();
  const { setSnackbar } = snackbarCacheControl;
  const {
    powerUnitsData,
    powerUnitsLoading,
    odometerUnitsData,
    odometerUnitsLoading,
  } = useUnits();

  const [ updateUnits ] = useUpdateDeviceUnitsMutation({
    refetchQueries: [
      {
        query: DeviceUnitsByDeviceIdDocument,
        variables: {
          deviceId: techId,
        },
      },
      {
        query: DeviceBasicInformationDocument,
        variables: {
          deviceId: techId,
        },
      },
      {
        query: DevicesDocument,
        variables: {
          deviceId: techId,
        },
      },
      {
        query: MaintenanceIntervalsByDeviceDocument,
        variables: {
          deviceId: techId,
        },
      },
    ],
  });
  const { data, loading } = useDeviceUnitsByDeviceIdQuery({
    variables: {
      deviceId: techId,
    },
    skip: !techId,
  });

  useEffect(() => {
    if (data?.device) {
      setOdometerUnitId(Number(data.device.odometerUnit?.id) || 1);
      setPowerUnitId(Number(data.device.powerUnit?.id) || 1);
    }
  }, [ data ]);

  const submit = async () => {
    try {
      await updateUnits({
        variables: {
          device: {
            id: techId,
            odometerUnit: {
              id: Number(odometerUnitId),
            },
            powerUnit: {
              id: Number(powerUnitId),
            },
          },
        },
      });
      setSnackbar('success', 'UPDATE_UNITS_SUCCESSFUL');
      navigate(`/app/${techId}/tech-detail/settings`);
    } catch (error) {
      setSnackbar('error', 'UPDATE_UNITS_ERROR');
    }
  };

  useHeader({
    headline: 'appBar.headline.changeCurrency',
    widget: (
      <Selections>
        <ControlMenu />
      </Selections>
    ),
  });

  if (!odometerUnitId || !powerUnitId || loading || powerUnitsLoading || odometerUnitsLoading) {
    return <></>;
  }

  return (
    <Box mt={4}>
      <Box p={0}>
        <Paper>
          <Grid container spacing={4} p={3} pt={0} pb={4}>
            <Grid item xs={12}>
              <NativeSelect
                label={t('changeUnits.odometerUnit.label')}
                id="odometerUnit"
                fullWidth
                value={odometerUnitId}
                onChange={(e) => setOdometerUnitId(Number(e.target.value))}
                isLabelWhite
              >
                {odometerUnitsData?.odometerUnits.map((unit) => (
                  <option color="black" key={Number(unit.id)} value={Number(unit.id)}>
                    {t(`changeUnits.odometerUnit.${unit.unitName}`)}
                  </option>
                ))}
              </NativeSelect>
            </Grid>
            <Grid item xs={12}>
              <NativeSelect
                label={t('changeUnits.powerUnit.label')}
                id="powerUnit"
                fullWidth
                value={powerUnitId}
                onChange={(e) => setPowerUnitId(Number(e.target.value))}
                isLabelWhite
              >
                {powerUnitsData?.powerUnits.map((unit) => (
                  <option color="black" key={Number(unit.id)} value={Number(unit.id)}>
                    {t(`changeUnits.powerUnit.${unit.unitName}`)}
                  </option>
                ))}
              </NativeSelect>
            </Grid>
          </Grid>
        </Paper>
        <Stack alignItems="center" justifyContent="center" spacing={1.5} mt={3}>
          <Button
            variant="contained"
            color="secondary"
            onClick={submit}
          >
            {t('changeUnits.save')}
          </Button>
        </Stack>
      </Box>
    </Box>
  );
};

export default ChangeUnits;
