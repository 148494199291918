import React, { FC, useState } from 'react';
import { IProps, ControlState } from './types';
import {
  Section,
  Header,
  Content,
  Tech,
  TechHeadline,
  Controls,
} from './styles';
import {
  IconButton,
  Skeleton,
} from '@mechis/elements';
import Vrp from '@mechis/blocks/Vrp';
import ErrorIcon from '@mui/icons-material/Error';
import SwipeUpIcon from '@mui/icons-material/SwipeUp';
import { useDeviceHeaderDetailQuery } from '@state/mechis-backend/generated/schema';

// selections controls + modals
import Date from './Date';
import Odometer from './Odometer';
import Attachments from './Attachments';
import TagFilters from './TagFilters';
import GeneralFilters from './GeneralFilters';
import DeviceAvatar from '@mechis/blocks/DeviceAvatar';
import { ModalDeviceSwitcher } from './DeviceSwitcher/ModalDeviceSwitcher';
import useTech from '@hooks/useTech';

export const getColorByState = (state: ControlState): 'primary' | 'error' | 'background' => {
  switch (state) {
    case 'filled':
      return 'primary';
    case 'missing':
      return 'error';
    case 'default':
    default: return 'background';
  }
};

const Selections: FC<IProps> = ({ 
  date,
  odometer,
  attachments,
  tagFilters,
  generalFilters,
  children,
}) => {
  const { techId } = useTech();

  const { data: headerDetail, loading: headerDetailLoading, error: headerDeviceError } = useDeviceHeaderDetailQuery({
    variables: {
      deviceId: techId,
    },
    skip: !techId,
    fetchPolicy: 'cache-first',
  });

  const [ isDeviceSwitcherOpen, setIsDeviceSwitcherOpen ] = useState<boolean>(false);

  if (headerDeviceError) {
    return (
      <ErrorIcon />
    );
  }

  return (
    <Section>
      <Header>
        {headerDetailLoading ? (
          <Skeleton variant="text" sx={{ minWidth: '81px', height: '23px', borderRadius: '8px' }} />
        ) : (
          <Vrp
            variant="default"
            vrp={headerDetail?.device?.spz}
            serialNumber={headerDetail?.device?.serialNumber}
          />
        )}
        {headerDetailLoading ? (
          <Skeleton 
            variant="text" 
            sx={{
              width: '81px',
              height: '23px',
              borderRadius: '8px',
              marginRight: '120px',
            }} 
          />
        ) : (
          <TechHeadline variant="body2">{headerDetail?.device?.name}</TechHeadline>
        )}
        
      </Header>
      <Content>
        {headerDetailLoading ? (
          <Skeleton 
            sx={{
              width: '75px',
              height: '75px',
              borderRadius: '16px',
            }}
            variant="rounded"
          />
        ) : (
          <Tech onClick={() => setIsDeviceSwitcherOpen(true)}>
            <IconButton
              color="primary"
              size="small"
              isSquared
            >
              <SwipeUpIcon fontSize="small" />
            </IconButton>
            <DeviceAvatar
              imageId={headerDetail?.device?.thumbnailImage?.storageName}
              deviceCategoryId={Number(headerDetail?.device?.deviceCategory?.id) ?? 1}
              size={75}
              isEven={false}
            />
          </Tech>
        )}

        <Controls>
          {date?.isSelection && <Date />}
          {odometer?.isSelection && <Odometer />}
          {attachments?.isSelection && <Attachments />}
          {tagFilters?.isSelection && <TagFilters />}
          {generalFilters?.isSelection && <GeneralFilters 
            isDate={generalFilters?.isDate}
            isOdometer={generalFilters?.isOdometer}
            isPrice={generalFilters?.isPrice}
            isRecordType={generalFilters?.isRecordType}
          />}
          {children}
        </Controls>
      </Content>
      <ModalDeviceSwitcher
        isOpen={isDeviceSwitcherOpen}
        onClose={() => setIsDeviceSwitcherOpen(false)}
        data={headerDetail}
      />
    </Section>
  );
};

export default Selections;
