import React, { FC, useState } from 'react';
import { IProps } from './types';
import { Box, Paper, Stack, TextField, Typography } from '@mechis/elements';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { CreditPreviewDocument, useApplyCouponMutation } from '@state/mechis-backend/generated/schema';
import snackbarCacheControl from '@state/mutations/snackbar';

const Coupon: FC<IProps> = ({ closeDialog }) => {
  const { t } = useTranslation();
  const [ code, setCode ] = useState<string>('');
  const { setSnackbar } = snackbarCacheControl;

  const [ applyCoupon ] = useApplyCouponMutation({
    onCompleted: () => {
      setSnackbar('success', 'COUPON_APPLIED');
      closeDialog();
    },
    onError: () => setCode(''),
    refetchQueries: [
      {
        query: CreditPreviewDocument,
      },
    ],
  });

  const submitCoupon = async () => {
    await applyCoupon({
      variables: {
        code,
      },
    });
  };

  return (
    <Paper
      sx={{
        mt: 2,
        p: 2,
        background: 'transparent',
        border: '2px dashed lightgrey',
        boxShadow: 'none',
      }}
    >
      <Stack direction="row" spacing={2} alignItems="center" justifyContent="space-between">
        <Stack direction="row" spacing={2} alignItems="center">
          <Box
            sx={{
              borderRadius: '12px',
              background: '#01ab6d14',
              width: '80%',
              height: '50px',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <CardGiftcardIcon color="secondary" fontSize="large" />
          </Box>
          <Box>
            <Typography variant="h5" color="primary">{t('coupon.title')}</Typography>
          </Box>
        </Stack>
        <Box>
          <Button
            variant="contained"
            color="secondary"
            size="small"
            disabled={code.length === 0}
            onClick={submitCoupon}
          >
            {t('coupon.apply')}
          </Button>
        </Box>
      </Stack>
      <Stack mt={2} spacing={1.5}>
        <Stack direction="row" alignItems="center" spacing={1} pl={0.5}>
          <TextField
            variant="outlined"
            value={code}
            onChange={(e) => setCode(e.target.value)}
            label={t('coupon.label')}
          />
        </Stack>
      </Stack>
    </Paper>
  );
};

export default Coupon;
