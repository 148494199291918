import React, { useEffect, useState } from 'react';
import {
  Typography,
  Paper,
  Box,
  Button,
  Stack,
  TextField,
  FormControlLabel,
  Switch,
  Chip,
  InputAdornment,
} from '@mechis/elements';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Controller } from 'react-hook-form';
import {
  blurTarget,
  clearZero,
} from '@utilities/app';
import useHeader from '@hooks/useHeader';
import Selections from '@mechis/sections/Selections';
import useIcon from '@mechis/icons/useIcon';
import { CommonIcons } from '@mechis/icons/types';
import { useParams } from 'react-router-dom';
import useRefueling from '@hooks/useRefueling';
import { FuelDevice } from '@state/mechis-backend/generated/schema';
import { cutToTwoDecimalPlaces } from '@utilities/cutToTwoDecimalPlaces';
import NoFuel from './NoFuel';
import NumberField from '@mechis/elements/NumberField';

const NewRefueling = () => {
  const [ lastEmpty, setLastEmpty ] = useState<'pricePerUnit' | 'fuelVolume' | 'priceTotal' | 'final' | null>(null);
  const { 
    fuelTypeId,
    onFuelChange,
    fuels,
    loading,
    error,
    control,
    setDescription,
    description,
    handleSubmit,
    watch,
    isValid,
    setValue,
    onCreateRefueling,
    isUploading,
    odometerState,
  } = useRefueling();
  
  useHeader({
    headline: 'appBar.headline.refueling',
    widget: (
      <Selections
        date={{ isSelection: true, order: 1 }}
        odometer={{ isSelection: true, order: 2 }}
        attachments={{ isSelection: true, order: 3 }}
      />
    ),
  });
  
  const { t } = useTranslation();
  const { step } = useParams();
  const navigate = useNavigate();
  const priceTotalWatch = watch('priceTotal');
  const fuelVolumeWatch = watch('fuelVolume');
  const pricePerUnitWatch = watch('pricePerUnit');

  const currentFuel = fuels.find((fuel: FuelDevice) => fuel?.fuelType?.id === fuelTypeId);

  useEffect(() => {
    if (lastEmpty === 'final') {
      return;
    }

    if (
      priceTotalWatch &&
      fuelVolumeWatch &&
      (
        typeof pricePerUnitWatch === 'undefined' ||
        (
          pricePerUnitWatch === 0 &&
          lastEmpty !== 'pricePerUnit'
        ) ||
        lastEmpty === 'pricePerUnit'
      )
    ) {
      const pricePerUnitTmp = priceTotalWatch / fuelVolumeWatch;
      setLastEmpty('pricePerUnit');
      setValue('pricePerUnit', cutToTwoDecimalPlaces(pricePerUnitTmp));
    }

    if (
      priceTotalWatch &&
      pricePerUnitWatch &&
      (
        typeof fuelVolumeWatch === 'undefined' ||
        (
          fuelVolumeWatch === 0 &&
          lastEmpty !== 'fuelVolume'
        ) ||
        lastEmpty === 'fuelVolume'
      )
    ) {
      const fuelVolumeTmp = priceTotalWatch / pricePerUnitWatch;
      setLastEmpty('fuelVolume');
      setValue('fuelVolume', cutToTwoDecimalPlaces(fuelVolumeTmp));
    }

    if (
      fuelVolumeWatch &&
      pricePerUnitWatch &&
      (
        typeof priceTotalWatch === 'undefined' ||
        (
          priceTotalWatch === 0 &&
          lastEmpty !== 'priceTotal'
        ) ||
        lastEmpty === 'priceTotal'
      )
    ) {
      const priceTotalTmp = fuelVolumeWatch * pricePerUnitWatch;
      setLastEmpty('priceTotal');
      setValue('priceTotal', cutToTwoDecimalPlaces(priceTotalTmp));
    }
  }, [ priceTotalWatch, fuelVolumeWatch, pricePerUnitWatch ]);

  const unsetLastEmpty = () => {
    if (priceTotalWatch !== 0 && fuelVolumeWatch !== 0 && pricePerUnitWatch !== 0) {
      setLastEmpty('final');
    }
  };

  const onContinue = () => {
    if (isValid()) {
      navigate('note');
    }
  };

  if (step && step === 'note') {
    return (
      <>
        <Paper>
          <Box py={3} px={2.5}>
            <TextField
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              label={t('addExpense.description')}
              multiline
              rows={8}
              variant="outlined"
              type="text"
              id="newExpenseNote"
              InputProps={{
                startAdornment: <></>,
              }}
            />
          </Box>
        </Paper>
        <Button
          variant="contained"
          color="secondary"
          isLoading={isUploading}
          onClick={() => {
            handleSubmit(onCreateRefueling)();
          }}
          sx={{
            maxWidth: '160px',
            display: 'block',
            marginLeft: 'auto',
            marginRight: 'auto',
            marginTop: (theme) => theme.spacing(2),
          }}
        >
          {t('newRefueling.form.save')}
        </Button>
      </>
    );
  }

  if (loading || error) return <></>;

  if (fuels.length === 0) {
    return (
      <NoFuel />
    );
  }

  return (
    <Box>
      {fuels.length > 1 && (
        <Typography 
          variant="sectionHeadline"
          textAlign="center"
          sx={{ width: '100%', display: 'block' }}
        >
          {t('newRefueling.subheadline')}
        </Typography>
      )}
      <Paper>
        <Box p={2} pt={2.5}>
          <Stack
            direction="row"
            justifyContent="center"
            sx={{
              pb: 2,
              borderBottom: '1px dashed lightgrey',
              flexWrap: 'wrap',
            }}
          >
            {fuels.map((fuel) => (
              <Chip
                key={fuel.fuelType?.id}
                icon={useIcon(`fuel-${fuel.fuelType?.fuelName}` as CommonIcons)}
                label={t(`fuelTypes.${fuel.fuelType?.fuelName}`)}
                variant={fuelTypeId === fuel.fuelType?.id ? 'filled' : 'outlined'}
                color="primary"
                size="small"
                onClick={() => onFuelChange(fuel.fuelType?.id as string)}
                sx={{
                  m: 0.5,
                  '& svg': {
                    marginLeft: '8px !important',
                  },
                }}
              />
            ))}
          </Stack>
        </Box>
        <Box px={4} pt={1} pb={4}>
          <Stack
            direction="column"
            justifyContent="space-between"
            spacing={2}
          >
            <Controller
              render={({ field, formState }) => (
                <NumberField
                  {...field}
                  value={clearZero(field.value)}
                  onBlur={unsetLastEmpty}
                  onWheel={blurTarget}
                  id="priceTotal"
                  error={!!formState.errors?.priceTotal?.message}
                  helperText={formState.errors.priceTotal?.message}
                  textAlign="right"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Typography 
                          sx={{
                            fontFamily: 'Poppins',
                            fontSize: '0.8em',
                            fontWeight: 'bold',
                            color: (theme) => theme.palette.primary.main,
                          }}
                        >
                          {t('newRefueling.form.priceTotal.name')}
                        </Typography>
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end" sx={{ position: 'relative', top: '1px' }}>
                        <small>Kč</small>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
              name="priceTotal"
              control={control}
            />
            <Controller
              render={({ field, formState }) => (
                <NumberField
                  {...field}
                  value={clearZero(field.value)}
                  onBlur={unsetLastEmpty}
                  onWheel={blurTarget}
                  id="fuelVolume"
                  error={!!formState.errors?.fuelVolume?.message}
                  helperText={formState.errors.fuelVolume?.message}
                  textAlign="right"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Typography 
                          sx={{
                            fontFamily: 'Poppins',
                            fontSize: '0.8em',
                            fontWeight: 'bold',
                            color: (theme) => theme.palette.primary.main,
                          }}
                        >
                          {t('newRefueling.form.fuelVolume.name')}
                        </Typography>
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end" sx={{ position: 'relative', top: '1px' }}>
                        <small> {currentFuel?.volumeUnit?.unitName}</small>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
              name="fuelVolume"
              control={control}
            />
            <Controller
              render={({ field, formState }) => (
                <NumberField
                  {...field}
                  value={clearZero(field.value)}
                  onBlur={unsetLastEmpty}
                  onWheel={blurTarget}
                  id="pricePerUnit"
                  error={!!formState.errors?.priceTotal?.message}
                  helperText={formState.errors.priceTotal?.message}
                  textAlign="right"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Typography 
                          sx={{
                            fontFamily: 'Poppins',
                            fontSize: '0.8em',
                            fontWeight: 'bold',
                            color: (theme) => theme.palette.primary.main,
                          }}
                        >
                          {t('newRefueling.form.pricePerUnit.name')} {currentFuel?.volumeUnit?.unitName}
                        </Typography>
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end" sx={{ position: 'relative', top: '1px' }}>
                        <small>Kč</small>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
              name="pricePerUnit"
              control={control}
            />
            <Stack direction="row" justifyContent="space-between" spacing={2}>
              <Controller
                render={({ field }) => (
                  <Stack direction="row" alignItems="center" justifyContent="space-between">
                    <FormControlLabel
                      sx={{ 
                        flexDirection: 'column',
                        margin: '0 auto',
                        border: (theme) => `1px dashed ${field.value ? theme.palette.secondary.main : 'lightgrey'}`,
                        borderRadius: '12px',
                        padding: '12px',
                        maxWidth: '100px',
                        textAlign: 'center',
                      }}
                      control={
                        <Switch
                          {...field}
                          checked={field.value}
                          size="small"
                          id="fullTank"
                          color="secondary"
                          inputProps={{ 'aria-label': 'controlled' }}
                        />
                      } 
                      label={
                        <Typography 
                          sx={{
                            fontSize: '0.65em',
                            fontWeight: field.value ? 'bold' : 'initial',
                            color: (theme) => field.value ? theme.palette.secondary.main : undefined,
                          }}
                        >
                          {t('newRefueling.form.fullTank.name')}
                        </Typography>
                      } 
                    />
                  </Stack>
                )}
                name="fullTank"
                control={control}
              />
              <Controller
                render={({ field }) => (
                  <Stack direction="row" alignItems="center" justifyContent="space-between">
                    <FormControlLabel
                      sx={{ 
                        flexDirection: 'column',
                        margin: '0 auto',
                        border: (theme) => `1px dashed ${field.value ? theme.palette.secondary.main : 'lightgrey'}`,
                        borderRadius: '12px',
                        padding: '12px',
                        maxWidth: '100px',
                        textAlign: 'center',
                      }}
                      control={
                        <Switch
                          {...field}
                          id="skipTank"
                          size="small"
                          color="secondary"
                          checked={field.value}
                          inputProps={{ 'aria-label': 'controlled' }}
                        />
                      } 
                      label={
                        <Typography 
                          sx={{
                            fontSize: '0.65em',
                            fontWeight: field.value ? 'bold' : 'initial',
                            color: (theme) => field.value ? theme.palette.secondary.main : undefined,
                          }}
                        >
                          {t('newRefueling.form.skipTank.name')}
                        </Typography>
                      }
                    />
                  </Stack>
                )}
                name="skipTank"
                control={control}
              />
            </Stack>
          </Stack>
        </Box>
      </Paper>

      <Stack
        direction="column"
        alignItems="center"
        spacing={1}
        mt={2}
      >
        <Button
          variant="contained"
          color="secondary"
          disabled={odometerState === 'missing'}
          onClick={() => handleSubmit(onContinue)()}
        >
          {t('newRefueling.form.continue')}
        </Button>
      </Stack>
    </Box>
  );
};

export default NewRefueling;
