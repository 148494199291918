import React from 'react';
import {
  Button,
  Paper,
  Box,
  Typography,
  Stack,
} from '@mechis/elements';
import useHeader from '@hooks/useHeader';
import Stepper from '@mechis/blocks/Stepper';
import { useNavigate, useParams } from 'react-router-dom';
import AddDeviceLayout from '@mechis/layouts/AddDeviceLayout';
import SimpleTable from '@mechis/blocks/SimpleTable';
import { ITagOption } from '@mechis/sections/Selections/TagFilters/types';
import useMaintenance from '@hooks/useMaintenance';
import _ from 'lodash';
import NumberFormat from 'react-number-format';
import { CZK_UNIT_NAME, THOUSAND_SEPARATOR } from '@configurations/constants/app';
import { useDeviceHeaderDetailQuery } from '@state/mechis-backend/generated/schema';
import {
  // MaintenanceInterval,
  useMaintenanceIntervalsByDeviceQuery,
} from '@state/mechis-backend/generated/schema';
import FormBlock from '@mechis/blocks/FormBlock';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Date from '@mechis/sections/Selections/Date';
import Odometer from '@mechis/sections/Selections/Odometer';
import Attachments from '@mechis/sections/Selections/Attachments';
import { useTranslation } from 'react-i18next';
import IntervalTitle from '@screens/TechDetail/EventDetail/maintenance/components/IntervalTitle';
import OperationLabel from '@screens/TechDetail/EventDetail/maintenance/components/OperationLabel';

const Step3 = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { techId } = useParams();

  useHeader({
    headline: t('newMaintenance.step3.headline'),
    widget: (
      <Stepper
        steps={4}
        defaultStep={3}
        onBack={() => navigate(`/app/${techId}/new-maintenance/2`)}
      />
    ),
  });

  const { data: headerDetail } = useDeviceHeaderDetailQuery({
    variables: {
      deviceId: Number(techId),
    },
    fetchPolicy: 'cache-first',
    skip: !techId,
  });

  const { 
    onCreateMaintenanceEvent,
    description,
    expenses,
    prices,
    clearedOperations,
    isUploading,
  } = useMaintenance({ keepOperations: true });
  
  const { data: maintenanceIntervals } = useMaintenanceIntervalsByDeviceQuery({
    variables: {
      deviceId: Number(techId),
    },
    skip: !techId,
  });

  const getCompletedOperations = () => {
    if (maintenanceIntervals && clearedOperations) {
      const operations = clearedOperations.map(({ operation }) => operation.id);    
      const data = maintenanceIntervals.maintenanceIntervalsByDevice
        .map((interval) => {
          const operationData: Array<any> = interval.operation
            ? interval.operation
              .filter((operation) => operations.includes(+operation.id))
              .map((operation) => ({
                label: <OperationLabel name={operation.name} type={operation.operationType} />,
                value: <CheckCircleIcon color="secondary" />,
              }))
            : [];
          if (operationData.length > 0) {
            return {
              name: <IntervalTitle name={interval.name} periodDay={interval.periodDay} periodDistance={interval.periodDistance} />,
              data: operationData,
            };
          }
          return null;
        })
        .filter(Boolean); 
      return data;
    }
  };

  const getExpensesData = () => {
    return expenses.map((expense: ITagOption) => {
      return {
        label: expense.tagTranslation,
        value: (
          <NumberFormat
            value={_.filter(prices, { tagId: expense.tagId })[0].price}
            suffix=" kč"
            displayType="text"
            thousandSeparator={THOUSAND_SEPARATOR}
          />
        ),
      };
    });
  };

  const getPriceTotal = () => {
    let total = 0;
    expenses.forEach((expense: ITagOption) => {
      const price = _.filter(prices, { tagId: expense.tagId })[0].price;
      if (price) total += price;
    });
    return total;
  };

  return (
    <AddDeviceLayout
      defaultNickname={headerDetail?.device?.name}
      disableEditMode
    >
      <Stack spacing={3} mt={-2}>
        <Stack direction="row" alignItems="center" justifyContent="center">
          <Date />
          <Odometer />
          <Attachments />
        </Stack>

        {/* Expenses */}
        <Box>
          <SimpleTable
            title={t('newMaintenance.step3.expenses')}
            data={getExpensesData()}
          />
          <Stack alignItems={'flex-end'} pt={0.5} pr={1.5}>
            <Typography variant="sectionHeadline" marginBottom={'-12px'}>{t('newMaintenance.step3.priceTotal')}</Typography>
            <Typography variant="h4">
              <NumberFormat
                suffix={` ${CZK_UNIT_NAME}`}
                value={getPriceTotal()}
                displayType="text"
                thousandSeparator={THOUSAND_SEPARATOR}
              />
            </Typography>
          </Stack>
        </Box>
        
        {/* Operations */}
        <FormBlock headline={t('newMaintenance.step3.finishedTasks')}>
          <Stack spacing={2}>
            {getCompletedOperations()?.map((interval, index) => 
              <SimpleTable
                title={interval?.name}
                key={index}
                // @ts-expect-error
                data={interval.data}
              />
            )}
            {getCompletedOperations()?.length === 0 && (
              <Box display={'flex'} justifyContent={'center'}>
                <Typography><em>{t('newMaintenance.step3.noTask')}</em></Typography>
              </Box>
            )}
          </Stack>
        </FormBlock>

        {/* Note */}
        <Box>
          <Typography variant="sectionHeadline">{t('newMaintenance.step3.note')}</Typography>
          <Paper>
            <Box p={2}>
              <Typography>
                <small>
                  {description ? description : <em>{t('newMaintenance.step3.withoutNote')}</em>}
                </small>
              </Typography>
            </Box>
          </Paper>
        </Box>
        <Button
          isLoading={isUploading}
          variant="contained"
          color="secondary"
          onClick={onCreateMaintenanceEvent}
          sx={{
            alignSelf: 'center',
          }}
        >
          {t('newMaintenance.step3.save')}
        </Button>
      </Stack>
    </AddDeviceLayout>
  );
};

export default Step3;
