import React, { FC, useEffect, useState } from 'react';
import { IProps } from './types';
import { Block } from './styles';
import {
  IconButton,
  Typography,
  Stack,
} from '@mechis/elements';
import Delete from '@mui/icons-material/Delete';
import { getIconByCategory } from '@hooks/useTagManager';
import { useTheme } from '@mui/material/styles';
import NumberField from '@mechis/elements/NumberField';

const Expense: FC<IProps> = ({
  title,
  price,
  setPrice,
  tagId,
  onDelete,
  onClick,
  tagCategory,
}) => {
  const theme = useTheme();
  const [ localPrice, setLocalPrice ] = useState(0);
  
  useEffect(() => {
    setLocalPrice(price ?? 0);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setPrice(localPrice);
  }, [ localPrice ]);

  const handleChange = (value: number) => {
    setLocalPrice(value);
  };

  return (
    <Block onClick={onClick} id={tagId}>
      <NumberField
        value={localPrice}
        onChange={(e) => handleChange(+e.target.value)}
        allowEmpty={false}
        label={
          <Stack
            direction="row"
            spacing={1}
            alignItems="center"
            sx={{
              position: 'relative',
              top: '-2px',
            }}
          >
            <IconButton
              color="primary"
              size="small"
              isSquared
              sx={{ 
                mt: '3px',
              }}
            >
              {getIconByCategory(tagCategory)}
            </IconButton>
            <span 
              style={{ 
                color: theme.palette.primary.main,
                fontWeight: 'bold',
              }}
            >
              {title}
            </span>
          </Stack>
        }
        id={`expense-${tagId}`}
        variant="outlined"
        size="small"
        sx={{ mr: 1.5 }}
        InputProps={{
          sx: {
            '& input': {
              textAlign: 'right',
              fontWeight: 'bold',
              // eslint-disable-next-line
              color: (theme) => theme.palette.primary.main,
              fontSize: '1.225em',
              fontFamily: 'Poppins',
            },
          },
          endAdornment: (
            <Typography
              color="primary"
              sx={{ 
                ml: 0.5,
                fontWeight: 'bold',
                fontFamily: 'Poppins',
              }}
            > 
              <small>Kč</small>
            </Typography>
          ),
        }}
      />
      <div style={{ minWidth: '15%', textAlign: 'right' }}>
        <IconButton
          size="small"
          onClick={onDelete}
          color="error"
        >
          <Delete fontSize="small" />
        </IconButton>
      </div>
    </Block>
  );
};

export default Expense;
