import React, { FC, useState } from 'react';
import { IProps } from './types';
import { IAddDeviceQuery } from '@state/queries/addDevice/types';
import { Layout, Header, AvatarNameContainer } from './styles';
import {
  IconButton,
  Typography,
  Box,
  TextField,
  Button,
  Stack,
} from '@mechis/elements';
import { useQuery } from '@apollo/client';
import { GET_ADD_DEVICE_TMP_STORE } from '@state/queries/addDevice';
import Vrp from '@mechis/blocks/Vrp';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import addDeviceCacheControl from '@state/mutations/addDevice';
import DeviceAvatar from '@mechis/blocks/DeviceAvatar/DeviceAvatar';

const AddDeviceLayout: FC<IProps> = ({ children, disableEditMode, defaultNickname }) => {
  const [ isEditMode, setIsEditMode ] = useState(false);
  const { t } = useTranslation();
  const { data } = useQuery<IAddDeviceQuery>(GET_ADD_DEVICE_TMP_STORE);
  const nickname = data?.addDevice.name;
  const { setName } = addDeviceCacheControl;

  const schema = yup.object({
    machineNickname: yup.string().required(t('addDevice.components.machineNickname.required')),
  }).required();

  const { control, handleSubmit } = useForm<{ machineNickname: string }>({
    defaultValues: {
      machineNickname: data?.addDevice.name,
    },
    resolver: yupResolver(schema),
  });

  const onSubmitEdit = async ({ machineNickname }: { machineNickname: string }) => {
    setName(machineNickname);
    setIsEditMode(false);
  };

  return (
    <Layout>
      <Header>
        <AvatarNameContainer>
          <Box mr={1}>
            <DeviceAvatar
              deviceCategoryId={data?.addDevice.deviceCategory?.id ?? 1}
              imageId={data?.addDevice?.thumbnailImage?.storageName}
              size={42}
            />
          </Box>
          {isEditMode ? (
            <Stack 
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{ my: 2 }}
            >
              <Controller
                render={({ field, formState }) => (
                  <TextField
                    {...field}
                    label={t('addDevice.components.machineNickname.name')}
                    id="machineNickname"
                    error={!!formState.errors.machineNickname?.message}
                    helperText={formState.errors.machineNickname?.message}
                    variant="outlined"
                    size="small"
                    sx={{ mr: 1.5 }}
                  />
                )}
                name="machineNickname"
                control={control}
              />
              <Button
                onClick={handleSubmit(onSubmitEdit)}
                variant="contained"
                color="secondary"
                size="small"
              >
                Upravit
              </Button>
            </Stack>
          ) : (
            <>
              <Typography
                variant="h5"
                sx={{
                  color: (theme) => theme.palette.primary.dark, 
                }}
              >
                {defaultNickname ? defaultNickname : nickname}
              </Typography>
              {!disableEditMode && (
                <IconButton
                  onClick={() => setIsEditMode(true)}
                  size="small"
                  color="background"
                  sx={{ 
                    ml: 2,
                    mr: 2,
                  }}
                >
                  <ModeEditIcon 
                    fontSize="small"
                    color="primary"
                  />
                </IconButton>
              )}
            </>
          )}
        </AvatarNameContainer>
        {!isEditMode && (
          <Vrp vrp={data?.addDevice.spz} />
        )}
      </Header>
      <Box mt={4}>
        <Box p={0}>
          {children}
        </Box>
      </Box>
    </Layout>
  );
};

export default AddDeviceLayout;
