
import React, { ChangeEvent, forwardRef, useEffect, useState } from 'react';
import TextField from '../TextField';
import { NumberFieldProps } from './types';

const normalize = (val: string) => val.replace(',', '.').replace(/(\.|\.)$/, '');

const NumberField = forwardRef<any, NumberFieldProps>(({ value, onChange, onlyInteger, allowEmpty = true, textAlign, ...props}, ref) => {
  const numberRegex = onlyInteger ? /^\d*$/ : /^\d*([.,]\d*)?$/;
  const [ innerValue, setInnerValue ] = useState<string>(value === null || value === undefined ? '' : `${value}`);

  useEffect(() => {
    if (normalize(innerValue) !== normalize(`${value}`)) {
      setInnerValue(value === null ? '' : `${value}`);
    }
  }, [ value ]);
  
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    let newValue = event.target.value;

    if (!onChange) return;
    
    if (!numberRegex.test(newValue)) return;

    if (!allowEmpty && newValue === '') newValue = '0';

    if (newValue.startsWith('0') && ![ '.', ',' ].includes(newValue[1]) && newValue.length > 1) {
      newValue = newValue.replace(/^0+/, '');
    }

    setInnerValue(newValue);

    const numberValue = newValue.replace(',', '.');
    onChange({ ...event, target: { ...event.target, value: numberValue } });
  };

  return (
    <TextField
      {...props}
      ref={ref}
      value={innerValue}
      onChange={handleChange}
      inputProps={{ inputMode: onlyInteger ? 'numeric' : 'decimal', style: { textAlign } }}
    />
  );
});

NumberField.displayName = 'NumberField';

export default NumberField;
