import React, { FC } from 'react';
import { IProps } from './types';
import FlashMessage from '@mechis/blocks/FlashMessage/FlashMessage';
import OdometerToDateValidateModal from '@mechis/sections/OdometerToDateValidateModal/OdometerToDateValidateModal';

const RootLayout: FC<IProps> = ({ children }) => {
  return (
    <>
      {children}
      <FlashMessage />
      <OdometerToDateValidateModal />
    </>
  );
};

export default RootLayout;
