import React, { FC } from 'react';
import { IProps } from './types';
import {
  Block,
  Value,
  PaperBlock,
  InnerPaperBlock,
  PaperBlockHeader,
  PaperBlockHeaderName,
} from './styles';
import NumberFormat from 'react-number-format';
import {
  Typography,
  Box,
  IconButton,
} from '@mechis/elements';
import {
  //CZK_UNIT_NAME,
  THOUSAND_SEPARATOR,
} from '@configurations/constants/app';
import { OutputDateFormats } from '@configurations/types/global';
import { tableOutputDate } from '@utilities/app';
import useEvent from '@hooks/useEvent';

//ALWAYS MECHIS COINS FOR TRANSACTIONS FOR NOW
//const NAMES_TRANSACTIONS_FOR_CREDITS = [ 'dataQuoteUpgrade', 'membershipMonthlyFee', 'dataQuoteMonthlyFee' ];

const Transaction: FC<IProps> = ({
  title,
  date,
  type,
  priceTotal,
  isEven = true,
//  name,
}) => {
  const { eventIcon } = useEvent(11);
  //const isTransactionForCredits = name ? NAMES_TRANSACTIONS_FOR_CREDITS.includes(name) : false; DEACTIVATED 

  return (
    <Block>
      <Box mr={2} sx={{ position: 'relative', top: '-8px' }}>
        <IconButton
          color={isEven ? 'primary' : 'secondary'}
          size="small"
          isSquared
          sx={{ width: 40, height: 40 }}
        >
          {eventIcon(type)}
        </IconButton>
      </Box>
      <PaperBlock>
        <InnerPaperBlock px={2} py={2}>
          <PaperBlockHeader>
            <PaperBlockHeaderName>
              <Typography
                variant="h6"
                color={isEven ? 'primary' : 'secondary'}
              >
                {title}
              </Typography>
  
            </PaperBlockHeaderName>
            <Value variant="h6">
              <Typography
                variant="subtitle1"
                component="span"
                sx={{
                  lineHeight: 1,
                }}
              >
                {tableOutputDate(date, OutputDateFormats.Classic)}
              </Typography>
 
              <span className={priceTotal && priceTotal < 0 ? 'negative' : undefined}>
                <NumberFormat
                  value={priceTotal}
                  displayType="text"
                  thousandSeparator={THOUSAND_SEPARATOR}
                  suffix=" "
                  //suffix={isTransactionForCredits ? ' ' : ` ${CZK_UNIT_NAME}`}
                />
                <span style={{textDecoration: 'line-through'}}>M</span>
              </span>
            </Value>
          </PaperBlockHeader>
        </InnerPaperBlock>
      </PaperBlock>
    </Block>
  );
};

export default Transaction;
