import { IProps } from '@mechis/sections/DeviceListing/types';
import { Box, Grid, Stack, Typography, Skeleton } from '@mechis/elements';
import TechCard from '@mechis/blocks/TechCard';
import React, { useMemo } from 'react';
import ErrorIcon from '@mui/icons-material/Error';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { EmptyDeviceListingMessage } from '@mechis/sections/DeviceListing/EmptyDeviceListingMessage';
import replaceUrlSegment from '@utilities/replaceUrlSegment';
import { Dictionary } from 'lodash';
import { compareAsc } from 'date-fns';
import useNavigate from '@hooks/useNavigate';
import useDevices from '@hooks/useDevices';
import selectionsCacheControl from '@state/mutations/selections';

export const DeviceListing: React.FC<IProps> = ({
  onClick,
  isInModal,
  excludedTechId,
}) => {
  const { navigate } = useNavigate();
  const { data, loading, error, userHasNoDevices } = useDevices();
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const { setSelectedDeviceId } = selectionsCacheControl;

  const handleNavigateDetail = (techId: string) => {
    if (pathname === '/app') {
      navigate(`/app/${techId}/tech-detail`);
    } else {
      const destination = replaceUrlSegment(pathname, techId, 1);
      navigate(destination);
    }
  };

  const deviceCategoryPriority: Dictionary<number> = {
    'passenger-car': 0,
    motorcycle: 1,
    truck: 2,
    racing: 3,
    veteran: 4,
    'constructions-machine': 5,
    'garden-machinery': 6,
    trailer: 7,
    other: 8,
  };

  const sortDeviceCategoriesByPriority = (first: string | undefined, second: string | undefined) => {
    if (!first || !second) return 0;
    if (deviceCategoryPriority[first] < deviceCategoryPriority[second]) return -1;
    if (deviceCategoryPriority[first] > deviceCategoryPriority[second]) return 1;
    return 0;
  };

  const relevantDeviceCategories: Array<string | undefined> = useMemo(() => Array.from(
    new Set(data?.devices
      .map((device) => device.deviceCategory?.name)
    )), [ data ]);

  if (loading) {
    return (
      <Stack spacing={2}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={4}>
          <Skeleton
            variant="rounded"
            sx={{
              minWidth: '60px',
              minHeight: '60px',
            }}
          />
          <Skeleton
            variant="rounded"
            sx={{
              width: '100%',
              height: '60px',
            }}
          />
        </Stack>
        <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={4}>
          <Skeleton
            variant="rounded"
            sx={{
              minWidth: '60px',
              minHeight: '60px',
            }}
          />
          <Skeleton
            variant="rounded"
            sx={{
              width: '100%',
              height: '60px',
            }}
          />
        </Stack>
        <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={4}>
          <Skeleton
            variant="rounded"
            sx={{
              minWidth: '60px',
              minHeight: '60px',
            }}
          />
          <Skeleton
            variant="rounded"
            sx={{
              width: '100%',
              height: '60px',
            }}
          />
        </Stack>
        <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={4}>
          <Skeleton
            variant="rounded"
            sx={{
              minWidth: '60px',
              minHeight: '60px',
            }}
          />
          <Skeleton
            variant="rounded"
            sx={{
              width: '100%',
              height: '60px',
            }}
          />
        </Stack>
        <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={4}>
          <Skeleton
            variant="rounded"
            sx={{
              minWidth: '60px',
              minHeight: '60px',
            }}
          />
          <Skeleton
            variant="rounded"
            sx={{
              width: '100%',
              height: '60px',
            }}
          />
        </Stack>
      </Stack>
    );
  }

  if (error) {
    return (
      <Grid container justifyContent="center" alignItems="center">
        <ErrorIcon />
        <Typography variant="sectionHeadline">{t('snackbar.messages.HTTP_CALL_ERROR')}</Typography>
      </Grid>
    );
  }

  if (userHasNoDevices) {
    return (
      <EmptyDeviceListingMessage />
    );
  }

  return (
    <>
      {relevantDeviceCategories
        .sort(sortDeviceCategoriesByPriority)
        .map((categoryName) => (
          <Box key={categoryName} mb={4}>
            <Typography variant="sectionHeadline">
              {isInModal ? (
                <small>
                  {t(`deviceCategories.${categoryName}`)}
                </small>
              ) : t(`deviceCategories.${categoryName}`)}
              
            </Typography>
            <Stack spacing={2}>
              {data?.devices
                .filter((device) => device.deviceCategory?.name === categoryName)
                .filter((device) => excludedTechId !== device.id)
                .sort((a, b) => compareAsc(new Date(a.creationDate ?? ''), new Date(b.creationDate ?? '')))
                .map((device, i) =>
                  <Box key={device.id} style={{ opacity: device.suspendedDate ? 0.5 : 1}}>
                    <TechCard
                      isEven={i % 2 === 0}
                      title={device.name}
                      key={device.id}
                      vrp={device.spz}
                      deviceCategoryId={Number(device.deviceCategory?.id) ?? 1}
                      serialNumber={device.serialNumber}
                      imageId={device?.thumbnailImage?.storageName}
                      odometerState={device.actualOdometerState}
                      odometerUnit={device.odometerUnit?.unitName}
                      suspendedDate={device.suspendedDate}
                      onClick={() => {
                        if (!device.suspendedDate) {
                          setSelectedDeviceId(Number(device.id));
                          handleNavigateDetail(device.id);
                          if (onClick) {
                            onClick();
                          }
                        }
                      }}
                    />
                  </Box>
                )}
            </Stack>
          </Box>
        ))}
    </>
  );
};

