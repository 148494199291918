import React from 'react';
import { Box } from '@mechis/elements';
import useHeader from '@hooks/useHeader';
import Stepper from '@mechis/blocks/Stepper';
import { useNavigate, useParams } from 'react-router-dom';
import Expenses from '@mechis/sections/Expenses';
import { useTranslation } from 'react-i18next';

const Step1 = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { techId } = useParams();

  useHeader({
    headline: t('newMaintenance.step1.headline'),
    widget: (
      <Stepper
        steps={4}
        defaultStep={1}
        onBack={() => navigate(`/app/${techId}/new-maintenance`, { state: { keepOperations: true }})}
        onNext={() => navigate(`/app/${techId}/new-maintenance/2`)}
      />
    ),
  });

  return (
    <Box mt={-4}>
      <Expenses />
    </Box>
  );
};

export default Step1;
