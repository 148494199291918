import React, { Component, ErrorInfo, ReactNode } from 'react';

import { ACCESS_TOKEN, BOUNDARY_ERROR, BOUNDARY_ERROR_TTL } from '@configurations/constants/app';
import { getWithExpiry, setWithExpiry } from '@hooks/useTTLLocalStorage';

interface Props {
  children: ReactNode
}

interface State {
  hasError: boolean
  error?: Error
  info?: ErrorInfo
}

const ErrorComponent = () => {
  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', textAlign: 'center' }}>
      <div>
        <h1>Něco se pokazilo</h1>
        <p>Probíhá restart aplikace...</p>
      </div>
    </div>
  );
};

class ErrorBoundary extends Component<Props, State> {
  state = { hasError: false};

  handleReset = () => {
    const isBoundaryErrorRecent = getWithExpiry(BOUNDARY_ERROR, BOUNDARY_ERROR_TTL);

    if (isBoundaryErrorRecent) {
      localStorage.removeItem(BOUNDARY_ERROR);
      localStorage.removeItem(ACCESS_TOKEN);
      window.location.href = '/';
    } else {
      setWithExpiry(BOUNDARY_ERROR, 'true');
      window.location.reload();
    }
  }; 

  static getDerivedStateFromError(): State {
    return { hasError: true };
  }

  componentDidCatch = (error: Error, info: ErrorInfo) => {
    this.setState({ error, info });
    this.handleReset();
  };

  render() {
    return this.state.hasError ? <ErrorComponent /> : this.props.children;
  }
}

export default ErrorBoundary;
