import React, { useState } from 'react';
import { Box, Grid } from '@mechis/elements';
import { useTranslation } from 'react-i18next';
import StorageCapacityWidget from './sections/StorageCapacityWidget';
import CreditWidget from './sections/CreditWidget';
import TransactionsWidget from './sections/TransactionsWidget';
import { useCreditPreviewQuery } from '@state/mechis-backend/generated/schema';
import Loader from '@mechis/blocks/Loader';
import appCacheControl from '@state/mutations/app';
import Membership from './sections/Membership';

const Mechis = () => {
  const { setToolbarHeadline } = appCacheControl;
  const { t } = useTranslation();
  const [ noCreditOpen, setNoCreditOpen ] = useState<boolean>(false);

  setToolbarHeadline(t('appBar.headline.mechis'));
  const { data: creditPreview, loading, error } = useCreditPreviewQuery();

  if (loading) {
    return <Loader />;
  }

  if (error) {
    throw new Error('Unable to get credit data');
  }

  return (
    <Box mt={-2}>
      <Membership setNoCreditOpen={setNoCreditOpen} />
      <Grid container spacing={2} mt={0.25}>
        <Grid item xs={7}>
          <StorageCapacityWidget setNoCreditOpen={setNoCreditOpen} />
        </Grid>
        <Grid item xs={5}>
          <CreditWidget
            creditBalance={creditPreview?.user?.creditBalance ?? 0}
            noCreditOpen={noCreditOpen}
            setNoCreditOpen={setNoCreditOpen}
          />
        </Grid>
      </Grid>
      <TransactionsWidget transactions={creditPreview?.creditTransactionsForCurrentUser} />
    </Box>
  );
};

export default Mechis;
