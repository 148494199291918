import React, { FC, useState, useEffect } from 'react';
import { useAttachments, IAttachmentsListItemProps } from '@mechis/sections/Attachments';
import {
  ListItem,
  ListItemText,
  ListItemAvatar,
  ListItemSecondaryAction,
  Avatar,
  CircularProgress,
  IconButton,
  Stack,
  Skeleton,
  Box,
} from '@mechis/elements';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import BorderAllIcon from '@mui/icons-material/BorderAll';
import DeleteIcon from '@mui/icons-material/Delete';
import EventNoteIcon from '@mui/icons-material/EventNote';
import Logo from '@mechis/blocks/Logo/Logo2';

const AttachmentsListItem: FC<IAttachmentsListItemProps> = ({
  key,
  variant,
  src,
  filePreview,
  alt,
  type,
  name,
  size,
  onDelete,
  onDetail,
  onClick,
}) => {
  const { isUploading, getType, getFileURL } = useAttachments();
  const [ source, setSource ] = useState<string | null>(null); 

  useEffect(() => {
    const fetchAvatarUrl = async () => {
      try {
        const url = await getFileURL(src);
        setSource(url);
      } catch (error) {
        console.log('Error fetching avatar URL:', error);
        setSource(null);
      }
    };

    if (filePreview) {
      setSource(filePreview);
    } else if (variant !== 'selected') {
      fetchAvatarUrl();
    }
  }, [ src, filePreview ]);

  const onClickAvatar = () => {
    if (getType(type) === 'doc' && source) {
      window.open(source, '_blank');
    }
    if (getType(type) === 'image' && onClick) {
      onClick();
    }
  };
  
  const getAvatar = () => {
    switch (type) {
      case 'application/pdf': return <PictureAsPdfIcon color="error" />;
      case 'application/msword': return <TextSnippetIcon color="info" />;
      case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document': return <TextSnippetIcon color="info" />;
      case 'text/plain': return <TextSnippetIcon color="disabled" />;
      case 'application/vnd.ms-excel': return <BorderAllIcon color="success" />;
      case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': return <BorderAllIcon color="success" />;
      case 'text/csv': return <BorderAllIcon color="success" />;
      default:
        return (
          <Box
            sx={{
              width: '100%',
              height: '100%',
              position: 'relative',
              '& > div': {
                position: 'absolute',
                width: '32px !important',
                height: '32px !important',
                top: '3px',
                left: '3px',
                opacity: 0.25,
              },
            }}
          >
            <Logo variant="medium" />
            <Skeleton
              variant="rounded"
              width={40}
              height={40}
            />
          </Box>
        );
    }
  };

  return (
    <ListItem 
      key={key}
      sx={{
        my: 2,
        borderRadius: '16px',
        border: variant === 'selected' ? '1px dashed lightgrey' : null,
        minHeight: variant === 'selected' ? '75px' : null,
      }}
    >
      <ListItemAvatar onClick={onClickAvatar}>
        <Avatar
          variant="rounded"
          src={source || undefined}
          alt={alt}
          sx={{
            border: '1px solid lightgrey',
            backgroundColor: '#bdbdbd1f',
          }} 
        >
          {getAvatar()}
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={name}
        secondary={
          variant === 'selected' && size ?
            `${(size / 1024 / 1024).toFixed(2)} MB` :
            null
        }
        primaryTypographyProps={{
          sx: {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: 'block',
            width: onDetail ? '86%' : '92%',
          },
        }}
      />
      <ListItemSecondaryAction 
        sx={{
          right: 0,
        }}
      >
        {isUploading ? (
          <CircularProgress />
        ) : (
          <Stack direction="row" spacing={0.5} justifyContent="space-between">
            {onDetail &&(
              <IconButton
                edge="end"
                aria-label="go-to-detail"
                onClick={onDetail}
              >
                <EventNoteIcon color="secondary" />
              </IconButton>
            )}
            {onDelete && (
              <IconButton
                edge="end"
                aria-label="delete"
                onClick={onDelete}
              >
                <DeleteIcon />
              </IconButton>
            )}
          </Stack>
        )}
      </ListItemSecondaryAction>
    </ListItem>
  );
};

export const AttachmentsListLoaderItem = () => {
  return (
    <ListItem>
      <ListItemAvatar>
        <Skeleton 
          variant="rounded"
          width={40}
          height={40}
        />
      </ListItemAvatar>
      <ListItemText>
        <Skeleton 
          width={230}
          height={40}
        />
      </ListItemText>
      <ListItemSecondaryAction sx={{ right: '10px' }}>
        <Stack direction="row" spacing={2} justifyContent="space-between">
          <Skeleton 
            width={24}
            height={40}
          />
          <Skeleton
            width={24}
            height={40}
          />
        </Stack>
      </ListItemSecondaryAction>
    </ListItem>
  );
};

export default AttachmentsListItem;
