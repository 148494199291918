import React, { FC } from 'react';
import { IProps } from './types';
import {
  TextField,
  InputAdornment,
} from '@mechis/elements';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { useQuery } from '@apollo/client';
import addDeviceCacheControl from '@state/mutations/addDevice';
import { IAddDeviceQuery } from '@state/queries/addDevice/types';
import { GET_ADD_DEVICE_TMP_STORE } from '@state/queries/addDevice';
import { useTranslation } from 'react-i18next';
import TodayIcon from '@mui/icons-material/Today';
import { setDay, setHours } from 'date-fns';

const FirstRegistrationDate: FC<IProps> = () => {
  const { t } = useTranslation();
  const { data } = useQuery<IAddDeviceQuery>(GET_ADD_DEVICE_TMP_STORE);
  const { setFirstRegistrationDate } = addDeviceCacheControl;

  const onChange = (value: Date | null) => {
    setFirstRegistrationDate(value ? setHours(setDay(value, 1), 0) : null);
  };

  return (
    <MobileDatePicker
      showToolbar={false}
      views={[ 'year', 'month' ]}
      label={t('addDevice.components.firstRegistrationDate.name')}
      inputFormat="MM/yyyy"
      value={data?.addDevice.firstRegistrationDate}
      disableFuture
      onChange={onChange}
      renderInput={(params) =>
        <TextField
          id="firstRegistrationDate"
          {...params}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <TodayIcon fontSize="small" />
              </InputAdornment>
            ),
          }}
        />
      }
    />
  );
};

export default FirstRegistrationDate;
