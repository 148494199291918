import React, { useState, useEffect } from 'react';
import { useAttachments, AttachmentAllowedType } from '@mechis/sections/Attachments';
import {
  AttachmentsConfirmDeleteModal,
  AttachmentsGridItem,
  AttachmentsListItem,
} from '@mechis/sections/Attachments/components';
import {
  List,
  Grid,
  Box,
} from '@mechis/elements';
import Zoom from 'yet-another-react-lightbox/plugins/zoom';
import Lightbox from 'yet-another-react-lightbox';
import 'yet-another-react-lightbox/styles.css';
import AttachmentsLightboxDynamicUrlPlugin from '../../components/AttachmentsLightboxDynamicUrlPlugin';

const AttachmentsFetchedEvent = () => {
  const {
    view,
    setIsDeleteIntent,
    loadAttachmentsEvent,
    attachmentsEvent,
  } = useAttachments();

  useEffect(() => {
    loadAttachmentsEvent();
  }, []);

  const [ lightBoxOpened, setLightBoxOpened ] = useState<boolean>(false);
  const [ clickedImageIndex, setClickedImageIndex ] = useState<number>(0);

  const onClick = (storageName: string) => {
    if (attachmentsEvent) {
      setClickedImageIndex(
        attachmentsEvent
          .filter(({ attachment }) => attachment.mimeType.startsWith('image/'))
          .findIndex(({ attachment }) => attachment.storageName === storageName)
      );
      setLightBoxOpened(true);
    }
  };

  const imageSlides = (attachmentsEvent ?? [])
    .filter(({ attachment }) => attachment.mimeType.startsWith('image/'))
    .map(({ attachment }) => ({
      src: attachment.storageName,
      width: 1200,
      height: 800,
    }));

  if (attachmentsEvent?.length === 0) return null;

  if (view === 'list') {
    return (
      <>
        <AttachmentsConfirmDeleteModal />
        <List>
          {attachmentsEvent?.map(({ attachment }, index) => {
            return (
              <AttachmentsListItem
                key={index}
                variant="fetched"
                src={attachment.storageName}
                alt={attachment.originalName}
                type={attachment.mimeType as AttachmentAllowedType}
                name={attachment.originalName}
                onClick={() => onClick(attachment.storageName)}
                onDelete={() => setIsDeleteIntent(attachment)}
              />
            );
          })}
        </List>
        <Lightbox
          open={lightBoxOpened}
          close={() => setLightBoxOpened(false)}
          index={clickedImageIndex}
          slides={imageSlides}
          plugins={[ Zoom, AttachmentsLightboxDynamicUrlPlugin ]}
          zoom={{ maxZoomPixelRatio: 3, scrollToZoom: true }}
        />
      </>
    );
  }

  if (view === 'grid') {
    return (
      <Box mt={2}>
        <Grid container spacing={2}>
          {attachmentsEvent?.map(({ attachment }, index) => {
            return (
              <AttachmentsGridItem
                key={index}
                variant="fetched"
                src={attachment.storageName}
                alt={attachment.originalName}
                type={attachment.mimeType as AttachmentAllowedType}
                name={attachment.originalName}
                onClick={() => onClick(attachment.storageName)}
              />
            );
          })}
          <Lightbox
            open={lightBoxOpened}
            close={() => setLightBoxOpened(false)}
            index={clickedImageIndex}
            slides={imageSlides}
            plugins={[ Zoom, AttachmentsLightboxDynamicUrlPlugin ]}
            zoom={{ maxZoomPixelRatio: 3, scrollToZoom: true }}
          />
        </Grid>
      </Box>
    );
  }

  return null;
};

export default AttachmentsFetchedEvent;

