const breakpoints = {
  xs: 0,
  iPhone5: 320,
  galaxyS8: 360,
  iPhoneX: 375,
  iPhone12: 390,
  pixel5: 411,
  iPhone14ProMax: 430,
  sm: 600,
  md: 960,
  lg: 1280,
  xl: 1920,
};

export default breakpoints;
