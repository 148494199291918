import React, { FC, useEffect, useState } from 'react';
import { Stack, Box, Paper, Typography } from '@mechis/elements';
import AnalyzeCategory from '@mechis/blocks/AnalyzeCategory/AnalyzeCategory';
import { FuelDevice, useDeviceUsedFuelsQuery, AnalyzeCategory as AnalyzeCategoryType } from '@state/mechis-backend/generated/schema';
import useHeader from '@hooks/useHeader';
import Selections from '@mechis/sections/Selections';
import ControlMenu from '../ControlMenu';
import { useQuery } from '@apollo/client';
import { ISelectionsQuery } from '@state/queries/selections/types';
import { GET_SELECTIONS } from '@state/queries/selections';
import { useAnalyzeEventFilterCounterQuery } from '@state/mechis-backend/generated/schema';
import { filterSelectionToQuery } from '@hooks/utils';
import FormBlock from '@mechis/blocks/FormBlock';
import { useTranslation } from 'react-i18next';
import useTagManager from '@hooks/useTagManager';

import StatCounter from '@mechis/blocks/StatCounter';
import useTech, { UnitType } from '@hooks/useTech';

const Analysis: FC = () => {
  useHeader({
    headline: 'appBar.headline.analysis',
    widget: (
      <Selections
        generalFilters={{
          isSelection: true,
          order: 1,
          isDate: true,
          isOdometer: true,
          isPrice: true,
          isRecordType: true,
        }}
        tagFilters={{ isSelection: true, order: 2 }}
      >
        <ControlMenu />
      </Selections>
    ),
  });

  const { data } = useQuery<ISelectionsQuery>(GET_SELECTIONS);
  const { getTagsIds } = useTagManager();
  const tags = getTagsIds();
  const { techId, getTechUnit } = useTech();
  const { t } = useTranslation();

  // dateFrom - dateTo
  const dateOptions: Intl.DateTimeFormatOptions = { day: 'numeric', month: 'numeric', year: 'numeric' };
  const dateFrom = data?.selections.filterDateFrom.toLocaleString('cs-CZ', dateOptions);
  const dateTo = data?.selections.filterDateTo.toLocaleString('cs-CZ', dateOptions);

  // odometerFrom - odometerTo
  const odometerFrom = Number(data?.selections.filterOdometerFrom);
  const odometerTo = Number(data?.selections.filterOdometerTo);

  // records counter
  const { 
    data: countData,
    // loading: countLoading,
    // error: countError,
  } = useAnalyzeEventFilterCounterQuery({
    fetchPolicy: 'network-only',
    variables: {
      deviceId: techId,
      filters: filterSelectionToQuery(data?.selections),
      tags,
    },
    skip: !techId,
  });
  
  const oneYearAgo = new Date();
  oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1);
  const [ fuels, setFuels ] = useState<FuelDevice[]>([]);
  
  const { data: deviceFuels, loading: deviceFuelsLoading, error: deviceFuelsError } = useDeviceUsedFuelsQuery({
    variables: {
      deviceId: techId,
    },
    skip: !techId,
  });

  useEffect(() => {
    if (deviceFuels && deviceFuels.device?.fuel?.length) {
      setFuels(deviceFuels.device.fuel);
    }
  }, [ deviceFuels ]);

  if (deviceFuelsLoading || deviceFuelsError) return <></>;

  return (
    <Stack spacing={3}>
      <Paper>
        <Box p={2}>
          <Stack direction="row" spacing={3}>
            <Box>
              <StatCounter
                headline={`${countData?.analyzeEventFilterCount}`}
                subHeadline="záznamů"
                type="recordsNumber"
              />
            </Box>
            <Stack
              direction="column"
              sx={{ 
                borderLeft: '2px dashed lightgrey',
                pl: 3,
              }}
            >
              <StatCounter
                subHeadline={
                  <Box display={'flex'} flexWrap={'wrap'}>
                    <Typography fontSize={'small'} noWrap>
                      {dateFrom}
                    </Typography>
                    <Typography fontSize={'small'} noWrap>
                      &nbsp;-&nbsp;
                    </Typography>
                    <Typography fontSize={'small'} noWrap>
                      {dateTo}
                    </Typography>
                  </Box>
                }
                type="date"
              />
              {odometerTo !== 0 && (
                <StatCounter
                  subHeadline={`${odometerFrom}${getTechUnit(UnitType.Odometer)} - ${odometerTo}${getTechUnit(UnitType.Odometer)}`}
                  type="odometer"
                />
              )}
            </Stack>
          </Stack>
        </Box>
      </Paper>
      <AnalyzeCategory categoryName={AnalyzeCategoryType.Sum} />
      {/* this is equivalent of mt: 6 */}
      {fuels.length > 0 && (
        <Box sx={{ marginTop: '48px !important '}}>
          <FormBlock headline={t(`analyzeCategoryTypes.${AnalyzeCategoryType.Refuelings}`)}>
            <Stack spacing={2}>
              {fuels.length > 1 && (
                <AnalyzeCategory categoryName={AnalyzeCategoryType.Refuelings} />
              )}
              {fuels.map((fuel) => (
                <AnalyzeCategory
                  key={fuel.id}
                  categoryName={AnalyzeCategoryType.Refuelings}
                  fuel={fuel}
                />
              ))}
            </Stack>
          </FormBlock>
        </Box>
      )}
      <AnalyzeCategory categoryName={AnalyzeCategoryType.Tags} />
    </Stack>
  );
};

export default Analysis;
