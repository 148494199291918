import React, { FC } from 'react';
import useHeader from '@hooks/useHeader';
import Selections from '@mechis/sections/Selections';
import ControlMenu from '../ControlMenu';
import { Box, Typography, Button, Stack } from '@mechis/elements';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  DevicesDocument,
  DevicesIdsDocument,
  useDeleteDeviceMutation,
  useDevicesLazyQuery,
  UserDocument,
} from '@state/mechis-backend/generated/schema';
import snackbarCacheControl from '@state/mutations/snackbar';
import useTech from '@hooks/useTech';
import { compareDesc } from 'date-fns';
import selectionsCacheControl from '@state/mutations/selections';

const DeleteTech: FC = () => {
  useHeader({
    headline: 'appBar.headline.deleteTech',
    widget: (
      <Selections>
        <ControlMenu />
      </Selections>
    ),
  });

  const navigate = useNavigate();
  const [ getDevices ] = useDevicesLazyQuery();
  const { techId } = useTech();
  const { setSnackbar } = snackbarCacheControl;
  const { setSelectedDeviceId } = selectionsCacheControl;
  const { t } = useTranslation();
  const [ deleteDevice ] = useDeleteDeviceMutation({
    onCompleted: () => {
      setSnackbar('success', 'DEVICE_DELETE_SUCCESSFUL');

      getDevices().then((res) => {
        const devicesCopy = [ ...res.data?.devices || [] ];
        const sortedDevices = devicesCopy
          .filter((device) => device.suspendedDate === null)
          .sort((a, b) => compareDesc(new Date(a.lastEditedDate ?? ''), new Date(b.lastEditedDate ?? '')));

        if (sortedDevices.length > 0) {
          setSelectedDeviceId(Number(sortedDevices[0].id));
        } else {
          setSelectedDeviceId(null);
        }
      });
      navigate('/app');
    },
    onError: () => setSnackbar('error', 'DEVICE_DELETE_ERROR'),
    refetchQueries: [
      {
        query: DevicesDocument,
      },
      {
        query: DevicesIdsDocument,
      },
      {
        query: UserDocument,
      },
    ],
  });

  const submit = async () => {
    await deleteDevice({
      variables: {
        deleteDeviceId: techId,
      },
    });
  };

  return (
    <Box textAlign="center" p={2}>
      <Typography variant="h2" sx={{ mb: 1 }}>
        {t('deleteDevice.youSure')}
      </Typography>
      <Typography>
        {t('deleteDevice.description')}
      </Typography>
      <Stack alignItems="center" justifyContent="center" spacing={1.5} mt={3}>
        <Button
          variant="contained"
          color="error"
          size="large"
          onClick={submit}
        >
          {t('deleteDevice.delete')}
        </Button>
        <Button
          variant="text"
          color="primary"
          size="small"
          sx={{ textDecoration: 'underline', maxWidth: '220px' }}
          onClick={() => navigate(`/app/${techId}/tech-detail/settings`)}
        >
          {t('deleteDevice.cancel')}
        </Button>
      </Stack>
    </Box>
  );
};

export default DeleteTech;
