import { gql } from '@apollo/client';

export const GET_SELECTIONS = gql`
  query GetSelections {
    selections @client {
      dateValue
      dateState
      selectedDeviceId
      odometerValue
      odometerState
      tags
      filterDateFrom
      filterDateTo
      filterOdometerFrom
      filterOdometerTo
      filterPriceFrom
      filterPriceTo
      filterIsDate
      filterIsOdometer
      filterIsPrice
      filterIsRecordOdometer
      filterIsRecordRefueling
      filterIsRecordExpenses
      filterIsRecordMaintenance
      filterIsModalOpen
    }
  }
`;

export const GET_SELECTED_DEVICE_ID = gql`
  query GetSelections {
    selections @client {
      selectedDeviceId
    }
  }
`;

