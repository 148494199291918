import { ReactiveVar, makeVar } from '@apollo/client';
import { ISelectionsModel } from './types';

const filterDateFrom = new Date(new Date().setFullYear(new Date().getFullYear() - 1));
filterDateFrom.setHours(0, 0, 0, 0);
const filterDateTo = new Date();
filterDateTo.setHours(23, 59, 59, 999);

export const init: ISelectionsModel = {
  dateValue: new Date(),
  dateState: 'filled',
  selectedDeviceId: null,
  odometerValue: 0,
  odometerState: 'default',
  imageAttachments: [],
  fileAttachments: [],
  tags: [],
  filterDateFrom,
  filterDateTo,
  filterOdometerFrom: 0,
  filterOdometerTo: 0,
  filterPriceFrom: 0,
  filterPriceTo: 0,
  filterIsDate: true,
  filterIsOdometer: false,
  filterIsPrice: false,
  filterIsRecordOdometer: false,
  filterIsRecordRefueling: false,
  filterIsRecordExpenses: false,
  filterIsRecordMaintenance: false,
  filterIsModalOpen: false,
  pendingAttachmentIds: [],
};

const selections: ReactiveVar<ISelectionsModel> = makeVar<ISelectionsModel>(init);

export default selections;
