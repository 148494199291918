import React, { useEffect } from 'react';
import {
  Box,
  Button,
  Chip,
  Divider,
  Stack,
  TextField,
} from '@mechis/elements';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { useUpdateUserMutation, useUserQuery } from '@state/mechis-backend/generated/schema';
import Loader from '@mechis/blocks/Loader';
import useAuth from '@hooks/useAuth';
import { UserData } from './types';
import snackbarCacheControl from '@state/mutations/snackbar';
import useHeader from '@hooks/useHeader';
import LockIcon from '@mui/icons-material/Lock';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import NumberField from '@mechis/elements/NumberField';

interface IForm {
  firstName: string
  lastName: string
  phoneNumber: string
  phoneCountryCode: string
}

const MyProfile = () => {
  const { setSnackbar } = snackbarCacheControl;
  const { t } = useTranslation();
  useHeader({ headline: 'appBar.headline.myProfile' });

  const {
    onPasswordReset,
    passwordReset: {
      error: passwordResetError,
    },
  } = useAuth();

  const onSendPasswordResetLink = async () => {
    await onPasswordReset({ email: userInfo?.login ?? '' });
    passwordResetError ? setSnackbar('error', 'RESET_TOKEN_NOT_SENT') : setSnackbar('success', 'RESET_TOKEN_SENT_SUCCESSFUL');
  };

  const { data: userData, loading: userLoading, refetch: refetchUserQuery } = useUserQuery();

  const [ updateUser, {
    error: updateUserError,
  } ] = useUpdateUserMutation({
    onCompleted: () => refetchUserQuery(),
  });

  const userInfo = userData?.user;

  const schema = yup.object({
    phoneNumber: yup
      .string()
      .matches(/^\d{9}$|^$/, t('myProfile.form.phone.errorLength')),
  });

  const { control, handleSubmit, formState: { isDirty, isSubmitting }, reset } = useForm<IForm>({
    defaultValues: {
      firstName: userInfo?.firstName || '',
      lastName: userInfo?.surname || '',
      phoneNumber: userInfo?.phone || '',
      phoneCountryCode: userInfo?.countryCode || '',
    },
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    reset({
      firstName: userData?.user?.firstName || '',
      lastName: userData?.user?.surname || '',
      phoneCountryCode: userData?.user?.countryCode || '',
      phoneNumber: userData?.user?.phone || '',
    });
  }, [ userData?.user ]);

  const onChangePersonalDetail = async (data: UserData) => {
    await updateUser({
      variables: {
        user: {
          firstName: data.firstName,
          surname: data.lastName,
          phone: data.phoneNumber,
          countryCode: data.phoneCountryCode,
        },
      },
    });
    updateUserError ? setSnackbar('error', 'USER_UPDATE_ERROR') : setSnackbar('success', 'USER_UPDATE_SUCCESSFUL');
  };

  if (userLoading) {
    return (
      <Loader />
    );
  }

  return (
    <>
      <Box display={'grid'} gap={3}>
        <Stack spacing={2}>
          <Divider>
            <Chip label={t('myProfile.password')} size="small" />
          </Divider>
          <Button
            startIcon={<LockIcon />}
            onClick={onSendPasswordResetLink}
            style={{ textTransform: 'none' }}
            color="primary"
            variant="outlined"
          >
            {t('myProfile.changePassword')}
          </Button>
        </Stack>
        <Stack spacing={2}>
          <Divider>
            <Chip label={t('myProfile.profile')} size="small" />
          </Divider>
          <Controller
            render={({ field, formState }) => (
              <TextField
                {...field}
                id={'firstName'}
                label={t('myProfile.form.firstName.name')}
                variant="outlined"
                type="text"
                error={!!formState.errors.firstName?.message}
                helperText={<>{formState.errors.firstName?.message}</>}
              />
            )}
            name="firstName"
            control={control}
          />
          <Controller
            render={({ field, formState }) => (
              <TextField
                {...field}
                id={'lastName'}
                label={t('myProfile.form.lastName.name')}
                variant="outlined"
                type="text"
                error={!!formState.errors.lastName?.message}
                helperText={<>{formState.errors.lastName?.message}</>}
              />
            )}
            name="lastName"
            control={control}
          />
          <Box>
            <TextField
              value={userData?.user?.login}
              label={t('myProfile.form.email.name')}
              variant="outlined"
              type="text"
              disabled
            />
          </Box>
          <Box display={'flex'} gap={'10px'} >
            <Box display={'flex'} width={'150px'} position={'relative'}>
              <Box position={'absolute'} top={'50%'} left={'5px'} style={{ transform: 'translateY(-50%)' }}>+</Box>
              <Controller
                render={({ field, formState }) => (
                  <NumberField
                    {...field}
                    label={t('myProfile.form.phoneCountryCode.name')}
                    error={!!formState.errors.phoneCountryCode?.message}
                    helperText={<>{formState.errors.phoneCountryCode?.message}</>}
                    onlyInteger
                  />
                )}
                name="phoneCountryCode"
                control={control}
              />
            </Box>
            <Controller
              render={({ field, formState }) => (
                <NumberField
                  {...field}
                  label={t('myProfile.form.phone.name')}
                  error={!!formState.errors.phoneNumber?.message}
                  helperText={<>{formState.errors.phoneNumber?.message}</>}
                  onlyInteger
                />
              )}
              name="phoneNumber"
              control={control}
            />
          </Box>
          <Box display={'flex'} justifyContent={'center'} width={'100%'}>
            <Button
              color="secondary"
              variant="contained"
              type="submit"
              onClick={
                handleSubmit(onChangePersonalDetail)}
              disabled={!isDirty || isSubmitting}
            >
              {t('myProfile.button.saveChanges')}
            </Button>
          </Box>
        </Stack>
      </Box>
    </>
  );
};

export default MyProfile;
