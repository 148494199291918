import { useLocation, useParams } from 'react-router-dom';
import { ACCESS_TOKEN, CZK_UNIT_NAME, LITRE_UNIT_NAME, NON_BREAKING_SPACE } from '@configurations/constants/app';
import { useDevicesLazyQuery, useDeviceUnitsByDeviceIdQuery } from '@state/mechis-backend/generated/schema';
import { useQuery } from '@apollo/client';
import { ISelectionsDeviceIdQuery } from '@state/queries/selections/types';
import { GET_SELECTED_DEVICE_ID } from '@state/queries/selections';
import { useEffect, useState } from 'react';
import { compareDesc } from 'date-fns';
import selectionsCacheControl from '@state/mutations/selections';

// eslint-disable-next-line no-shadow
export enum UnitType {
  Litre = 'litre',
  Odometer = 'odometer',
  Power = 'power',
  Currency = 'currency',
}

const useTech = () => {
  const [ techId, setTechId ] = useState<number>(0);
  const { techId: paramTechId } = useParams();
  const { data: selections } = useQuery<ISelectionsDeviceIdQuery>(GET_SELECTED_DEVICE_ID);
  const [ getDevices ] = useDevicesLazyQuery();
  const accessToken = localStorage.getItem(ACCESS_TOKEN);
  const { setSelectedDeviceId } = selectionsCacheControl;
  const location = useLocation();

  const resolveTechId = (): number => {
    if (!Number.isNaN(paramTechId) && paramTechId) {
      return Number(paramTechId);
    }

    if (selections?.selections.selectedDeviceId) {
      return selections.selections.selectedDeviceId;
    }

    if (accessToken) {
      getDevices().then((res) => {
        const devicesCopy = [ ...res.data?.devices || [] ];
        const sortedDevices = devicesCopy
          .filter((device) => device.suspendedDate === null)
          .sort((a, b) => compareDesc(new Date(a.lastEditedDate ?? ''), new Date(b.lastEditedDate ?? '')));

        if (sortedDevices.length > 0) {
          setSelectedDeviceId(Number(sortedDevices[0].id));
          return Number(sortedDevices[0].id);
        } else {
          return 0;
        }
      });
    }

    return 0;
  };

  useEffect(() => {
    setTechId(resolveTechId());
  }, [ paramTechId, selections, location ]);

  const { data } = useDeviceUnitsByDeviceIdQuery({ variables: { deviceId: techId as number }, skip: !techId });
  const odometerUnit = data?.device?.odometerUnit?.unitName;
  const powerUnit = data?.device?.powerUnit?.unitName;
  const fuelsConsumptionUnit = data?.device?.fuel.map((fuel) => ({
    name: fuel.fuelType?.fuelName,
    unitName: fuel.consumptionUnit?.unitName,
  }));

  const getTechUnit = (unit: UnitType, withNonBreakingSpace?: boolean) => {
    let result;

    switch (unit) {
      case UnitType.Litre:
        result = LITRE_UNIT_NAME;
        break;
      case UnitType.Odometer:
        result = odometerUnit;
        break;
      case UnitType.Power:
        result = powerUnit;
        break;
      case UnitType.Currency:
        result = CZK_UNIT_NAME;
        break;
      default:
        return '';
    }

    return result && result.length > 0 && withNonBreakingSpace ? `${NON_BREAKING_SPACE}${result}` : result;
  };

  const getConsumptionUnitByFuelName = (fuelName: string, withNonBreakingSpace?: boolean) => {
    const findFuel = fuelsConsumptionUnit?.find((fuel) => fuel.name === fuelName);
    return findFuel?.unitName ? (withNonBreakingSpace ? `${NON_BREAKING_SPACE}${findFuel.unitName}` : findFuel.unitName) : '';
  };

  return {
    techId,
    settings: {
      units: {
        litre: LITRE_UNIT_NAME,
        odometer: odometerUnit,
        power: powerUnit,
        currency: CZK_UNIT_NAME,
        fuelsConsumption: fuelsConsumptionUnit,
      },
    },
    getTechUnit,
    getConsumptionUnitByFuelName,
  };
};

export default useTech;
