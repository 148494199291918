import React, { useState, useEffect, FC } from 'react';
import {
  Stack,
  Typography,
  Dialog,
  DialogContent,
  Button,
  DialogActions,
} from '@mechis/elements';
import CancelIcon from '@mui/icons-material/Cancel';
import {
  useMembershipsQuery,
  useDevicesIdsQuery,
} from '@state/mechis-backend/generated/schema';
import { IProps } from './types';
import {
  MEMBERSHIP_ADVANCED_ID,
  MEMBERSHIP_PROFESSIONAL_ID,
  MEMBERSHIP_STARTER_ID,
} from '@configurations/constants/membership';
import { useTranslation } from 'react-i18next';

const ChangeMembershipInfoModal: FC<IProps> = ({
  membershipToSwitchId,
  isOpen,
  setIsOpen,
  handleActivate,
  openSuspendingModal,
  openMembershipModal,
}) => {
  const { t } = useTranslation();
  const { data: membershipListData } = useMembershipsQuery();
  const [ canActivateWithoutSuspending, setCanActivateWithoutSuspending ] = useState<boolean>(false);
  const membershipToSwitch = membershipListData?.memberships?.membershipsDetail
    .find((membership) => membership?.id === membershipToSwitchId);

  const { data: devicesIds } = useDevicesIdsQuery({
    fetchPolicy: 'cache-first',
  });

  useEffect(() => {
    if (
      (devicesIds?.devices.length ?? 0) > (membershipToSwitch?.deviceCountMembership ?? 0)
    ) {
      setCanActivateWithoutSuspending(false);
    } else {
      setCanActivateWithoutSuspending(true);
    }
  }, [ membershipToSwitch, devicesIds ]);

  const getContentByMembershipSwitching = () => {
    switch (membershipToSwitchId) {
      case MEMBERSHIP_STARTER_ID:
        return (
          <Stack mt={2} spacing={1.5}>
            <Stack direction="row" alignItems="center" spacing={1} pl={0.5}>
              <Typography sx={{ lineHeight: 1 }}>
                {t('membershipSwitchModal.description.starter.1')}
              </Typography>
            </Stack>
            <Stack direction="row" alignItems="center" spacing={1} pl={0.5}>
              <Typography sx={{ lineHeight: 1 }}>
                {t('membershipSwitchModal.description.starter.2')}
              </Typography>
            </Stack>
          </Stack>
        );
      case MEMBERSHIP_ADVANCED_ID:
        return (
          <Stack mt={2} spacing={1.5}>
            <Stack direction="row" alignItems="center" spacing={1} pl={0.5}>
              <Typography sx={{ lineHeight: 1 }}>
                {t('membershipSwitchModal.description.advanced.1')}
              </Typography>
            </Stack>
            <Stack direction="row" alignItems="center" spacing={1} pl={0.5}>
              <Typography sx={{ lineHeight: 1 }}>
                {t('membershipSwitchModal.description.advanced.2', { price: membershipToSwitch?.price })} 
              </Typography>
            </Stack>
            <Stack direction="row" alignItems="center" spacing={1} pl={0.5}>
              <Typography sx={{ lineHeight: 1 }}>
                {t('membershipSwitchModal.description.advanced.3')}
              </Typography>
            </Stack>
          </Stack>
        );
      case MEMBERSHIP_PROFESSIONAL_ID:
        return (
          <Stack mt={2} spacing={1.5}>
            <Stack direction="row" alignItems="center" spacing={1} pl={0.5}>
              <Typography sx={{ lineHeight: 1 }}>
                {t('membershipSwitchModal.description.professional.1')}
              </Typography>
            </Stack>
            <Stack direction="row" alignItems="center" spacing={1} pl={0.5}>
              <Typography sx={{ lineHeight: 1 }}>
                {t('membershipSwitchModal.description.professional.2', { price: membershipToSwitch?.price })}
              </Typography>
            </Stack>
            <Stack direction="row" alignItems="center" spacing={1} pl={0.5}>
              <Typography sx={{ lineHeight: 1 }}>
                {t('membershipSwitchModal.description.professional.3')}
              </Typography>
            </Stack>
          </Stack>
        );
      default:
        return <></>;
    }
  };

  return (
    <Dialog
      onClose={() => setIsOpen(false)}
      open={isOpen}
      fullScreen
      fullWidth
      PaperProps={{
        sx: {
          backgroundColor: '#f6f6f6',
          textAlign: 'center',
        },
      }}
    >
      <CancelIcon
        onClick={() => setIsOpen(false)}
        sx={{
          position: 'absolute',
          right: '16px',
          top: '16px',
          color: 'lightgrey',
        }}
      />
      <DialogContent>
        <Typography
          variant="h5"
          textAlign="center"
          mb={1}
          mt={1}
        >
          {t('membershipSwitchModal.title')}
        </Typography>
        <Stack spacing={3} mt={2} direction="column-reverse">
          {getContentByMembershipSwitching()}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Stack
          sx={{
            width: '100%',
            pr: 2,
            pl: 2,
          }}
          direction="row" 
          justifyContent="space-around"
        >
          <Button variant="text" onClick={() => {
            setIsOpen(false);
            openMembershipModal();
          }}>
            {t('membershipSwitchModal.back')}
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              if (canActivateWithoutSuspending) {
                handleActivate(Number(membershipToSwitchId));
              } else {
                setIsOpen(false);
                openSuspendingModal();
              }
            }}
          >
            {t('membershipSwitchModal.continue')}
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};

export default ChangeMembershipInfoModal;
