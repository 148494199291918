import React, { useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
} from '@mechis/elements';
import { Controller, useForm} from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import * as yup from 'yup';
import snackbarCacheControl from '@state/mutations/snackbar';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import TextField from '@mechis/elements/TextField';
import { useTranslation } from 'react-i18next';
import { IUpdateOperationalDataProps, IUpdateOperationalDataFormInput } from './types';
import { format, isBefore, isValid, setDay, setHours } from 'date-fns';
import UpdateInfoModalHeader from '@screens/TechDetail/Info/modals/UpdateInfoModalHeader';
import useTech from '@hooks/useTech';
import { blurTarget, ensureNumericZeroIsNotDisplayed } from '@utilities/app';
import useUnits from '@hooks/useUnits';
import SpeedIcon from '@mui/icons-material/Speed';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import NumberField from '@mechis/elements/NumberField';

const UpdateOperationalData: React.FC<IUpdateOperationalDataProps> = ({
  isOpen,
  onClose, 
  updateOperationalData, 
  deviceOperationalData, 
  errorOperationalData,
}) => {
  const { t } = useTranslation();
  const { techId } = useTech();
  const { setSnackbar } = snackbarCacheControl;

  const [ odometerUnit, setOdometerUnit ] = useState<number | undefined>(Number(deviceOperationalData?.device?.odometerUnit?.id));
  const { odometerUnitsData } = useUnits();
  const isOdometerUnitValueSame = Number(deviceOperationalData?.device?.odometerUnit?.id) === odometerUnit;

  const schema = yup.object({
    actualOdometerState: yup.number().transform((value, originalValue) => originalValue === '' ? null : value).nullable(),
    guaranteeDistance: yup.number().transform((value, originalValue) => originalValue === '' ? null : value).nullable(),
    acquisitionDate: yup.string()
      .test(
        'is-past',
        t('addDevice.components.acquisitionDate.onlyPast'),
        (value) => {
          if (!value) return false;
          return isValid(new Date(value)) && isBefore(new Date(value), new Date());
        }
      ),
    firstRegistrationDate: yup.string()
      .test(
        'is-past',
        t('addDevice.components.firstRegistrationDate.onlyPast'),
        (value) => {
          if (!value) return false;
          return isValid(new Date(value)) && isBefore(new Date(value), new Date());
        }
      ),
    guaranteeMonths: yup.number().transform((value, originalValue) => originalValue === '' ? null : value).nullable(),
    initialOdometerState: yup.number().transform((value, originalValue) => originalValue === '' ? null : value).nullable(),
    odometerUnit: yup.string(),
  }).required();

  const { control, handleSubmit, formState: { isDirty, isSubmitting }, reset} = useForm<IUpdateOperationalDataFormInput>({
    defaultValues: {
      acquisitionDate: deviceOperationalData?.device?.acquisitionDate,
      firstRegistrationDate: deviceOperationalData?.device?.firstRegistrationDate,
      guaranteeDate: deviceOperationalData?.device?.guaranteeDate,
      guaranteeDistance: ensureNumericZeroIsNotDisplayed(deviceOperationalData?.device?.guaranteeDistance),
      guaranteeMonths: ensureNumericZeroIsNotDisplayed(deviceOperationalData?.device?.guaranteeMonths),
      initialOdometerState: ensureNumericZeroIsNotDisplayed(deviceOperationalData?.device?.initialOdometerState),
      odometerUnit: String(odometerUnit),
    },
    resolver: yupResolver(schema),
  });
  
  const onChangeOperationalData = async (data: any) => {
    await updateOperationalData({
      variables: {
        device: {
          acquisitionDate: data.acquisitionDate ? format(new Date(data.acquisitionDate), 'yyyy-MM-dd') : undefined,
          firstRegistrationDate: data.firstRegistrationDate ? format(new Date(data.firstRegistrationDate), 'yyyy-MM-dd') : undefined,
          guaranteeDate: data.guaranteeDate ? format(new Date(data.guaranteeDate), 'yyyy-MM-dd') : undefined,
          guaranteeDistance: data.guaranteeDistance,
          guaranteeMonths: data.guaranteeMonths,
          id: techId,
          initialOdometerState: data.initialOdometerState,
          odometerUnit: { id: Number(odometerUnit) },
        },
      },
    });
    errorOperationalData ? setSnackbar('error', 'DEVICE_UPDATE_FAILED') : setSnackbar('success', 'DEVICE_UPDATE_SUCCESS');
    onClose();
  };
  
  return (
    <Dialog
      fullScreen={true}
      open={isOpen}
      onClose={() => {
        onClose();
        reset();
      }}
    >
      <DialogContent>
        <UpdateInfoModalHeader
          onClose={onClose}
          tParam="techDetail.headlines.dataAndWarranty"
        />
        <Box mb={2}>
          <Controller
            render={({field, formState}) => (
              <MobileDatePicker
                {...field}
                showToolbar={false}
                views={[ 'year', 'month' ]}
                inputFormat="MM/yyyy"
                disableFuture
                label={t('addDevice.components.firstRegistrationDate.name')}
                value={field.value}
                onChange={(value: Date | null) => field.onChange(value ? setHours(setDay(value, 1), 0) : null)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={!!formState.errors.firstRegistrationDate?.message}
                    helperText={<>{formState.errors.firstRegistrationDate?.message}</>}
                  />
                )}
              />
            )}
            name="firstRegistrationDate"
            control={control}
          />
        </Box>
        <Box mb={2}>
          <Controller
            render={({field, formState}) => (
              <MobileDatePicker
                {...field}
                showToolbar={false}
                views={[ 'year', 'month' ]}
                inputFormat="MM/yyyy"
                disableFuture
                label={t('addDevice.components.acquisitionDate.name')}
                value={field.value}
                onChange={(value: Date | null) => field.onChange(value ? setHours(setDay(value, 1), 0) : null)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={!!formState.errors.acquisitionDate?.message}
                    helperText={<>{formState.errors.acquisitionDate?.message}</>}
                  />
                )}
              />
            )}
            name="acquisitionDate"
            control={control}
          />
        </Box>
        <Box mb={2}>
          <Controller
            render={({ field, formState }) => (
              <NumberField
                {...field}
                onWheel={blurTarget}
                label={t('addDevice.components.initialOdometerState.name')}
                value={field.value}
                id="initialOdometerState"
                error={!!formState.errors.initialOdometerState?.message}
                helperText={<>{formState.errors.initialOdometerState?.message}</>}
                unitIcon={<SpeedIcon fontSize="small" />}
                units={odometerUnitsData?.odometerUnits.map((unit) => ({
                  id: Number(unit.id),
                  name: unit.unitName,
                  isSelected: odometerUnit === Number(unit.id),
                }))}
                onUnitChange={(unitId) => setOdometerUnit(unitId)}
                variant="outlined"
                onlyInteger
              />
            )}
            name="initialOdometerState"
            control={control}
          />
        </Box>
        <Box mb={2}>
          <Controller
            render={({ field }) => (
              <MobileDatePicker
                {...field}
                showToolbar={false}
                views={[ 'year', 'month' ]}
                inputFormat="MM/yyyy"
                label={t('addDevice.components.guaranteeDate.name')}
                value={field.value}
                onChange={(value: Date | null) => field.onChange(value ? setHours(setDay(value, 1), 0) : null)}
                renderInput={(params) => <TextField id="guaranteeDate" {...params} />}
              />
            )}
            name="guaranteeDate"
            control={control}
          />
        </Box>
        <Box mb={2}>
          <Controller
            render={({ field, formState }) => (
              <NumberField
                {...field}
                id="guaranteeMonths"
                label={t('addDevice.components.guaranteeMonths.name')}
                error={!!formState.errors.guaranteeMonths?.message}
                helperText={<>{formState.errors.guaranteeMonths?.message}</>}
                variant="outlined"
                onlyInteger
              />
            )}
            name="guaranteeMonths"
            control={control}
          />
        </Box>
        <Box mb={2}>
          <Controller
            render={({ field, formState }) => (
              <NumberField
                {...field}
                onWheel={blurTarget}
                label={t('addDevice.components.guaranteeDistance.name')}
                id="guaranteeDistance"
                error={!!formState.errors.guaranteeDistance?.message}
                helperText={<>{formState.errors.guaranteeDistance?.message}</>}
                unitIcon={<FormatListNumberedIcon fontSize="small" />}
                units={odometerUnitsData?.odometerUnits.map((unit) => ({
                  id: Number(unit.id),
                  name: unit.unitName,
                  isSelected: odometerUnit === Number(unit.id),
                }))}
                onUnitChange={(unitId) => setOdometerUnit(unitId)}
                variant="outlined"
                onlyInteger
              />
            )}
            name="guaranteeDistance"
            control={control}
          />
        </Box>
        <Box display={'flex'} justifyContent={'center'} width={'100%'}>
          <Button
            variant="contained"
            onClick={handleSubmit(onChangeOperationalData)}
            disabled={!isDirty && !isSubmitting && isOdometerUnitValueSame}
          >
            {t('myProfile.button.saveChanges')}
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default UpdateOperationalData;
