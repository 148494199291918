import React, { FC, useState, useEffect } from 'react';
import { IProps } from './types';
import { v4 as uuid } from 'uuid';
import { AnalyzeCategory, Filter, useAnalyzeQuery } from '@state/mechis-backend/generated/schema';
import { useQuery } from '@apollo/client';
import { GET_SELECTIONS } from '@state/queries/selections';
import { ISelectionsQuery } from '@state/queries/selections/types';
import { filterSelectionToQuery } from '@hooks/utils';
import { useTranslation } from 'react-i18next';
import NumberFormat from 'react-number-format';
import { THOUSAND_SEPARATOR } from '@configurations/constants/app';
import { Stack } from '@mechis/elements';

import {
  Table,
  TableContainer,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
} from '@mechis/elements';
import { getIconByCategory } from '@hooks/useTagManager';
import { LITRE_KEYS, ODOMETER_KEYS, PRICE_KEYS } from '@configurations/constants/analysis';
import useTech, { UnitType } from '@hooks/useTech';

const AnalyzeData: FC<IProps> = ({ category, command, fuelId }) => {
  const [ 
    analyzeData,
    setAnalyzeData,
  ] = useState<
    Array<{
      rowDescriptionKey: string
      rowDescriptionCategory?: string | null
      value: string
    }>
  >([]);
  const [ tags, setTags ] = useState<number[]>([]);
  const [ filters, setFilters ] = useState<Filter>({});
  const { data: selectionData } = useQuery<ISelectionsQuery>(GET_SELECTIONS);
  const { t } = useTranslation();
  const { getTechUnit, techId } = useTech();

  const { data, loading, error } = useAnalyzeQuery({
    fetchPolicy: 'network-only',
    variables: {  
      deviceId: techId,
      analyzeCategory: category,
      command,
      tags,
      filters,
      fuelId,
    },
    skip: !techId,
  });

  const getSuffix = (key: string) => {
    if (LITRE_KEYS.includes(key)) return getTechUnit(UnitType.Litre, true);
    if (PRICE_KEYS.includes(key) || key.includes('tag')) return getTechUnit(UnitType.Currency, true);
    if (ODOMETER_KEYS.includes(key)) return getTechUnit(UnitType.Odometer, true);

    return undefined;
  };

  useEffect(() => {
    if (data?.analyze) {
      setAnalyzeData(data.analyze);
    }
  }, [ error, loading, data ]);

  useEffect(() => {
    if (!selectionData?.selections.filterIsModalOpen) {
      setFilters(filterSelectionToQuery(selectionData?.selections));
    }

    if (selectionData?.selections?.tags) {
      setTags(selectionData?.selections?.tags.map((tag) => Number(tag.id)));
    }
  }, [ selectionData ]);

  const resolveKey = (key: string) => {
    if (key.includes('tag')) return t(`tagManager.id.${key}`);

    return t(`analyzeValueRowDescription.${key}`);
  };

  return (
    <TableContainer>
      <Table>
        <TableBody>
          {analyzeData.map((row) => (
            <TableRow key={uuid()}>
              <TableCell>
                <Stack flexDirection="row" sx={{ lineHeight: 1 }} alignItems="center">
                  {category === AnalyzeCategory.Tags && (
                    <IconButton
                      color="primary"
                      size="small"
                      sx={{marginRight: 1}}
                    >
                      {getIconByCategory(row.rowDescriptionCategory ?? 'other')}
                    </IconButton>)}
                  {resolveKey(row.rowDescriptionKey)}
                </Stack>
              </TableCell>
              <TableCell align="right" sx={{ width: '35%' }}>
                <NumberFormat 
                  value={row.value}
                  displayType="text"
                  thousandSeparator={THOUSAND_SEPARATOR}
                  decimalScale={2}
                  suffix={getSuffix(row.rowDescriptionKey)}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default AnalyzeData;
