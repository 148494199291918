import { ReactiveVar } from '@apollo/client';
import odometerValidationModalData from '@state/models/odometerValidationModalData';
import { IOdometerValidationModalData } from '@state/models/odometerValidationModalData/types';

const setOdometerValidationModalData = (odometerValidationModalDataModel: ReactiveVar<IOdometerValidationModalData>) => {
  return (filledDate: Date, payload: any, minOdometer?: number, maxOdometer?: number) => {
    const model = {
      timestamp: Date.now(),
      payload,
      filledDate,
      minOdometer,
      maxOdometer,
      ignoreNextValidation: false,
    };
    odometerValidationModalDataModel(model);
  };
};

const ignoreNextValidation = (odometerValidationModalDataModel: ReactiveVar<IOdometerValidationModalData>) => {
  return () => {
    const model = {
      ...odometerValidationModalDataModel(),
      ignoreNextValidation: true,
    };
    odometerValidationModalDataModel(model);
  };
};

const resetIgnoreNextValidation = (odometerValidationModalDataModel: ReactiveVar<IOdometerValidationModalData>) => {
  return () => {
    const model = {
      ...odometerValidationModalDataModel(),
      ignoreNextValidation: false,
    };
    odometerValidationModalDataModel(model);
  };
};

const odometerValidationModalDataCacheControl = {
  setOdometerValidationModalData: setOdometerValidationModalData(odometerValidationModalData),
  ignoreNextValidation: ignoreNextValidation(odometerValidationModalData),
  resetIgnoreNextValidation: resetIgnoreNextValidation(odometerValidationModalData),
};

export default odometerValidationModalDataCacheControl;
